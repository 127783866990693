export default {
    key: 'G',
    suffix: 'aug',
    positions: [
        {
            frets: '32100x',
            fingers: '321000'
        },
        {
            frets: '3x544x',
            fingers: '104230'
        },
        {
            frets: 'xx544x',
            fingers: '002110',
            barres: 4,
            capo: true
        },
        {
            frets: 'xa988x',
            fingers: '032110',
            barres: 8,
            capo: true
        }
    ]
};

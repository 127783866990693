export default {
    key: 'Ab',
    suffix: 'mmaj7',
    positions: [
        {
            frets: 'x21143',
            fingers: '021143',
            barres: 1,
            capo: true
        },
        {
            frets: '465444',
            fingers: '132111',
            barres: 4,
            capo: true
        },
        {
            frets: 'x66887',
            fingers: '011342',
            barres: 6,
            capo: true
        },
        {
            frets: 'xbdccb',
            fingers: '014231',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'add9',
    positions: [
        {
            frets: '2425',
            fingers: '1314',
            barres: 2,
            capo: true
        },
        {
            frets: '7657',
            fingers: '3214'
        },
        {
            frets: '99a9',
            fingers: '1121',
            barres: 9,
            capo: true
        },
        {
            frets: 'becc',
            fingers: '1422',
            barres: 12
        }
    ]
};

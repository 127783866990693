export default {
    key: 'F#',
    suffix: 'aug7',
    positions: [
        {
            frets: '2x233x',
            fingers: '102340'
        },
        {
            frets: 'xx4756',
            fingers: '001423'
        },
        {
            frets: 'x98770',
            fingers: '043120'
        },
        {
            frets: 'x9c9ba',
            fingers: '014132',
            barres: 9,
            capo: true
        }
    ]
};

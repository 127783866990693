export default {
    key: 'G',
    suffix: 'add9',
    positions: [
        {
            frets: '300203',
            fingers: '200103'
        },
        {
            frets: 'xx3214',
            fingers: '003214'
        },
        {
            frets: 'xa97a7',
            fingers: '032141',
            barres: 7,
            capo: true
        },
        {
            frets: 'xa90aa',
            fingers: '021034'
        }
    ]
};

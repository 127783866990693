export default {
    key: 'Bb',
    suffix: '9b5',
    positions: [
        {
            frets: 'x10110',
            fingers: '010230'
        },
        {
            frets: '6x0554',
            fingers: '400231'
        },
        {
            frets: '656556',
            fingers: '213114',
            barres: 5,
            capo: true
        },
        {
            frets: 'xdcdd0',
            fingers: '021340'
        }
    ]
};

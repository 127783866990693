export default {
    key: 'C',
    suffix: 'm9',
    positions: [
        {
            frets: '3335',
            fingers: '1113',
            barres: 3
        },
        {
            frets: '8765',
            fingers: '4321'
        },
        {
            frets: '7766',
            fingers: '2211',
            barres: [6, 7],
            capo: true
        },
        {
            frets: '8aaa',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'madd9',
    positions: [
        {
            frets: 'x5442x',
            fingers: '042310'
        },
        {
            frets: '75067x',
            fingers: '310240'
        },
        {
            frets: 'xx9779',
            fingers: '003114',
            barres: 7,
            capo: true
        },
        {
            frets: 'xecbex',
            fingers: '032140'
        }
    ]
};

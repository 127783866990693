export default {
    key: 'Bb',
    suffix: 'aug',
    positions: [
        {
            frets: 'x1433x',
            fingers: '014230'
        },
        {
            frets: '65433x',
            fingers: '432110',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx8776',
            fingers: '004231'
        },
        {
            frets: 'xx877x',
            fingers: '002110',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'm6',
    positions: [
        {
            frets: '2x1222',
            fingers: '201334',
            barres: 2
        },
        {
            frets: 'x44645',
            fingers: '011312',
            barres: 4,
            capo: true
        },
        {
            frets: 'x97879',
            fingers: '031214',
            barres: 7,
            capo: true
        },
        {
            frets: 'x9b8ax',
            fingers: '024130'
        }
    ]
};

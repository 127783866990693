export default {
    key: 'B',
    suffix: '11',
    positions: [
        {
            frets: '2304',
            fingers: '1203'
        },
        {
            frets: '6456',
            fingers: '3124'
        },
        {
            frets: '9996',
            fingers: '2341'
        },
        {
            frets: '8997',
            fingers: '2341'
        }
    ]
};

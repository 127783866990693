export default {
    key: 'G',
    suffix: '/D',
    positions: [
        {
            frets: 'xx0003',
            fingers: '000001'
        },
        {
            frets: 'x55433',
            fingers: '034211',
            barres: 3
        },
        {
            frets: 'x55787',
            fingers: '011343',
            barres: 5
        }
    ]
};

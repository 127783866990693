export default {
    key: 'A',
    suffix: 'aug9',
    positions: [
        {
            frets: '0112',
            fingers: '0112',
            barres: 1
        },
        {
            frets: '4534',
            fingers: '2413'
        },
        {
            frets: '6778',
            fingers: '1223',
            barres: 7
        },
        {
            frets: 'ab9a',
            fingers: '2413'
        }
    ]
};

export default {
    key: 'G',
    suffix: '9b5',
    positions: [
        {
            frets: '2112',
            fingers: '2113',
            barres: 1
        },
        {
            frets: '4554',
            fingers: '1221',
            barres: [4, 5],
            capo: true
        },
        {
            frets: '6978',
            fingers: '1423'
        },
        {
            frets: 'ab9c',
            fingers: '2314'
        }
    ]
};

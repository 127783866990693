export default {
    key: 'Db',
    suffix: 'm9',
    positions: [
        {
            frets: '1302',
            fingers: '1302'
        },
        {
            frets: '4446',
            fingers: '1113',
            barres: 4,
            capo: true
        },
        {
            frets: '9876',
            fingers: '4321'
        },
        {
            frets: '8877',
            fingers: '2211',
            barres: [7, 8],
            capo: true
        }
    ]
};

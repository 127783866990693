export default {
    key: 'A',
    suffix: 'aug',
    positions: [
        {
            frets: '2110',
            fingers: '3120'
        },
        {
            frets: '2114',
            fingers: '2114',
            barres: 1,
            capo: true
        },
        {
            frets: '2554',
            fingers: '1342'
        },
        {
            frets: '6558',
            fingers: '2114',
            barres: 5,
            capo: true
        }
    ]
};

import firebase from 'firebase';
import { useContext, createContext } from 'react';

export type UserType = firebase.User;
export type User = UserType | null;

export const defaultUser: User = null;

interface Auth {
  user: User | null
  signOut: () => Promise<void>
}

export const AuthContext = createContext<Auth>({
  user: null,
  signOut: async () => {},
});

export function useAuth() {
  console.log('useAuth')
  return useContext(AuthContext);
}
export default {
    key: 'F',
    suffix: '/C',
    positions: [
        {
            frets: 'x33211',
            fingers: '034211',
            barres: 1
        },
        {
            frets: 'x33565',
            fingers: '011343',
            barres: 3
        },
        {
            frets: '88aaa8',
            fingers: '112341',
            barres: 8
        }
    ]
};

export default {
    key: 'F#',
    suffix: '7',
    positions: [
        {
            frets: '242322',
            fingers: '131211',
            barres: 2,
            capo: true
        },
        {
            frets: 'x44656',
            fingers: '011324',
            barres: 4,
            capo: true
        },
        {
            frets: 'x9897x',
            fingers: '032410'
        },
        {
            frets: '99b9b9',
            fingers: '113141',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'aug7',
    positions: [
        {
            frets: 'xx1423',
            fingers: '001423'
        },
        {
            frets: 'x69687',
            fingers: '014132',
            barres: 6,
            capo: true
        },
        {
            frets: 'bab009',
            fingers: '324001'
        },
        {
            frets: 'bxbccx',
            fingers: '102340'
        }
    ]
};

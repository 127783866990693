import { EditorState, Modifier } from "draft-js";

export function getSelectedText(editorState: EditorState) {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  return currentContentBlock.getText().slice(start, end);
}

export function replaceSelection(editorState: EditorState, newString: string) {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const newContent = Modifier.replaceText(content, selection, newString);
  const newState = EditorState.push(editorState, newContent, 'insert-characters');
  return newState;
}
export default {
    key: 'A',
    suffix: '7',
    positions: [
        {
            frets: 'x02020',
            fingers: '002030'
        },
        {
            frets: 'x02223',
            fingers: '001112',
            barres: 2,
            capo: true
        },
        {
            frets: '575655',
            fingers: '131211',
            barres: 5,
            capo: true
        },
        {
            frets: 'x07989',
            fingers: '001324'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'maj13',
    positions: [
        {
            frets: 'xx0422',
            fingers: '000311',
            barres: 2
        },
        {
            frets: 'x54420',
            fingers: '042310'
        },
        {
            frets: 'x55677',
            fingers: '011234',
            barres: 5
        },
        {
            frets: 'a999a9',
            fingers: '211131',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: '/C#',
    positions: [
        {
            frets: 'x42255',
            fingers: '031144',
            barres: 2
        },
        {
            frets: 'x42225',
            fingers: '031114',
            barres: 2
        },
        {
            frets: 'x47655',
            fingers: '014322',
            barres: 5
        }
    ]
};

import * as uuid from 'uuid';

const anonFingerprintKey = 'anonFingerprint';
const fingerprintKey = 'fingerprint';

const storage = window.localStorage;

function getFingerprint() {
  const f = storage.getItem(fingerprintKey);
  if (f) return f;
  return initFingerprint();
}

function getOrInitAnonFingerprint() {
  const f = storage.getItem(anonFingerprintKey);
  if (f) return f;
  
  const id = uuid.v4();
  storage.setItem(anonFingerprintKey, id);
  return id;
}

function initFingerprint(userId?: string) {
  if (userId) {
    storage.setItem(fingerprintKey, userId);
    return userId;
  } else {
    storage.removeItem(fingerprintKey);
    return getOrInitAnonFingerprint();
  }
}

export const FingerprintUtil = {
  getFingerprint,
  initFingerprint,
}
export default {
    key: 'C#',
    suffix: 'add9',
    positions: [
        {
            frets: 'x43141',
            fingers: '032141',
            barres: 1,
            capo: true
        },
        {
            frets: 'x43x44',
            fingers: '021034'
        },
        {
            frets: '98x89x',
            fingers: '310240'
        },
        {
            frets: 'xxba9b',
            fingers: '003214'
        }
    ]
};

export default {
    key: 'G',
    suffix: '13b9',
    positions: [
        {
            frets: '1412',
            fingers: '1412',
            barres: 1,
            capo: true
        },
        {
            frets: '4547',
            fingers: '1214',
            barres: 4,
            capo: true
        },
        {
            frets: 'a877',
            fingers: '4211',
            barres: 7,
            capo: true
        },
        {
            frets: 'abcb',
            fingers: '1243'
        }
    ]
};

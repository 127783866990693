export default {
    key: 'F#',
    suffix: 'maj7b5',
    positions: [
        {
            frets: '213311',
            fingers: '213411',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx4566',
            fingers: '001234'
        },
        {
            frets: 'x98ab8',
            fingers: '021341',
            barres: 8,
            capo: true
        },
        {
            frets: 'x9aabx',
            fingers: '012340'
        }
    ]
};

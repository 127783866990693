export default {
    key: 'Ab',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '3132',
            fingers: '3142'
        },
        {
            frets: '4131',
            fingers: '4131',
            barres: 1,
            capo: true
        },
        {
            frets: '4764',
            fingers: '1431',
            barres: 4,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'dim',
    positions: [
        {
            frets: '2320',
            fingers: '1320'
        },
        {
            frets: '8656',
            fingers: '4213'
        },
        {
            frets: '89b9',
            fingers: '1243'
        },
        {
            frets: 'b9b9',
            fingers: '3141',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: '7',
    positions: [
        {
            frets: 'xx0212',
            fingers: '000213'
        },
        {
            frets: 'x5453x',
            fingers: '032410'
        },
        {
            frets: '557575',
            fingers: '113141',
            barres: 5,
            capo: true
        },
        {
            frets: 'acabaa',
            fingers: '131211',
            barres: 10,
            capo: true
        }
    ]
};

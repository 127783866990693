export default {
    key: 'A',
    suffix: '13',
    positions: [
        {
            frets: '0122',
            fingers: '0123'
        },
        {
            frets: '4634',
            fingers: '2413'
        },
        {
            frets: '6779',
            fingers: '1234'
        },
        {
            frets: 'bb9a',
            fingers: '3412'
        }
    ]
};

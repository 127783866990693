export default {
    key: 'Gb',
    suffix: 'sus4',
    positions: [
        {
            frets: '4124',
            fingers: '3124'
        },
        {
            frets: '4674',
            fingers: '1231',
            barres: 4,
            capo: true
        },
        {
            frets: '6674',
            fingers: '2341'
        },
        {
            frets: '6679',
            fingers: '1124',
            barres: 6,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: 'minor',
    positions: [
        {
            frets: '0333',
            fingers: '0123'
        },
        {
            frets: '5333',
            fingers: '3111',
            barres: 3,
            capo: true
        },
        {
            frets: '5336',
            fingers: '3114',
            barres: 3,
            capo: true
        },
        {
            frets: '5786',
            fingers: '1342'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'maj11',
    positions: [
        {
            frets: '320012',
            fingers: '420013'
        },
        {
            frets: '334433',
            fingers: '112311',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx9078',
            fingers: '003012'
        },
        {
            frets: 'aaabca',
            fingers: '111231',
            barres: 10,
            capo: true
        }
    ]
};

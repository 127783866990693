export default {
    key: 'Eb',
    suffix: 'dim7',
    positions: [
        {
            frets: 'xx1212',
            fingers: '001324'
        },
        {
            frets: 'x67575',
            fingers: '023141',
            barres: 5,
            capo: true
        },
        {
            frets: 'xx7878',
            fingers: '001324'
        },
        {
            frets: 'bxabax',
            fingers: '201310',
            barres: 10
        }
    ]
};

export default {
    key: 'Gb',
    suffix: '9#11',
    positions: [
        {
            frets: '1001',
            fingers: '1002'
        },
        {
            frets: '3443',
            fingers: '1221',
            barres: [3, 4],
            capo: true
        },
        {
            frets: '5867',
            fingers: '1423'
        },
        {
            frets: '9a8b',
            fingers: '2314'
        }
    ]
};

import React, { useCallback } from 'react'
import { useOwnedSongs } from '../../hooks/Song';
import { List, Row, Spin, Alert, Button, Empty, Typography, Badge, Divider, Statistic, Tooltip, Col, Space, Card, Popconfirm } from 'antd';
import QueryBuilder from '../common/QueryBuilder';
import Authenticated from '../auth/Authenticated';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import { archive } from '../../hooks/Docs';
import UserTag from '../auth/UserTag';

const SongListPage: React.FC<RouteComponentProps> = () => {
  const ownedSongs = useOwnedSongs();

  const createSong = useCallback(() => {
    async function create() {
      console.log('creating song')
      const song = await ownedSongs?.create();
      if (song) {
        console.log('song created... navigating')
        navigate(`s/${song.id}`);
      } else {
        console.log('Song is null')
      }
    }
    create();
  }, [ownedSongs]);

  return (
    <Authenticated strategy='signInButton'>
      {(
        ownedSongs ? (
          <QueryBuilder
            query={ownedSongs.query}
            renderItems={(items) => {
              const quotaUsed = items.length / ownedSongs.songLimit;
              const quotaColor = quotaUsed < 0.5 ? undefined : (quotaUsed < 0.8 ? '#faad14' : '#ff4d4f')
              return (
                <div>
                  <Divider type='horizontal' />
                  <Row align='middle'>
                    <Space>
                      <Button
                        disabled={items.length >= ownedSongs.songLimit}
                        onClick={createSong}>
                        Create Song
                    </Button>
                      <Statistic
                        valueStyle={{ color: quotaColor }}
                        value={items.length}
                        suffix={`/${ownedSongs.songLimit}`} />
                    </Space>
                    <Tooltip title='Chordpad Pro coming soon!'>
                      <Button disabled type='link'>Unlock more songs</Button>
                    </Tooltip>
                  </Row>
                  {
                    items.map((item, i) => (
                      <div key={i}>
                        <Divider type='horizontal' />
                        <Card
                          actions={[
                            <Popconfirm
                              placement='bottom'
                              title={'Are you sure? You cannot unarchive songs.'}
                              onConfirm={() => archive('songs', item)}>
                              <Button type='link'>Archive</Button>
                            </Popconfirm>,
                            <Link to={`${item.id}`}>
                              <Button type='primary'>Continue writing</Button>
                            </Link>
                          ]}>
                          <Card.Meta
                            title={item.title}
                            description={
                              <div>
                                <p>
                                  {`Updated: ${item.updatedAt?.toDate()?.toLocaleString()}`}
                                </p>
                              </div>} />
                        </Card>
                      </div>
                    ))
                  }
                </div>
              );
            }}
            renderLoading={() => <Row justify='center'><Spin /></Row>}
            renderError={(err: Error) => <Alert message={err.message} />} />
        ) : (
            <div>
              <Empty />
            </div>
          )
      )}
    </Authenticated>
  );
};

export default SongListPage;
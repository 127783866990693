export default {
    key: 'Eb',
    suffix: 'aug9',
    positions: [
        {
            frets: '343443',
            fingers: '121341',
            barres: 3,
            capo: true
        },
        {
            frets: 'x65667',
            fingers: '021334',
            barres: 6
        },
        {
            frets: 'baxa09',
            fingers: '420301'
        },
        {
            frets: 'babacx',
            fingers: '213140',
            barres: 10
        }
    ]
};

export default {
    key: 'D',
    suffix: 'm11',
    positions: [
        {
            frets: 'xx0011',
            fingers: '000011',
            barres: 1,
            capo: true
        },
        {
            frets: 'x53553',
            fingers: '021341',
            barres: 3,
            capo: true
        },
        {
            frets: 'a8a988',
            fingers: '314211',
            barres: 8,
            capo: true
        },
        {
            frets: 'aaaaac',
            fingers: '111114',
            barres: 10,
            capo: true
        }
    ]
};

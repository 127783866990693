export default {
    key: 'D',
    suffix: '/F#',
    positions: [
        {
            frets: '200232',
            fingers: '100243'
        },
        {
            frets: '254232',
            fingers: '143121',
            barres: 2
        },
        {
            frets: 'xx4235',
            fingers: '003124'
        }
    ]
};

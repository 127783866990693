export default {
    key: 'Db',
    suffix: '9b5',
    positions: [
        {
            frets: '0312',
            fingers: '0312'
        },
        {
            frets: '4536',
            fingers: '2314'
        },
        {
            frets: '8778',
            fingers: '2113',
            barres: 7,
            capo: true
        },
        {
            frets: 'abba',
            fingers: '1221',
            barres: [10, 11],
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm11',
    positions: [
        {
            frets: '000002',
            fingers: '000001'
        },
        {
            frets: '005430',
            fingers: '003210'
        },
        {
            frets: 'x75775',
            fingers: '021341',
            barres: 5,
            capo: true
        },
        {
            frets: 'cacbaa',
            fingers: '314211',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'b13b9',
    positions: [
        {
            frets: '0102',
            fingers: '0102'
        },
        {
            frets: '4434',
            fingers: '2314'
        },
        {
            frets: '6777',
            fingers: '1234'
        },
        {
            frets: '9b9a',
            fingers: '1312',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: 'm69',
    positions: [
        {
            frets: '133133',
            fingers: '122134',
            barres: [1, 3],
            capo: true
        },
        {
            frets: '4x3533',
            fingers: '201311',
            barres: 3,
            capo: true
        },
        {
            frets: 'x8678x',
            fingers: '031240'
        },
        {
            frets: 'dbc0dx',
            fingers: '312040'
        }
    ]
};

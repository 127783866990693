export default {
    key: 'D',
    suffix: '6',
    positions: [
        {
            frets: 'xx0202',
            fingers: '000203'
        },
        {
            frets: 'x5443x',
            fingers: '042310'
        },
        {
            frets: 'x57777',
            fingers: '013334',
            barres: 7
        },
        {
            frets: '7977a7',
            fingers: '131141',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'C#',
    suffix: 'm6',
    positions: [
        {
            frets: 'x42324',
            fingers: '031214',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx6656',
            fingers: '002314'
        },
        {
            frets: '9x899x',
            fingers: '201340'
        },
        {
            frets: '9bb9b9',
            fingers: '123141',
            barres: 9,
            capo: true
        }
    ]
};

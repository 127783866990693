export default {
    key: 'G',
    suffix: '7',
    positions: [
        {
            frets: '320001',
            fingers: '320001'
        },
        {
            frets: '353433',
            fingers: '131211',
            barres: 3,
            capo: true
        },
        {
            frets: 'x55767',
            fingers: '011324',
            barres: 5,
            capo: true
        },
        {
            frets: 'aacaca',
            fingers: '113141',
            barres: 10,
            capo: true
        }
    ]
};

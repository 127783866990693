export default {
    key: 'D',
    suffix: '11',
    positions: [
        {
            frets: 'xx0012',
            fingers: '000012'
        },
        {
            frets: 'x54533',
            fingers: '032411',
            barres: 3,
            capo: true
        },
        {
            frets: 'x55575',
            fingers: '011131',
            barres: 5,
            capo: true
        },
        {
            frets: 'x97788',
            fingers: '111211',
            barres: 8,
            capo: true
        }
    ]
};

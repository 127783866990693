export default {
    key: 'A',
    suffix: 'minor',
    positions: [
        {
            frets: '2000',
            fingers: '2000'
        },
        {
            frets: '2003',
            fingers: '2003'
        },
        {
            frets: '2453',
            fingers: '1342'
        },
        {
            frets: '5453',
            fingers: '3241'
        }
    ]
};

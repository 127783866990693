export default {
    key: 'G',
    suffix: '/E',
    positions: [
        {
            frets: '020033',
            fingers: '010023'
        },
        {
            frets: '020003',
            fingers: '010002'
        },
        {
            frets: 'xx2433',
            fingers: '001423'
        }
    ]
};

export default {
    key: 'E',
    suffix: '/G#',
    positions: [
        {
            frets: 'xxx100',
            fingers: '000100'
        },
        {
            frets: '422100',
            fingers: '423100'
        },
        {
            frets: '476454',
            fingers: '143121',
            barres: 4
        },
        {
            frets: '42245x',
            fingers: '211340',
            barres: 2
        }
    ]
};

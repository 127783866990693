export default {
    key: 'D',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '2414',
            fingers: '2314'
        },
        {
            frets: '6557',
            fingers: '2113',
            barres: 5,
            capo: true
        },
        {
            frets: 'a997',
            fingers: '4231'
        },
        {
            frets: '9998',
            fingers: '2341'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'mmaj7',
    positions: [
        {
            frets: '1413',
            fingers: '1413',
            barres: 1,
            capo: true
        },
        {
            frets: '5547',
            fingers: '2214',
            barres: 5
        },
        {
            frets: 'a887',
            fingers: '4221',
            barres: 8
        },
        {
            frets: '9888',
            fingers: '2111',
            barres: 8,
            capo: true
        }
    ]
};

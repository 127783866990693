export default {
    key: 'D',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: 'xx0121',
            fingers: '000132'
        },
        {
            frets: '45666x',
            fingers: '123330',
            barres: 6
        },
        {
            frets: 'x5666x',
            fingers: '012340'
        },
        {
            frets: 'abbaxa',
            fingers: '122101',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '69',
    positions: [
        {
            frets: 'xx1011',
            fingers: '002034'
        },
        {
            frets: 'x33343',
            fingers: '011121',
            barres: 3,
            capo: true
        },
        {
            frets: 'x65566',
            fingers: '021134',
            barres: 5,
            capo: true
        },
        {
            frets: 'baaabb',
            fingers: '211134',
            barres: 10,
            capo: true
        }
    ]
};

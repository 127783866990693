export default {
    key: 'C',
    suffix: 'alt',
    positions: [
        {
            frets: '5423',
            fingers: '4312'
        },
        {
            frets: '5687',
            fingers: '1243'
        },
        {
            frets: '9687',
            fingers: '4132'
        }
    ]
};

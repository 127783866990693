var suffixes = [
    'major',
    'minor',
    'dim',
    'dim7',
    'sus2',
    'sus4',
    '7sus4',
    'alt',
    'aug',
    '6',
    '69',
    '7',
    '7b5',
    'aug7',
    '9',
    '9b5',
    'aug9',
    '7b9',
    '7b9#5',
    '7#9',
    '11',
    '9#11',
    '13',
    '13b9',
    '13b5b9',
    'b13b9',
    'b13#9',
    'maj7',
    'maj7b5',
    'maj7#5',
    'maj9',
    'maj11',
    'maj13',
    'm6',
    'm7',
    'm7b5',
    'm9',
    'm69',
    'm9b5',
    'm11',
    'mmaj7',
    'mmaj7b5',
    'mmaj9',
    'mmaj11',
    'add9',
    'madd9'
];
export default suffixes;

export default {
    key: 'C#',
    suffix: 'aug9',
    positions: [
        {
            frets: 'x43445',
            fingers: '021334',
            barres: 4
        },
        {
            frets: '98780x',
            fingers: '421300'
        },
        {
            frets: '9898ax',
            fingers: '213140',
            barres: 8,
            capo: true
        },
        {
            frets: '9c9a0b',
            fingers: '141203',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Db',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '8687',
            fingers: '3142'
        },
        {
            frets: '9686',
            fingers: '4131',
            barres: 6,
            capo: true
        },
        {
            frets: '9cb9',
            fingers: '1431',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'add9',
    positions: [
        {
            frets: '3343',
            fingers: '1121',
            barres: 3,
            capo: true
        },
        {
            frets: '5866',
            fingers: '1422',
            barres: 6
        },
        {
            frets: '8a8b',
            fingers: '1314',
            barres: 8,
            capo: true
        },
        {
            frets: 'dcbd',
            fingers: '3214'
        }
    ]
};

export default {
    key: 'B',
    suffix: '7b9',
    positions: [
        {
            frets: 'x21212',
            fingers: '021314',
            barres: 1,
            capo: true
        },
        {
            frets: '7675xx',
            fingers: '324100'
        },
        {
            frets: '7x7878',
            fingers: '101213',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx98a8',
            fingers: '002131',
            barres: 8
        }
    ]
};

export default {
    key: 'F#',
    suffix: '11',
    positions: [
        {
            frets: '212100',
            fingers: '314200'
        },
        {
            frets: '444456',
            fingers: '111123',
            barres: 4,
            capo: true
        },
        {
            frets: 'x98977',
            fingers: '032411',
            barres: 7,
            capo: true
        },
        {
            frets: 'x999b9',
            fingers: '011131',
            barres: 9,
            capo: true
        }
    ]
};


import React, { useEffect, useState, useCallback } from 'react'
import { Modal, Collapse, Skeleton, Empty } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import firebase from 'firebase';

interface SynonymModalProps {
  rootWord: string | undefined
  visible: boolean
  onSynonymSelected: (synonym: string) => void
  onCancel: () => void
}

interface ThesaurusEntry {
  meta?: {
    syns: string[][]
  },
  fl?: string
  shortdef?: string[]
}


const SynonymModal: React.FC<SynonymModalProps> = (props) => {
  const rootWord = props.rootWord;
  const visible = props.visible;
  const onCancel = props.onCancel;

  const [thesaurusEntries, setThesaurusEntries] = useState<ThesaurusEntry[]>([]);
  const [synonymsRoot, setSynonymsRoot] = useState('');
  const [selectedSynonym, setSelectedSynonym] = useState('');
  const [loading, setLoading] = useState(false);

  const requestSynonyms = firebase.functions().httpsCallable('default-synonyms-synonyms');

  const fetchSynonyms = useCallback(() => {
    async function fetchSynonymsInternal() {
      if (rootWord && rootWord !== synonymsRoot) {
        setLoading(true);
        const response = await requestSynonyms(rootWord);
        let body: ThesaurusEntry[] = [];
        console.log(response.data);
        body = await response.data;

        setThesaurusEntries(body);
        setSynonymsRoot(rootWord);
        setLoading(false);
      }
    }

    fetchSynonymsInternal();
  }, [rootWord, synonymsRoot]);

  useEffect(() => {
    if (visible) {
      fetchSynonyms()
    }
  }, [rootWord, visible, onCancel, fetchSynonyms]);

  function onOk() {
    if (selectedSynonym) {
      props.onSynonymSelected(selectedSynonym);
    } else {
      props.onCancel();
    }
  }

  function clickSynonym(checked: boolean, synonym: string) {
    if (checked) {
      setSelectedSynonym(synonym);
    } else {
      setSelectedSynonym('');
    }
  }

  const entries = thesaurusEntries.map(entry => ([{
    fl: entry.fl,
    entries: [entry],
  }])).reduce((prev, next) => {
    for (let e of prev) {
      if (e.fl === next[0].fl) {
        e.entries.push(next[0].entries[0])
        return prev;
      }
    }
    return prev.concat(next);
  }, []);

  return (
    <Modal
      onCancel={props.onCancel}
      onOk={onOk}
      visible={visible}>
      <h1>Synonyms for "{rootWord}"</h1>
      <Skeleton loading={loading}>
        <Collapse>
          {entries.map((e, i) => (
            <Collapse.Panel
              header={`${e.fl}`}
              key={i}>
              <div>
                {e.entries.map((entry, i) => (
                  <div key={i}>
                    { entry.shortdef ? entry.shortdef.map((def, i) => (
                      <div key={i}>
                        <h4>{def}</h4>
                        {(entry.meta && entry.meta.syns && entry.meta.syns[i]) ? entry.meta.syns[i].map((syn, i) => (
                          <CheckableTag
                            key={i}
                            checked={selectedSynonym === syn}
                            onChange={checked => clickSynonym(checked, syn)}>
                            {syn}
                          </CheckableTag>
                        )) : <Empty />}
                      </div>
                    )) : <Empty />}
                  </div>
                ))}
              </div>
            </Collapse.Panel>
          ))}
        </Collapse>
      </Skeleton>
    </Modal>
  );
};

export default SynonymModal;
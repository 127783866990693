export default {
    key: 'C',
    suffix: '/G',
    positions: [
        {
            frets: '332010',
            fingers: '342010'
        },
        {
            frets: '335553',
            fingers: '112341',
            barres: 3
        },
        {
            frets: '33255x',
            fingers: '231440',
            barres: 5
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm7',
    positions: [
        {
            frets: '022030',
            fingers: '023040'
        },
        {
            frets: '020000',
            fingers: '020000'
        },
        {
            frets: '022433',
            fingers: '011423',
            barres: 2
        },
        {
            frets: '779787',
            fingers: '113121',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx998a',
            fingers: '002314'
        }
    ]
};

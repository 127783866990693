export default {
    key: 'B',
    suffix: 'maj11',
    positions: [
        {
            frets: 'x21300',
            fingers: '021300'
        },
        {
            frets: '222342',
            fingers: '111231',
            barres: 2,
            capo: true
        },
        {
            frets: '778879',
            fingers: '112213',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx99bb',
            fingers: '001134',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'b13#9',
    positions: [
        {
            frets: '3200',
            fingers: '3200'
        },
        {
            frets: '2201',
            fingers: '2301'
        },
        {
            frets: '3455',
            fingers: '1234'
        },
        {
            frets: '7967',
            fingers: '2413'
        }
    ]
};

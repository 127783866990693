export default {
    key: 'Ab',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '445446',
            fingers: '112114',
            barres: 4,
            capo: true
        },
        {
            frets: 'x66687',
            fingers: '011132',
            barres: 6,
            capo: true
        },
        {
            frets: 'xb9cb9',
            fingers: '021431',
            barres: 9,
            capo: true
        },
        {
            frets: 'bbbccb',
            fingers: '111231',
            barres: 11,
            capo: true
        }
    ]
};

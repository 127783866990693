export default {
    key: 'C',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '1002',
            fingers: '1002'
        },
        {
            frets: '5442',
            fingers: '4231'
        },
        {
            frets: '4443',
            fingers: '2341'
        },
        {
            frets: '5877',
            fingers: '1423'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: '9#11',
    positions: [
        {
            frets: '4x453x',
            fingers: '203410'
        },
        {
            frets: 'xx6778',
            fingers: '001234'
        },
        {
            frets: 'xbabba',
            fingers: '021341',
            barres: 10,
            capo: true
        },
        {
            frets: 'xbcbdb',
            fingers: '012131',
            barres: 11,
            capo: true
        }
    ]
};

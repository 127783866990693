export default {
    key: 'E',
    suffix: 'maj7',
    positions: [
        {
            frets: '021100',
            fingers: '031200'
        },
        {
            frets: 'xx2444',
            fingers: '001333',
            barres: 4
        },
        {
            frets: 'x76444',
            fingers: '043111',
            barres: 4,
            capo: true
        },
        {
            frets: '779897',
            fingers: '113241',
            barres: 7,
            capo: true
        }
    ]
};

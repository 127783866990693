export default {
    key: 'G',
    suffix: 'dim7',
    positions: [
        {
            frets: '31x320',
            fingers: '310420'
        },
        {
            frets: '3x2320',
            fingers: '301420'
        },
        {
            frets: '345353',
            fingers: '123141',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx5656',
            fingers: '001324'
        }
    ]
};

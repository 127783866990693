export default {
    key: 'F',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '102220',
            fingers: '102340'
        },
        {
            frets: '587655',
            fingers: '143211',
            barres: 5,
            capo: true
        },
        {
            frets: 'x8b9ax',
            fingers: '014230'
        },
        {
            frets: 'xxfeec',
            fingers: '004231'
        }
    ]
};

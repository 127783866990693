export default {
    key: 'D',
    suffix: 'm9',
    positions: [
        {
            frets: '100210',
            fingers: '100320'
        },
        {
            frets: 'x53555',
            fingers: '021344',
            barres: 5
        },
        {
            frets: 'x57560',
            fingers: '014230'
        },
        {
            frets: 'acaaac',
            fingers: '131114',
            barres: 10,
            capo: true
        }
    ]
};

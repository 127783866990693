export default {
    key: 'F',
    suffix: 'm/C',
    positions: [
        {
            frets: 'x33111',
            fingers: '034111',
            barres: 1
        },
        {
            frets: 'x33114',
            fingers: '023114',
            barres: 1
        },
        {
            frets: '88aa98',
            fingers: '113421',
            barres: 8
        }
    ]
};

import React, { ReactElement } from 'react'

interface VisibilityProps {
  visible?: boolean
  hiddenElement?: ReactElement
}

const Visibility: React.FC<VisibilityProps> = ({ 
  visible, 
  hiddenElement,
  children
}) => {
  if (visible) {
    return <>{children}</>;
  }
  if (hiddenElement) {
    return hiddenElement;
  }
  return <></>;
};

export function visibility({visible, hiddenElement}: VisibilityProps, visibleElement: ReactElement) {
  if (!visible) {
    if (hiddenElement) {
      return hiddenElement;
    }
    return undefined;
  }
  return visibleElement;
}

export default Visibility;
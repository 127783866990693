export default {
    key: 'Gb',
    suffix: '7#9',
    positions: [
        {
            frets: '2101',
            fingers: '3102'
        },
        {
            frets: '3100',
            fingers: '3100'
        },
        {
            frets: '3454',
            fingers: '1243'
        },
        {
            frets: '6967',
            fingers: '1412',
            barres: 6,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'madd9',
    positions: [
        {
            frets: 'x4331x',
            fingers: '042310'
        },
        {
            frets: '64x56x',
            fingers: '310240'
        },
        {
            frets: 'xx8668',
            fingers: '003114',
            barres: 6,
            capo: true
        },
        {
            frets: 'xdbadx',
            fingers: '032140'
        }
    ]
};

export default {
    key: 'F',
    suffix: '7',
    positions: [
        {
            frets: '2313',
            fingers: '2314'
        },
        {
            frets: '5556',
            fingers: '1112',
            barres: 5,
            capo: true
        },
        {
            frets: '8988',
            fingers: '1211',
            barres: 8,
            capo: true
        },
        {
            frets: 'acbc',
            fingers: '1324'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'maj13',
    positions: [
        {
            frets: '0200',
            fingers: '0200'
        },
        {
            frets: '2435',
            fingers: '1324'
        },
        {
            frets: '7757',
            fingers: '2314'
        },
        {
            frets: '99aa',
            fingers: '1122',
            barres: [9, 10],
            capo: true
        }
    ]
};

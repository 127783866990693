export default {
    key: 'E',
    suffix: 'm/F',
    positions: [
        {
            frets: 'xx3000',
            fingers: '001000'
        },
        {
            frets: '122000',
            fingers: '123000'
        },
        {
            frets: '122003',
            fingers: '123004'
        }
    ]
};

export default {
    key: 'G',
    suffix: '6',
    positions: [
        {
            frets: '0202',
            fingers: '0102'
        },
        {
            frets: '4435',
            fingers: '2314'
        },
        {
            frets: '7777',
            fingers: '1111',
            barres: 7,
            capo: true
        },
        {
            frets: '9baa',
            fingers: '1423'
        }
    ]
};

export default {
    key: 'C#',
    suffix: '7',
    positions: [
        {
            frets: 'x4342x',
            fingers: '032410'
        },
        {
            frets: 'x46464',
            fingers: '013141',
            barres: 4,
            capo: true
        },
        {
            frets: '986667',
            fingers: '431112',
            barres: 6,
            capo: true
        },
        {
            frets: '9b9a99',
            fingers: '131211',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'major',
    positions: [
        {
            frets: '2100',
            fingers: '2100'
        },
        {
            frets: '2454',
            fingers: '1243'
        },
        {
            frets: '6454',
            fingers: '4121',
            barres: 4,
            capo: true
        },
        {
            frets: '999c',
            fingers: '1114',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: '7#9',
    positions: [
        {
            frets: '320301',
            fingers: '320401'
        },
        {
            frets: '353466',
            fingers: '131244',
            barres: [3, 6],
            capo: true
        },
        {
            frets: 'x55466',
            fingers: '023144',
            barres: 6
        },
        {
            frets: 'xa9abx',
            fingers: '021340'
        }
    ]
};

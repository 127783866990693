export default {
    key: 'E',
    suffix: 'major',
    positions: [
        {
            frets: '1402',
            fingers: '1402'
        },
        {
            frets: '4442',
            fingers: '2341'
        },
        {
            frets: '4447',
            fingers: '1114',
            barres: 4,
            capo: true
        },
        {
            frets: '9877',
            fingers: '4311',
            barres: 7,
            capo: true
        }
    ]
};

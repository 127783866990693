export default {
    key: 'A',
    suffix: 'major',
    positions: [
        {
            frets: 'x02220',
            fingers: '001230'
        },
        {
            frets: 'x02225',
            fingers: '001114',
            barres: 2,
            capo: true
        },
        {
            frets: '577655',
            fingers: '134211',
            barres: 5,
            capo: true
        },
        {
            frets: 'x079a9',
            fingers: '001243'
        }
    ]
};

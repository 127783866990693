export default {
    key: 'Db',
    suffix: 'sus2',
    positions: [
        {
            frets: '1344',
            fingers: '1233',
            barres: 4
        },
        {
            frets: '6896',
            fingers: '1341',
            barres: 6,
            capo: true
        },
        {
            frets: '889b',
            fingers: '1124',
            barres: 8,
            capo: true
        },
        {
            frets: 'ddbb',
            fingers: '3411',
            barres: 11,
            capo: true
        }
    ]
};

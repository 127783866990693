export default {
    key: 'F#',
    suffix: '13',
    positions: [
        {
            frets: '221300',
            fingers: '231400'
        },
        {
            frets: '222344',
            fingers: '111234',
            barres: 2,
            capo: true
        },
        {
            frets: 'x98870',
            fingers: '042310'
        },
        {
            frets: '9999bb',
            fingers: '111134',
            barres: 9,
            capo: true
        }
    ]
};

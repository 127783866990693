export default {
    key: 'Gb',
    suffix: 'maj11',
    positions: [
        {
            frets: '3542',
            fingers: '2431'
        },
        {
            frets: 'dbdd',
            fingers: '2134'
        }
    ]
};

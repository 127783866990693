export default {
    key: 'A',
    suffix: 'm7b5',
    positions: [
        {
            frets: 'x0101x',
            fingers: '002030'
        },
        {
            frets: '5x554x',
            fingers: '203410'
        },
        {
            frets: '567585',
            fingers: '123141',
            barres: 5,
            capo: true
        },
        {
            frets: 'xx7888',
            fingers: '001222',
            barres: 8
        }
    ]
};

export default {
    key: 'B',
    suffix: 'minor',
    positions: [
        {
            frets: '4222',
            fingers: '3111',
            barres: 2,
            capo: true
        },
        {
            frets: '4225',
            fingers: '3114',
            barres: 2,
            capo: true
        },
        {
            frets: '4675',
            fingers: '1342'
        },
        {
            frets: '7675',
            fingers: '3241'
        }
    ]
};

export default {
    key: 'D',
    suffix: '13',
    positions: [
        {
            frets: 'xx0412',
            fingers: '000412'
        },
        {
            frets: '555577',
            fingers: '111134',
            barres: 5,
            capo: true
        },
        {
            frets: 'a90908',
            fingers: '420301'
        },
        {
            frets: 'axabcx',
            fingers: '102340'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '1431',
            fingers: '1431',
            barres: 1,
            capo: true
        },
        {
            frets: 'cacb',
            fingers: '3142'
        },
        {
            frets: 'daca',
            fingers: '4131',
            barres: 10,
            capo: true
        }
    ]
};

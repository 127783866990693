export default {
    key: 'G',
    suffix: 'major',
    positions: [
        {
            frets: '0232',
            fingers: '0132'
        },
        {
            frets: '4232',
            fingers: '3121',
            barres: 2,
            capo: true
        },
        {
            frets: '4235',
            fingers: '3124'
        },
        {
            frets: '777a',
            fingers: '1114',
            barres: 7,
            capo: true
        }
    ]
};

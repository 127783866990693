export default {
    key: 'E',
    suffix: '9b5',
    positions: [
        {
            frets: '1221',
            fingers: '1221',
            barres: [1, 2],
            capo: true
        },
        {
            frets: '3645',
            fingers: '1423'
        },
        {
            frets: '7869',
            fingers: '2314'
        },
        {
            frets: 'baab',
            fingers: '2113',
            barres: 10,
            capo: true
        }
    ]
};

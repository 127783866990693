import React, { ReactElement } from 'react'
import { useFeature, isEnabled } from '../../hooks/Features';
import Visibility, { visibility } from '../common/Visibility';
import { FeatureName } from '../../config/Features';


interface FeatureProps {
  feature: FeatureName
  disabledElement?: ReactElement
}

const Feature: React.FC<FeatureProps> = ({
  feature,
  disabledElement,
  children,
}) => {
  const _feature = useFeature(feature);
  return (
    <Visibility 
      visible={isEnabled(_feature)}
      hiddenElement={disabledElement}>
      {children}
    </Visibility>
  );
};

// For use in contexts where empty <div> is not allowed
export function feature({ feature, disabledElement }: FeatureProps, enabledElement: ReactElement) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _feature = useFeature(feature);
  return visibility({
    visible: isEnabled(_feature),
    hiddenElement: disabledElement,
  }, enabledElement);
}

export default Feature;
export default {
    key: 'D',
    suffix: 'm7',
    positions: [
        {
            frets: '2213',
            fingers: '2213',
            barres: 2
        },
        {
            frets: '5555',
            fingers: '1111',
            barres: 5,
            capo: true
        },
        {
            frets: '7988',
            fingers: '1322',
            barres: 8
        },
        {
            frets: 'acac',
            fingers: '1324'
        }
    ]
};

import React, { ReactElement } from 'react'
import { useDebugMode } from '../../hooks/DebugMode';
import Visibility, { visibility } from '../common/Visibility';

const Debug: React.FC = (props) => {
  const {enabled} = useDebugMode();
  return (
    <Visibility visible={enabled}>
      {props.children}
    </Visibility>
  );
}

// For use in contexts where empty <div> is not allowed
export function debug(element: ReactElement): ReactElement | undefined {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { enabled } = useDebugMode();
  return visibility({
    visible: enabled
  }, element);
};

export default Debug;
export default {
    key: 'B',
    suffix: 'm11',
    positions: [
        {
            frets: 'x20220',
            fingers: '010230'
        },
        {
            frets: '757655',
            fingers: '314211',
            barres: 5,
            capo: true
        },
        {
            frets: '777779',
            fingers: '111114',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx99aa',
            fingers: '001123',
            barres: [9, 10],
            capo: true
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'm7',
    positions: [
        {
            frets: '2424',
            fingers: '1324'
        },
        {
            frets: '6657',
            fingers: '2213',
            barres: 6
        },
        {
            frets: '9999',
            fingers: '1111',
            barres: 9,
            capo: true
        },
        {
            frets: 'bdcc',
            fingers: '1322',
            barres: 12
        }
    ]
};

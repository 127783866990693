export default {
    key: 'Eb',
    suffix: 'm69',
    positions: [
        {
            frets: '2x1311',
            fingers: '201311',
            barres: 1
        },
        {
            frets: 'x6456x',
            fingers: '031240'
        },
        {
            frets: 'b9aa99',
            fingers: '412300',
            barres: 9,
            capo: true
        },
        {
            frets: 'xxdbdd',
            fingers: '002134'
        }
    ]
};

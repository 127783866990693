export default {
    key: 'Bb',
    suffix: 'major',
    positions: [
        {
            frets: '3211',
            fingers: '3211',
            barres: 1,
            capo: true
        },
        {
            frets: '3565',
            fingers: '1243'
        },
        {
            frets: '7565',
            fingers: '3121',
            barres: 5,
            capo: true
        },
        {
            frets: 'aaad',
            fingers: '1114',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'add9',
    positions: [
        {
            frets: '1422',
            fingers: '1422',
            barres: 2
        },
        {
            frets: '4647',
            fingers: '1314',
            barres: 4,
            capo: true
        },
        {
            frets: '9879',
            fingers: '3214'
        },
        {
            frets: 'bbcb',
            fingers: '1121',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'madd9',
    positions: [
        {
            frets: 'xx4224',
            fingers: '003114',
            barres: 2,
            capo: true
        },
        {
            frets: '244224',
            fingers: '123114',
            barres: 2,
            capo: true
        },
        {
            frets: 'x9769x',
            fingers: '032140'
        },
        {
            frets: 'x97x99',
            fingers: '021034'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'maj11',
    positions: [
        {
            frets: '2431',
            fingers: '2431'
        },
        {
            frets: 'cacc',
            fingers: '2134'
        }
    ]
};

export default {
    key: 'A',
    suffix: '7b9',
    positions: [
        {
            frets: '0101',
            fingers: '0102'
        },
        {
            frets: '3434',
            fingers: '1324'
        },
        {
            frets: '6767',
            fingers: '1324'
        },
        {
            frets: '9a9a',
            fingers: '1324'
        }
    ]
};

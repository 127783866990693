export default {
    key: 'D',
    suffix: '7#9',
    positions: [
        {
            frets: 'x5456x',
            fingers: '021340'
        },
        {
            frets: '000a78',
            fingers: '000412'
        },
        {
            frets: 'x87778',
            fingers: '021113',
            barres: 7,
            capo: true
        },
        {
            frets: 'acabad',
            fingers: '131214',
            barres: 10,
            capo: true
        }
    ]
};

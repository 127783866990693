export default {
    key: 'B',
    suffix: '7',
    positions: [
        {
            frets: '2322',
            fingers: '1211',
            barres: 2,
            capo: true
        },
        {
            frets: '4656',
            fingers: '1324'
        },
        {
            frets: '8979',
            fingers: '2314'
        },
        {
            frets: 'bbbc',
            fingers: '1112',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'C#',
    suffix: 'maj9',
    positions: [
        {
            frets: 'x41111',
            fingers: '041111',
            barres: 1,
            capo: true
        },
        {
            frets: 'x4354x',
            fingers: '021430'
        },
        {
            frets: '98a898',
            fingers: '214131',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxbadb',
            fingers: '002143'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'm7',
    positions: [
        {
            frets: '1313',
            fingers: '1324'
        },
        {
            frets: '5546',
            fingers: '2213',
            barres: 5
        },
        {
            frets: '8888',
            fingers: '1111',
            barres: 8,
            capo: true
        },
        {
            frets: 'acbb',
            fingers: '1322',
            barres: 11
        }
    ]
};

export default {
    key: 'D',
    suffix: 'aug7',
    positions: [
        {
            frets: 'xx0312',
            fingers: '000412'
        },
        {
            frets: 'x545x6',
            fingers: '021304'
        },
        {
            frets: 'x58576',
            fingers: '014132',
            barres: 5,
            capo: true
        },
        {
            frets: 'axabbx',
            fingers: '102340'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'add9',
    positions: [
        {
            frets: '4324',
            fingers: '3214'
        },
        {
            frets: '6676',
            fingers: '1121',
            barres: 6
        },
        {
            frets: '8b99',
            fingers: '1422',
            barres: 9
        },
        {
            frets: 'bdbe',
            fingers: '1314',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'sus2',
    positions: [
        {
            frets: '2xx122',
            fingers: '200134'
        },
        {
            frets: '444674',
            fingers: '111341',
            barres: 4,
            capo: true
        },
        {
            frets: '99bb99',
            fingers: '113411',
            barres: 9,
            capo: true
        },
        {
            frets: 'ebbdex',
            fingers: '311240',
            barres: 11,
            capo: true
        }
    ]
};

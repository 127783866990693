export default {
    key: 'A',
    suffix: '/E',
    positions: [
        {
            frets: '002220',
            fingers: '001230'
        },
        {
            frets: 'x77655',
            fingers: '034211',
            barres: 5
        },
        {
            frets: 'xx2225',
            fingers: '001114',
            barres: 2
        }
    ]
};

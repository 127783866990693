export default {
    key: 'C',
    suffix: 'maj9',
    positions: [
        {
            frets: 'x30000',
            fingers: '030000'
        },
        {
            frets: '33243x',
            fingers: '221430',
            barres: 3
        },
        {
            frets: '055557',
            fingers: '011113',
            barres: 5,
            capo: true
        },
        {
            frets: '8x998a',
            fingers: '102314',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'mmaj11',
    positions: [
        {
            frets: 'xx0021',
            fingers: '000021'
        },
        {
            frets: 'x53653',
            fingers: '021431',
            barres: 3,
            capo: true
        },
        {
            frets: '555775',
            fingers: '111231',
            barres: 5,
            capo: true
        },
        {
            frets: 'aabaac',
            fingers: '112114',
            barres: 10,
            capo: true
        }
    ]
};

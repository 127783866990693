export default {
    key: 'Db',
    suffix: 'maj13',
    positions: [
        {
            frets: '3313',
            fingers: '2314'
        },
        {
            frets: '5566',
            fingers: '1122',
            barres: [5, 6],
            capo: true
        },
        {
            frets: '8a88',
            fingers: '1311',
            barres: 8,
            capo: true
        },
        {
            frets: 'acbd',
            fingers: '1324'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: 'xx1232',
            fingers: '001243'
        },
        {
            frets: '56777x',
            fingers: '123330',
            barres: 7
        },
        {
            frets: 'x6777x',
            fingers: '012340'
        },
        {
            frets: 'bccbxb',
            fingers: '122101',
            barres: [11, 12],
            capo: true
        }
    ]
};

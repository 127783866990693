import React, { useState } from 'react'
import { useAuth } from '../../hooks/Auth';
import Authenticated from './Authenticated';
import { Button, Modal, Form, Input } from 'antd';
import { navigate } from '@reach/router';
import { firstExisting } from '@chordpad/common-ts/dist/arrays';

const DeactivateAccountButton: React.FC = () => {
  const auth = useAuth();
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [confirmDeactivateText, setConfirmDeactivateText] = useState('');

  const confirmDeactivateTest = firstExisting(
    'I understand.',
    auth.user?.email,
    auth.user?.displayName,
    auth.user?.phoneNumber
  )

  const confirmDeactivateValid = confirmDeactivateText === confirmDeactivateTest;
  const confirmDeactivateValidateStatus = confirmDeactivateValid ? 'success' : 'warning';

  function deactivateAccount() {
    auth.user?.delete();
    navigate('/');
  }

  return (
    <Authenticated>
      <Button
        type='default'
        onClick={() => setConfirmDeactivate(true)} color='red'>
        Deactivate Account
      </Button>
      <Modal
        title='Are you sure?'
        visible={confirmDeactivate}
        onCancel={() => setConfirmDeactivate(false)}
        footer={(
          <Button 
            onClick={deactivateAccount}
            disabled={!confirmDeactivateValid}>
              Delete
          </Button>
        )}>
        <p>This will permanently delete your account and data.</p>
        <p>This operation is irreversible.</p>
        <Form>
          <Form.Item validateStatus={confirmDeactivateValidateStatus} help={`Write "${confirmDeactivateTest}" to proceed.`}>
            <Input
              placeholder={confirmDeactivateTest}
              value={confirmDeactivateText}
              onChange={(e) => setConfirmDeactivateText(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </Authenticated>
  );
};

export default DeactivateAccountButton;
export default {
    key: 'Ab',
    suffix: '7b5',
    positions: [
        {
            frets: '4x453x',
            fingers: '203410'
        },
        {
            frets: 'xx6778',
            fingers: '001234'
        },
        {
            frets: 'x9ab9a',
            fingers: '012413',
            barres: 9,
            capo: true
        },
        {
            frets: 'xbcbdx',
            fingers: '012130',
            barres: 11,
            capo: true
        }
    ]
};

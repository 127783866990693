export default {
    key: 'G',
    suffix: '/B',
    positions: [
        {
            frets: 'x20033',
            fingers: '010023'
        },
        {
            frets: 'x20003',
            fingers: '010002'
        },
        {
            frets: 'x25433',
            fingers: '014322',
            barres: 3
        }
    ]
};

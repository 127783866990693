export default {
    key: 'Ab',
    suffix: 'alt',
    positions: [
        {
            frets: '5243',
            fingers: '4132'
        },
        {
            frets: '5885',
            fingers: '1341',
            barres: 5,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: 'm9',
    positions: [
        {
            frets: '2224',
            fingers: '1113',
            barres: 2,
            capo: true
        },
        {
            frets: '7654',
            fingers: '4321'
        },
        {
            frets: '6655',
            fingers: '2211',
            barres: [5, 6],
            capo: true
        },
        {
            frets: '7999',
            fingers: '1234'
        }
    ]
};

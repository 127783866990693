export default {
    key: 'G',
    suffix: 'madd9',
    positions: [
        {
            frets: '3230',
            fingers: '2130'
        },
        {
            frets: '2231',
            fingers: '2231',
            barres: 2
        },
        {
            frets: 'caac',
            fingers: '3114',
            barres: 10,
            capo: true
        }
    ]
};

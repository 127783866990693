export default {
    key: 'F',
    suffix: '11',
    positions: [
        {
            frets: '1220',
            fingers: '1230'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'sus2',
    positions: [
        {
            frets: '4674',
            fingers: '1341',
            barres: 4,
            capo: true
        },
        {
            frets: '6679',
            fingers: '1124',
            barres: 6,
            capo: true
        },
        {
            frets: 'bb99',
            fingers: '3411',
            barres: 9,
            capo: true
        },
        {
            frets: 'bdee',
            fingers: '1233',
            barres: 14
        }
    ]
};

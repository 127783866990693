import React, { useMemo, ReactElement } from 'react'
import { useAuth } from '../../hooks/Auth';
import SignInButton from './SignInButton';
import { Alert } from 'antd';

type AuthenticatedStrategy = 'hide' | 'signInButton' | 'message';

interface AuthenticatedProps {
  strategy?: AuthenticatedStrategy
  unauthenticated?: React.ReactElement
}

const Authenticated: React.FC<AuthenticatedProps> = (props) => {
  const element = authenticated(<div>{props.children}</div>, props);
  if (element) return element;
  return <div></div>;
};

export function authenticated(element: ReactElement, props?: AuthenticatedProps) {
  const { strategy = undefined, unauthenticated = undefined } = props ? props : {};
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const auth = useAuth();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _strategy = useMemo(() => strategy !== undefined ? strategy : 'hide', [strategy]);

  if (auth.user) {
    return element;
  } else {
    if (unauthenticated) {
      return unauthenticated;
    }
    switch (_strategy) {
      case 'signInButton':
        return <SignInButton />;
      case 'hide': 
        return undefined;
      case 'message':
        return <Alert message='You must be signed in to view this.'/>;
    }
  }
}

export default Authenticated;
export default {
    key: 'G',
    suffix: '7b5',
    positions: [
        {
            frets: '0112',
            fingers: '0123'
        },
        {
            frets: '4534',
            fingers: '2413'
        },
        {
            frets: '6778',
            fingers: '1234'
        },
        {
            frets: 'ab9a',
            fingers: '2413'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'm7b5',
    positions: [
        {
            frets: '3xx321',
            fingers: '300421'
        },
        {
            frets: '3x332x',
            fingers: '203410'
        },
        {
            frets: 'xx5666',
            fingers: '001222',
            barres: 6
        },
        {
            frets: 'xababx',
            fingers: '013240'
        }
    ]
};

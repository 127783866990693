export default {
    key: 'B',
    suffix: 'mmaj9',
    positions: [
        {
            frets: 'x20322',
            fingers: '010423'
        },
        {
            frets: '75860x',
            fingers: '314200'
        },
        {
            frets: '798779',
            fingers: '132114',
            barres: 7,
            capo: true
        },
        {
            frets: 'xecfex',
            fingers: '031430'
        }
    ]
};

export default {
    key: 'C',
    suffix: 'maj11',
    positions: [
        {
            frets: '7577',
            fingers: '2134'
        },
        {
            frets: '9ba8',
            fingers: '2431'
        }
    ]
};

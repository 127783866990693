export default {
    key: 'C',
    suffix: 'major',
    positions: [
        {
            frets: '0003',
            fingers: '0003'
        },
        {
            frets: '0433',
            fingers: '0211',
            barres: 3
        },
        {
            frets: '5433',
            fingers: '3211',
            barres: 3,
            capo: true
        },
        {
            frets: '5787',
            fingers: '1243'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'm7b5',
    positions: [
        {
            frets: '1312',
            fingers: '1312',
            barres: 1,
            capo: true
        },
        {
            frets: '4546',
            fingers: '1213',
            barres: 4,
            capo: true
        },
        {
            frets: '8878',
            fingers: '2314'
        },
        {
            frets: 'abbb',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'dim',
    positions: [
        {
            frets: '42x43x',
            fingers: '310420'
        },
        {
            frets: 'xx67x7',
            fingers: '001203'
        },
        {
            frets: 'xb9x9a',
            fingers: '041023'
        },
        {
            frets: 'xbcdcx',
            fingers: '012430'
        }
    ]
};

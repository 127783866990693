export default {
    key: 'Ab',
    suffix: '13b5b9',
    positions: [
        {
            frets: '2525',
            fingers: '1314',
            barres: 2,
            capo: true
        },
        {
            frets: '7658',
            fingers: '3214'
        },
        {
            frets: 'b9a8',
            fingers: '4231'
        },
        {
            frets: 'a9a9',
            fingers: '3142'
        }
    ]
};

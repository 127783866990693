import firebase from "firebase"

export interface ReadDoc<T> {
  doc: firebase.firestore.DocumentReference<T>
}

export interface WriteDoc<T> extends ReadDoc<T> {
  write: (value: T) => Promise<void>
}

export interface ArchiveDoc<T> extends WriteDoc<T> {
  archive: () => Promise<void>
  unarchive: () => Promise<void>
}

export interface Timestamped {
  createdAt?: firebase.firestore.Timestamp,
  updatedAt?: firebase.firestore.Timestamp,
}

export interface Archivable {
  id: string,
  archived: boolean,
}

export function archive(collectionPath: string, archivable: Archivable) {
  return firebase.firestore().collection(collectionPath)
    .doc(archivable.id)
    .update({ archived: true })
}

export function unarchive(collectionPath: string, archivable: Archivable) {
  return firebase.firestore().collection(collectionPath)
    .doc(archivable.id)
    .update({ archived: false })
}
export default {
    key: 'C',
    suffix: '/E',
    positions: [
        {
            frets: '032010',
            fingers: '032010'
        },
        {
            frets: '032013',
            fingers: '032014'
        },
        {
            frets: 'x75588',
            fingers: '031144',
            barres: 5
        }
    ]
};

export default {
    key: 'C',
    suffix: '6',
    positions: [
        {
            frets: '0000',
            fingers: '0000'
        },
        {
            frets: '2433',
            fingers: '1423'
        },
        {
            frets: '5757',
            fingers: '1324'
        },
        {
            frets: '998a',
            fingers: '2213',
            barres: 9
        }
    ]
};

export default {
    key: 'F',
    suffix: 'sus4',
    positions: [
        {
            frets: '133311',
            fingers: '123411',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3566',
            fingers: '001234'
        },
        {
            frets: '88aab8',
            fingers: '112341',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxaabx',
            fingers: '001120',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: '13b9',
    positions: [
        {
            frets: '1214',
            fingers: '1214',
            barres: 1,
            capo: true
        },
        {
            frets: '7544',
            fingers: '4211',
            barres: 4,
            capo: true
        },
        {
            frets: '6545',
            fingers: '4213'
        },
        {
            frets: 'adab',
            fingers: '1412',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'mmaj7',
    positions: [
        {
            frets: '2214',
            fingers: '2214',
            barres: 2
        },
        {
            frets: '7554',
            fingers: '4221',
            barres: 5
        },
        {
            frets: '6555',
            fingers: '2111',
            barres: 5,
            capo: true
        },
        {
            frets: '7998',
            fingers: '1342'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'sus4',
    positions: [
        {
            frets: '224452',
            fingers: '112341',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4457',
            fingers: '001124',
            barres: 4,
            capo: true
        },
        {
            frets: '799977',
            fingers: '123411',
            barres: 7,
            capo: true
        },
        {
            frets: 'x99bcc',
            fingers: '011344',
            barres: [9, 12],
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: '69',
    positions: [
        {
            frets: 'x32233',
            fingers: '031134',
            barres: 2,
            capo: true
        },
        {
            frets: 'x30055',
            fingers: '010034'
        },
        {
            frets: '877788',
            fingers: '211134',
            barres: 7,
            capo: true
        },
        {
            frets: 'xaa9aa',
            fingers: '022134',
            barres: 10
        }
    ]
};

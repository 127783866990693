export default {
    key: 'F#',
    suffix: '9b5',
    positions: [
        {
            frets: '212112',
            fingers: '213114',
            barres: 1,
            capo: true
        },
        {
            frets: '212110',
            fingers: '213110',
            barres: 1
        },
        {
            frets: 'x34354',
            fingers: '012143',
            barres: 3,
            capo: true
        },
        {
            frets: 'x98998',
            fingers: '021341',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: 'm9',
    positions: [
        {
            frets: '131113',
            fingers: '131114',
            barres: 1,
            capo: true
        },
        {
            frets: 'x33044',
            fingers: '012034'
        },
        {
            frets: 'x86888',
            fingers: '021344',
            barres: 8
        },
        {
            frets: 'dbd0dd',
            fingers: '213044',
            barres: 13
        }
    ]
};

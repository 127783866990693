export default {
    key: 'D',
    suffix: 'm11',
    positions: [
        {
            frets: '9788',
            fingers: '3122',
            barres: 8
        },
        {
            frets: 'a787',
            fingers: '4121',
            barres: 7,
            capo: true
        },
        {
            frets: 'acca',
            fingers: '1341',
            barres: 10,
            capo: true
        }
    ]
};

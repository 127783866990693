export default {
    key: 'C',
    suffix: 'm7',
    positions: [
        {
            frets: '3333',
            fingers: '1111',
            barres: 3,
            capo: true
        },
        {
            frets: '5766',
            fingers: '1322',
            barres: 6
        },
        {
            frets: '8a8a',
            fingers: '1324'
        },
        {
            frets: 'ccbd',
            fingers: '2213',
            barres: 12
        }
    ]
};

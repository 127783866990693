export default {
    key: 'D',
    suffix: '9b5',
    positions: [
        {
            frets: 'x54554',
            fingers: '021341',
            barres: 4,
            capo: true
        },
        {
            frets: 'x56570',
            fingers: '013240'
        },
        {
            frets: 'a9a99a',
            fingers: '213114',
            barres: 9,
            capo: true
        },
        {
            frets: 'ababxc',
            fingers: '121304',
            barres: 10
        }
    ]
};

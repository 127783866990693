export default {
    key: 'A',
    suffix: 'sus2',
    positions: [
        {
            frets: 'x02200',
            fingers: '002300'
        },
        {
            frets: 'x02400',
            fingers: '001400'
        },
        {
            frets: '7779a7',
            fingers: '111341',
            barres: 7,
            capo: true
        },
        {
            frets: 'x09900',
            fingers: '001200'
        }
    ]
};

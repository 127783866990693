export default {
    key: 'G',
    suffix: '7',
    positions: [
        {
            frets: '0212',
            fingers: '0213'
        },
        {
            frets: '4535',
            fingers: '2314'
        },
        {
            frets: '7778',
            fingers: '1112',
            barres: 7,
            capo: true
        },
        {
            frets: 'abaa',
            fingers: '1211',
            barres: 10,
            capo: true
        }
    ]
};

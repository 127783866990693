export default {
    key: 'Ab',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '4x5304',
            fingers: '204103'
        },
        {
            frets: '465446',
            fingers: '132114',
            barres: 4,
            capo: true
        },
        {
            frets: '666887',
            fingers: '111342',
            barres: 6,
            capo: true
        },
        {
            frets: 'xb9cbx',
            fingers: '021430'
        }
    ]
};

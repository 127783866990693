export default {
    key: 'D',
    suffix: '/C',
    positions: [
        {
            frets: 'x30232',
            fingers: '030142'
        },
        {
            frets: 'x34232',
            fingers: '024131',
            barres: 2
        },
        {
            frets: '85777x',
            fingers: '413330',
            barres: 7
        }
    ]
};

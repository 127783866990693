export default {
    key: 'F',
    suffix: 'dim7',
    positions: [
        {
            frets: '1x0101',
            fingers: '100203'
        },
        {
            frets: 'xx3434',
            fingers: '001314',
            barres: 3,
            capo: true
        },
        {
            frets: '789797',
            fingers: '123141',
            barres: 7,
            capo: true
        },
        {
            frets: 'dxcdcx',
            fingers: '301420'
        }
    ]
};

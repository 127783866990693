export default {
    key: 'B',
    suffix: '9b5',
    positions: [
        {
            frets: '2314',
            fingers: '2314'
        },
        {
            frets: '6556',
            fingers: '2113',
            barres: 5,
            capo: true
        },
        {
            frets: '8998',
            fingers: '1221',
            barres: [8, 9],
            capo: true
        },
        {
            frets: 'adbc',
            fingers: '1423'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'aug9',
    positions: [
        {
            frets: '2312',
            fingers: '2413'
        },
        {
            frets: '4556',
            fingers: '1223',
            barres: 5
        },
        {
            frets: '8978',
            fingers: '2413'
        },
        {
            frets: 'abbc',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'E',
    suffix: 'sus4',
    positions: [
        {
            frets: '2452',
            fingers: '1231',
            barres: 2,
            capo: true
        },
        {
            frets: '4452',
            fingers: '2341'
        },
        {
            frets: '4457',
            fingers: '1124',
            barres: 4,
            capo: true
        },
        {
            frets: '9977',
            fingers: '3411',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: 'dim',
    positions: [
        {
            frets: '5323',
            fingers: '4213'
        },
        {
            frets: '5686',
            fingers: '1243'
        },
        {
            frets: '8686',
            fingers: '3141',
            barres: 6,
            capo: true
        },
        {
            frets: 'bcb9',
            fingers: '2431'
        }
    ]
};

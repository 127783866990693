export default {
    key: 'B',
    suffix: '13',
    positions: [
        {
            frets: 'x21244',
            fingers: '021344',
            barres: 4
        },
        {
            frets: '222244',
            fingers: '111134',
            barres: 2,
            capo: true
        },
        {
            frets: '746445',
            fingers: '413112',
            barres: 4,
            capo: true
        },
        {
            frets: '777899',
            fingers: '111234',
            barres: 7,
            capo: true
        }
    ]
};

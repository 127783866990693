export default {
    key: 'A',
    suffix: 'm11',
    positions: [
        {
            frets: '4233',
            fingers: '3122',
            barres: 3
        },
        {
            frets: '5232',
            fingers: '4121',
            barres: 2,
            capo: true
        },
        {
            frets: '5775',
            fingers: '1341',
            barres: 5,
            capo: true
        }
    ]
};

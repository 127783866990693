export default {
    key: 'D',
    suffix: '/Bb',
    positions: [
        {
            frets: 'x10232',
            fingers: '010243'
        },
        {
            frets: 'x14232',
            fingers: '014232',
            barres: 2
        },
        {
            frets: '65777x',
            fingers: '213330',
            barres: 7
        }
    ]
};

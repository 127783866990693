export default {
    key: 'Bb',
    suffix: 'maj7#5',
    positions: [
        {
            frets: 'x10232',
            fingers: '010243'
        },
        {
            frets: 'xx4335',
            fingers: '002114',
            barres: 3,
            capo: true
        },
        {
            frets: '6x777x',
            fingers: '102340'
        },
        {
            frets: 'adcbaa',
            fingers: '143211',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: '13b5b9',
    positions: [
        {
            frets: '3636',
            fingers: '1314',
            barres: 3,
            capo: true
        },
        {
            frets: '8769',
            fingers: '3214'
        },
        {
            frets: '0ab9',
            fingers: '0231'
        },
        {
            frets: 'baba',
            fingers: '3142'
        }
    ]
};

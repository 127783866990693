import React, { useState, useCallback, useEffect } from 'react';
import { useAuth, UserType } from "./Auth";
import { createContext, useContext, useMemo } from "react";
import firebase from 'firebase';

interface UserPreferences {
  premiumEnabled?: boolean,
  songLimit?: number
}

interface UserPreferencesContextProps {
  userPrefs: UserPreferences
  setUserPrefs: (prefs: UserPreferences) => Promise<void>
}

const UserPreferencesContext = createContext<UserPreferencesContextProps>({
  userPrefs: {},
  setUserPrefs: async (prefs: UserPreferences) => { }
});

interface UseUserPrefs {
  prefs: UserPreferences,
  setPrefs: (prefs: UserPreferences) => Promise<void>
}

function setUserPrefs(user: UserType, prefs: UserPreferences) {
  return firebase.firestore().collection('userPreferences').doc(user.uid).set(prefs);
}


export const UserPreferencesProvider: React.FC = (props) => {
  const auth = useAuth();
  const [prefs, setPrefs] = useState<UserPreferences>({});
  const [newPrefs, setNewPrefs] = useState<UserPreferences>({});

  useEffect(() => {
    const collection = firebase.firestore().collection('userPreferences');
    if (auth.user) {
      return collection
        .doc(auth.user.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setPrefs(snapshot.data() as UserPreferences);
          } else {
            setPrefs({});
          }
        }, (err) => {
          setPrefs({});
        });
    }
  }, [auth.user]);

  const _setUserPrefs = useCallback(() => {
    async function _internal() {
      if (auth.user) {
        return await setUserPrefs(auth.user, newPrefs);
      }
    }
    return _internal();
  }, [auth.user, newPrefs]);

  return (
    <UserPreferencesContext.Provider value={{
      userPrefs: prefs,
      setUserPrefs: (prefs) => {
        setNewPrefs(prefs);
        return _setUserPrefs();
      },
    }}>
      {props.children}
    </UserPreferencesContext.Provider>
  )
}

export function useUserPreferences(): UseUserPrefs {
  const auth = useAuth();
  const userPrefs = useContext(UserPreferencesContext);
  const prefs = useMemo<UseUserPrefs>(() => {
    const user = auth.user;
    if (user) {
      return {
        prefs: userPrefs.userPrefs,
        setPrefs: async (prefs) => {
          userPrefs.setUserPrefs(prefs);
        },
      };
    }
    return {
      prefs: {},
      setPrefs: async () => { }
    };
  }, [auth.user, userPrefs]);
  return prefs;
}
export default {
    key: 'Db',
    suffix: 'add9',
    positions: [
        {
            frets: '1314',
            fingers: '1314',
            barres: 1
        },
        {
            frets: '6546',
            fingers: '3214'
        },
        {
            frets: '8898',
            fingers: '1121',
            barres: 8
        },
        {
            frets: 'adbb',
            fingers: '1422',
            barres: 11
        }
    ]
};

export default {
    key: 'A',
    suffix: 'm7b5',
    positions: [
        {
            frets: '2333',
            fingers: '1234'
        },
        {
            frets: '5756',
            fingers: '1312',
            barres: 5,
            capo: true
        },
        {
            frets: '898a',
            fingers: '1213',
            barres: 8,
            capo: true
        },
        {
            frets: 'ccbc',
            fingers: '2314'
        }
    ]
};

export default {
    key: 'E',
    suffix: '9b5',
    positions: [
        {
            frets: '012132',
            fingers: '012143',
            barres: 1
        },
        {
            frets: '054334',
            fingers: '042113',
            barres: 3
        },
        {
            frets: 'x76776',
            fingers: '021341',
            barres: 6,
            capo: true
        },
        {
            frets: '098790',
            fingers: '032140'
        }
    ]
};

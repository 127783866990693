export default {
    key: 'F',
    suffix: 'alt',
    positions: [
        {
            frets: '12320x',
            fingers: '124300'
        },
        {
            frets: 'xx3405',
            fingers: '001203'
        },
        {
            frets: 'x89aax',
            fingers: '012340'
        },
        {
            frets: 'dcxecd',
            fingers: '210413',
            barres: 12,
            capo: true
        }
    ]
};

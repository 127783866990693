export default {
    key: 'D',
    suffix: '9#11',
    positions: [
        {
            frets: '1423',
            fingers: '1423'
        },
        {
            frets: '5647',
            fingers: '2314'
        },
        {
            frets: '9889',
            fingers: '2113',
            barres: 8,
            capo: true
        },
        {
            frets: 'bccb',
            fingers: '1221',
            barres: [11, 12],
            capo: true
        }
    ]
};

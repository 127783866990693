export default {
    key: 'E',
    suffix: 'minor',
    positions: [
        {
            frets: '0432',
            fingers: '0321'
        },
        {
            frets: '4432',
            fingers: '3421'
        },
        {
            frets: '4777',
            fingers: '1234'
        },
        {
            frets: '9777',
            fingers: '3111',
            barres: 7,
            capo: true
        }
    ]
};

import React, { useContext } from 'react'
import { Row, Col, Menu, Space, Typography } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Link, navigate } from '@reach/router';
import { FeaturesContext, isEnabled } from '../../hooks/Features';
import { entries } from '@chordpad/common-ts/dist/records';
import { debug } from './Debug';
import logo from '../../res/images/chordpad-logo.svg';

const TopHeader: React.FC = () => {
  const features = useContext(FeaturesContext);
  console.log('features', features);
  return (
    <Row align='middle'>
      <Link to='/'>
        <Space align='center'>
          <img src={logo} alt='' style={{ width: 50, height: 50 }} />
          <Typography.Title level={4} style={{ color: 'white' }}>Chordpad</Typography.Title>
        </Space>
      </Link>
      <Menu theme='dark' mode='horizontal' selectable={false}>
        <Menu.Item onClick={() => navigate('/account')}>
          Account
        </Menu.Item>
      </Menu>
      <Col flex={1}>
        {
          debug(
            <Menu theme='dark' mode='horizontal' selectable={false}>
              <SubMenu title='Features'>
                {entries(features).map(({ key, value }) => (
                  <Menu.Item key={key}>{`${key}: ${isEnabled(value)}`}</Menu.Item>
                ))}
              </SubMenu>
            </Menu>
          )
        }
      </Col>
    </Row>
  );
};

export default TopHeader;
export default {
    key: 'Eb',
    suffix: '7b5',
    positions: [
        {
            frets: 'xx1223',
            fingers: '001234'
        },
        {
            frets: 'xx5645',
            fingers: '002413'
        },
        {
            frets: 'x67686',
            fingers: '012130',
            barres: 6,
            capo: true
        },
        {
            frets: 'bxbcax',
            fingers: '203410'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '7#9',
    positions: [
        {
            frets: '0121',
            fingers: '0132'
        },
        {
            frets: '3634',
            fingers: '1412',
            barres: 3,
            capo: true
        },
        {
            frets: '6769',
            fingers: '1214',
            barres: 6,
            capo: true
        },
        {
            frets: 'ba9a',
            fingers: '4213'
        }
    ]
};

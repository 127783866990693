export default {
    key: 'B',
    suffix: 'major',
    positions: [
        {
            frets: '4322',
            fingers: '3211',
            barres: 2,
            capo: true
        },
        {
            frets: '4676',
            fingers: '1243'
        },
        {
            frets: '8676',
            fingers: '3121',
            barres: 6,
            capo: true
        },
        {
            frets: 'bbbe',
            fingers: '1114',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'alt',
    positions: [
        {
            frets: 'xx4312',
            fingers: '004312'
        },
        {
            frets: '2343x2',
            fingers: '124301',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4576',
            fingers: '001243'
        },
        {
            frets: 'x9abb9',
            fingers: '012340',
            barres: 9,
            capo: true
        }
    ]
};

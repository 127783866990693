export default {
    key: 'Bb',
    suffix: 'minor',
    positions: [
        {
            frets: 'x13321',
            fingers: '013421',
            barres: 1,
            capo: true
        },
        {
            frets: '688666',
            fingers: '134111',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx8666',
            fingers: '003111',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx8ab9',
            fingers: '001342'
        }
    ]
};

export default {
    key: 'C#',
    suffix: 'major',
    positions: [
        {
            frets: 'x43121',
            fingers: '043121',
            barres: 1
        },
        {
            frets: '446664',
            fingers: '112341',
            barres: 4,
            capo: true
        },
        {
            frets: '986669',
            fingers: '321114',
            barres: 6
        },
        {
            frets: '9bba99',
            fingers: '134211',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'madd9',
    positions: [
        {
            frets: 'xx3113',
            fingers: '003114',
            barres: 1,
            capo: true
        },
        {
            frets: 'x8658x',
            fingers: '032140'
        },
        {
            frets: 'x86068',
            fingers: '031024'
        },
        {
            frets: 'x8a098',
            fingers: '014032'
        }
    ]
};

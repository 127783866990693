export default {
    key: 'Gb',
    suffix: 'madd9',
    positions: [
        {
            frets: '1120',
            fingers: '1230'
        },
        {
            frets: 'b99b',
            fingers: '3114',
            barres: 9
        },
        {
            frets: 'edeb',
            fingers: '3241'
        }
    ]
};

export default {
    key: 'C',
    suffix: 'dim7',
    positions: [
        {
            frets: 'xx1212',
            fingers: '001324'
        },
        {
            frets: 'x34242',
            fingers: '023141',
            barres: 2,
            capo: true
        },
        {
            frets: '8x787x',
            fingers: '201310',
            barres: 7
        },
        {
            frets: 'xxabab',
            fingers: '001314',
            barres: 10
        }
    ]
};

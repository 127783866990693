export default {
    key: 'Ab',
    suffix: 'm9',
    positions: [
        {
            frets: '4321',
            fingers: '4321'
        },
        {
            frets: '3322',
            fingers: '2211',
            barres: [2, 3],
            capo: true
        },
        {
            frets: '4666',
            fingers: '1234'
        },
        {
            frets: '8a79',
            fingers: '2413'
        }
    ]
};

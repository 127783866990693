export default {
    key: 'F#',
    suffix: 'minor',
    positions: [
        {
            frets: '244222',
            fingers: '134111',
            barres: 2,
            capo: true
        },
        {
            frets: 'x44675',
            fingers: '011342',
            barres: 4,
            capo: true
        },
        {
            frets: 'xx7675',
            fingers: '003241'
        },
        {
            frets: '99bba9',
            fingers: '113421',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: '7#9',
    positions: [
        {
            frets: 'x3234x',
            fingers: '021340'
        },
        {
            frets: 'x35340',
            fingers: '013120',
            barres: 3
        },
        {
            frets: '8a898b',
            fingers: '131214',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxa9bb',
            fingers: '002134'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'maj7',
    positions: [
        {
            frets: '1333',
            fingers: '1234'
        },
        {
            frets: '5746',
            fingers: '2413'
        },
        {
            frets: '888a',
            fingers: '1113',
            barres: 8,
            capo: true
        },
        {
            frets: 'dcba',
            fingers: '4321'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'add9',
    positions: [
        {
            frets: '2232',
            fingers: '1121',
            barres: 2,
            capo: true
        },
        {
            frets: '4755',
            fingers: '1422',
            barres: 5
        },
        {
            frets: '797a',
            fingers: '1314',
            barres: 7,
            capo: true
        },
        {
            frets: 'cbac',
            fingers: '3214'
        }
    ]
};

export default {
    key: 'C#',
    suffix: 'maj7b5',
    positions: [
        {
            frets: 'x43563',
            fingers: '021341',
            barres: 3,
            capo: true
        },
        {
            frets: 'x4556x',
            fingers: '012340'
        },
        {
            frets: '98aa88',
            fingers: '213411',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxbcdd',
            fingers: '001234'
        }
    ]
};

export default {
    key: 'A',
    suffix: 'aug9',
    positions: [
        {
            frets: 'x03423',
            fingers: '002413'
        },
        {
            frets: '543005',
            fingers: '321004'
        },
        {
            frets: 'x05667',
            fingers: '001234'
        },
        {
            frets: 'xcbc0d',
            fingers: '021304'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: '9b5',
    positions: [
        {
            frets: '4303x2',
            fingers: '420301'
        },
        {
            frets: '434334',
            fingers: '213114',
            barres: 3,
            capo: true
        },
        {
            frets: '4545x6',
            fingers: '121304',
            barres: 4,
            capo: true
        },
        {
            frets: 'xbabba',
            fingers: '021341',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'add9',
    positions: [
        {
            frets: 'x10311',
            fingers: '010423'
        },
        {
            frets: 'xx8768',
            fingers: '003214'
        },
        {
            frets: 'xx87x8',
            fingers: '002103'
        },
        {
            frets: 'xdcada',
            fingers: '032141',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Gb',
    suffix: '13',
    positions: [
        {
            frets: '1301',
            fingers: '1302'
        },
        {
            frets: '3446',
            fingers: '1234'
        },
        {
            frets: '8867',
            fingers: '3412'
        },
        {
            frets: '9866',
            fingers: '4311',
            barres: 6,
            capo: true
        }
    ]
};

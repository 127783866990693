export default {
    key: 'D',
    suffix: 'minor',
    positions: [
        {
            frets: '2210',
            fingers: '2310'
        },
        {
            frets: '2555',
            fingers: '1234'
        },
        {
            frets: '7555',
            fingers: '3111',
            barres: 5,
            capo: true
        },
        {
            frets: '7558',
            fingers: '3114',
            barres: 5,
            capo: true
        }
    ]
};

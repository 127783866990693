export default {
    key: 'Db',
    suffix: '69',
    positions: [
        {
            frets: '1311',
            fingers: '1311',
            barres: 1,
            capo: true
        },
        {
            frets: '3546',
            fingers: '1324'
        },
        {
            frets: '8868',
            fingers: '2314'
        },
        {
            frets: 'aabb',
            fingers: '1122',
            barres: [10, 11],
            capo: true
        }
    ]
};

export default {
    key: 'Db',
    suffix: 'madd9',
    positions: [
        {
            frets: '6446',
            fingers: '3114',
            barres: 4,
            capo: true
        },
        {
            frets: '9896',
            fingers: '3241'
        },
        {
            frets: '8897',
            fingers: '2231',
            barres: 8
        }
    ]
};

export default {
    key: 'A',
    suffix: '9b5',
    positions: [
        {
            frets: 'x01423',
            fingers: '001423'
        },
        {
            frets: '545445',
            fingers: '213114',
            barres: 4,
            capo: true
        },
        {
            frets: 'x09889',
            fingers: '002113',
            barres: 8
        },
        {
            frets: 'xcbccb',
            fingers: '021341',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'mmaj11',
    positions: [
        {
            frets: 'x11221',
            fingers: '011231',
            barres: 1,
            capo: true
        },
        {
            frets: '667668',
            fingers: '112114',
            barres: 6,
            capo: true
        },
        {
            frets: 'x888a9',
            fingers: '011132',
            barres: 8,
            capo: true
        },
        {
            frets: 'xdbedb',
            fingers: '021431',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'minor',
    positions: [
        {
            frets: '2120',
            fingers: '2130'
        },
        {
            frets: '2124',
            fingers: '2134'
        },
        {
            frets: '6654',
            fingers: '3421'
        },
        {
            frets: 'b999',
            fingers: '3111',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm7b5',
    positions: [
        {
            frets: '012333',
            fingers: '012333',
            barres: 3
        },
        {
            frets: 'x7878x',
            fingers: '013240'
        },
        {
            frets: 'xx898a',
            fingers: '001214',
            barres: 8,
            capo: true
        },
        {
            frets: 'cxccbx',
            fingers: '203410'
        }
    ]
};

export default {
    key: 'C#',
    suffix: 'm69',
    positions: [
        {
            frets: 'x41320',
            fingers: '041320'
        },
        {
            frets: 'x4234x',
            fingers: '031240'
        },
        {
            frets: '9788x0',
            fingers: '412300'
        },
        {
            frets: 'xbb9bb',
            fingers: '022134',
            barres: 11
        }
    ]
};

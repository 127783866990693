export default {
    key: 'Eb',
    suffix: '7b9',
    positions: [
        {
            frets: 'xx1020',
            fingers: '002040'
        },
        {
            frets: 'x65656',
            fingers: '021314',
            barres: 5,
            capo: true
        },
        {
            frets: 'x68680',
            fingers: '013140',
            barres: 6
        },
        {
            frets: 'bab9xx',
            fingers: '324100'
        }
    ]
};

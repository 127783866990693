export default {
    key: 'Ab',
    suffix: 'm9b5',
    positions: [
        {
            frets: '4221',
            fingers: '4221',
            barres: 2
        },
        {
            frets: '3222',
            fingers: '2111',
            barres: 2,
            capo: true
        },
        {
            frets: '4665',
            fingers: '1342'
        },
        {
            frets: '7a79',
            fingers: '1413',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'aug',
    positions: [
        {
            frets: '0332',
            fingers: '0321'
        },
        {
            frets: '4332',
            fingers: '3221',
            barres: 3
        },
        {
            frets: '4336',
            fingers: '2114',
            barres: 3,
            capo: true
        },
        {
            frets: '4776',
            fingers: '1342'
        }
    ]
};

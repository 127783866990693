export default {
    key: 'A',
    suffix: 'maj7',
    positions: [
        {
            frets: 'x02120',
            fingers: '002130'
        },
        {
            frets: 'x02224',
            fingers: '001114',
            barres: 2,
            capo: true
        },
        {
            frets: '576655',
            fingers: '142311',
            barres: 5,
            capo: true
        },
        {
            frets: 'x07999',
            fingers: '001333',
            barres: 9
        }
    ]
};

export default {
    key: 'F',
    suffix: 'sus2',
    positions: [
        {
            frets: '0013',
            fingers: '0013'
        },
        {
            frets: '5533',
            fingers: '3411',
            barres: 3,
            capo: true
        },
        {
            frets: '5788',
            fingers: '1233',
            barres: 8
        },
        {
            frets: 'acda',
            fingers: '1341',
            barres: 10,
            capo: true
        }
    ]
};

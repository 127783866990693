export default {
    key: 'Db',
    suffix: 'dim',
    positions: [
        {
            frets: '6434',
            fingers: '4213'
        },
        {
            frets: '6797',
            fingers: '1243'
        },
        {
            frets: '9797',
            fingers: '3141',
            barres: 7,
            capo: true
        },
        {
            frets: 'cdca',
            fingers: '2431'
        }
    ]
};

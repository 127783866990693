export default {
    key: 'Eb',
    suffix: '9#11',
    positions: [
        {
            frets: 'xx1223',
            fingers: '001234'
        },
        {
            frets: 'x65665',
            fingers: '021341',
            barres: 5,
            capo: true
        },
        {
            frets: 'x6768x',
            fingers: '012130',
            barres: 6,
            capo: true
        },
        {
            frets: 'babaab',
            fingers: '213114',
            barres: 10,
            capo: true
        }
    ]
};

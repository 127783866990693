export default {
    key: 'F',
    suffix: '9#11',
    positions: [
        {
            frets: '2332',
            fingers: '1221',
            barres: [2, 3],
            capo: true
        },
        {
            frets: '4756',
            fingers: '1423'
        },
        {
            frets: '897a',
            fingers: '2314'
        },
        {
            frets: 'cbbc',
            fingers: '2113',
            barres: 11,
            capo: true
        }
    ]
};

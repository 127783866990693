export default {
    key: 'E',
    suffix: 'aug9',
    positions: [
        {
            frets: '030132',
            fingers: '030142'
        },
        {
            frets: '054554',
            fingers: '021341',
            barres: 4
        },
        {
            frets: 'x76778',
            fingers: '021334',
            barres: 7
        },
        {
            frets: '09a99a',
            fingers: '012113',
            barres: 9
        }
    ]
};

import React, { useState, useEffect, useCallback } from 'react';
import { Layout, ConfigProvider, Row, Switch } from 'antd';
import { ConfigProviderProps } from 'antd/lib/config-provider';
import { DebugProvider, useDebugMode } from '../../hooks/DebugMode';
import { AuthContext, User } from '../../hooks/Auth';
import firebase from 'firebase';
import './App.less';
import TopHeader from './TopMenu';
import SongListPage from '../pages/SongListPage';
import { Router, Redirect } from '@reach/router';
import SongEditorPage from '../pages/SongEditorPage';
import AppContent from './AppContent';
import { UserPreferencesProvider } from '../../hooks/UserPreferences';
import { FeaturesProvider } from '../../hooks/Features';
import { FingerprintUtil } from '../../util/FingerprintUtil';
import Copyright from './Copyright';
import AccountPage from '../pages/AccountPage';

function AppTheme() {
  const debugMode = useDebugMode();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Header>
        <TopHeader />
      </Layout.Header>
      <Layout.Content>
        <AppContent>
          <Router>
            <Redirect from='/' to='s' noThrow />
            <SongListPage path='s' />
            <SongEditorPage path='s/:songId' />
            <AccountPage path='/account' />
          </Router>
        </AppContent>
      </Layout.Content>
      <Layout.Footer>
        <Row justify='center'>
          <Copyright ownerName='Quentin Topper' fullStartingYear={2019} />
        </Row>
        {
          process.env.NODE_ENV === 'development' ?
            <Row justify='end'>
              <Switch
                checkedChildren={'Debug on'}
                unCheckedChildren={'Debug off'}
                checked={debugMode.enabled}
                onClick={debugMode.toggle} />
            </Row>
            : undefined
        }
      </Layout.Footer>
    </Layout>
  );
}

function App() {
  const config: ConfigProviderProps = {
    space: { size: 'small' },
  };
  const [user, setUser] = useState<User>(firebase.auth().currentUser);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      FingerprintUtil.initFingerprint(user?.uid);
      setUser(user);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const signOut = useCallback(() => {
    const internal = async () => {
      await firebase.auth().signOut();
    };
    return internal();
  }, []);

  return (
    <AuthContext.Provider value={{
      user: user,
      signOut: signOut,
    }}>
      <UserPreferencesProvider>
        <ConfigProvider {...config} >
          <DebugProvider>
            <FeaturesProvider>
              <AppTheme />
            </FeaturesProvider>
          </DebugProvider>
        </ConfigProvider>
      </UserPreferencesProvider>
    </AuthContext.Provider>
  );
}

export default App;

export default {
    key: 'A',
    suffix: '7sus4',
    positions: [
        {
            frets: 'x02030',
            fingers: '002030'
        },
        {
            frets: '575755',
            fingers: '131411',
            barres: 5,
            capo: true
        },
        {
            frets: 'x0798a',
            fingers: '001324'
        },
        {
            frets: 'xcccaa',
            fingers: '023411',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'm/E',
    positions: [
        {
            frets: '002210',
            fingers: '002310'
        },
        {
            frets: '032210',
            fingers: '042310'
        },
        {
            frets: 'x77555',
            fingers: '034111',
            barres: 5
        }
    ]
};

export default {
    key: 'B',
    suffix: '7sus4',
    positions: [
        {
            frets: 'x22200',
            fingers: '012300'
        },
        {
            frets: '224252',
            fingers: '113141',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4455',
            fingers: '001122',
            barres: [4, 5],
            capo: true
        },
        {
            frets: '797977',
            fingers: '131411',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: '7b5',
    positions: [
        {
            frets: '1223',
            fingers: '1234'
        },
        {
            frets: '5645',
            fingers: '2413'
        },
        {
            frets: '7889',
            fingers: '1234'
        },
        {
            frets: 'bcab',
            fingers: '2413'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'major',
    positions: [
        {
            frets: '133211',
            fingers: '134211',
            barres: 1,
            capo: true
        },
        {
            frets: 'x33565',
            fingers: '011243',
            barres: 3,
            capo: true
        },
        {
            frets: '587565',
            fingers: '143121',
            barres: 5,
            capo: true
        },
        {
            frets: '88aaa8',
            fingers: '112341',
            barres: 8,
            capo: true
        }
    ]
};

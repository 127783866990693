import React from 'react'
import { StyledFirebaseAuth } from 'react-firebaseui';
import firebase from 'firebase';

interface SignInButtonProps {
  onSignInFailure?: (error: firebaseui.auth.AuthUIError) => Promise<void>
}

const SignInButton: React.FC<SignInButtonProps> = (props) => {
  console.log('SignInButton')

  const uiConfig: firebaseui.auth.Config = {
      signInFlow: 'popup',
      callbacks: {
        signInFailure: props.onSignInFailure,
        signInSuccessWithAuthResult: (authResult, redirectUrl) => false,
      }, 
      
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ]
  };

  return (
    <StyledFirebaseAuth 
      firebaseAuth={firebase.auth()}
      uiConfig={uiConfig}/>
  );
};

export default SignInButton;
export default {
    key: 'Bb',
    suffix: 'maj11',
    positions: [
        {
            frets: '5355',
            fingers: '2134'
        },
        {
            frets: '7986',
            fingers: '2431'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'maj7b5',
    positions: [
        {
            frets: '1224',
            fingers: '1234'
        },
        {
            frets: '7644',
            fingers: '4311',
            barres: 4,
            capo: true
        },
        {
            frets: '6645',
            fingers: '3412'
        },
        {
            frets: '7899',
            fingers: '1234'
        }
    ]
};

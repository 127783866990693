export default {
    key: 'C',
    suffix: 'sus4',
    positions: [
        {
            frets: 'x33011',
            fingers: '034011',
            barres: 1
        },
        {
            frets: '335563',
            fingers: '112341',
            barres: 3,
            capo: true
        },
        {
            frets: '88x068',
            fingers: '230014'
        },
        {
            frets: '8aaa88',
            fingers: '123411',
            barres: 8,
            capo: true
        }
    ]
};

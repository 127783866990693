export default {
    key: 'Db',
    suffix: '11',
    positions: [
        {
            frets: '8678',
            fingers: '3124'
        }
    ]
};

export default {
    key: 'Bb',
    suffix: '13b9',
    positions: [
        {
            frets: '1232',
            fingers: '1243'
        },
        {
            frets: '4745',
            fingers: '1412',
            barres: 4,
            capo: true
        },
        {
            frets: '787a',
            fingers: '1214',
            barres: 7,
            capo: true
        },
        {
            frets: 'dbaa',
            fingers: '4211',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: 'mmaj7',
    positions: [
        {
            frets: '310032',
            fingers: '310042'
        },
        {
            frets: '354333',
            fingers: '132111',
            barres: 3,
            capo: true
        },
        {
            frets: 'x55776',
            fingers: '011342',
            barres: 5,
            capo: true
        },
        {
            frets: 'xacbba',
            fingers: '014231',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'C#',
    suffix: '7b9',
    positions: [
        {
            frets: 'x43434',
            fingers: '021314',
            barres: 3,
            capo: true
        },
        {
            frets: 'x40467',
            fingers: '010234'
        },
        {
            frets: '98970x',
            fingers: '324100'
        },
        {
            frets: 'xx9a9a',
            fingers: '001324'
        }
    ]
};

export default {
    key: 'F',
    suffix: '/A',
    positions: [
        {
            frets: 'x03211',
            fingers: '003211',
            barres: 1
        },
        {
            frets: '5332xx',
            fingers: '423100'
        },
        {
            frets: '533560',
            fingers: '211340',
            barres: 3
        }
    ]
};

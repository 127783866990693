export default {
    key: 'A',
    suffix: '7b5',
    positions: [
        {
            frets: 'x01223',
            fingers: '001234'
        },
        {
            frets: 'x05645',
            fingers: '002413'
        },
        {
            frets: 'xx7889',
            fingers: '001234'
        },
        {
            frets: 'xabcab',
            fingers: '012413',
            barres: 10,
            capo: true
        }
    ]
};

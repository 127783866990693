export default {
    key: 'Db',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: '0103',
            fingers: '0103'
        },
        {
            frets: '5434',
            fingers: '4213'
        },
        {
            frets: '6433',
            fingers: '4211',
            barres: 3,
            capo: true
        },
        {
            frets: '6787',
            fingers: '1243'
        }
    ]
};

export default {
    key: 'A',
    suffix: 'sus2',
    positions: [
        {
            frets: '2452',
            fingers: '1341',
            barres: 2,
            capo: true
        },
        {
            frets: '4457',
            fingers: '1124',
            barres: 4,
            capo: true
        },
        {
            frets: '9977',
            fingers: '3411',
            barres: 7,
            capo: true
        },
        {
            frets: '9bcc',
            fingers: '1233',
            barres: 12
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '2x333x',
            fingers: '102340'
        },
        {
            frets: '698766',
            fingers: '143211',
            barres: 6,
            capo: true
        },
        {
            frets: 'x9cabx',
            fingers: '014230'
        },
        {
            frets: 'xxcbbd',
            fingers: '002114',
            barres: 11,
            capo: true
        }
    ]
};

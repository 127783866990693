export default {
    key: 'D',
    suffix: 'maj11',
    positions: [
        {
            frets: '0424',
            fingers: '0213'
        },
        {
            frets: '9799',
            fingers: '2134'
        },
        {
            frets: 'bdca',
            fingers: '2431'
        }
    ]
};

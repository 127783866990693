export default {
    key: 'F',
    suffix: 'sus4',
    positions: [
        {
            frets: '3011',
            fingers: '3011',
            barres: 1
        },
        {
            frets: '3013',
            fingers: '2013'
        },
        {
            frets: '3563',
            fingers: '1231',
            barres: 3,
            capo: true
        },
        {
            frets: '5563',
            fingers: '2341'
        }
    ]
};

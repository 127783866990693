export default {
    key: 'B',
    suffix: '7',
    positions: [
        {
            frets: 'x21202',
            fingers: '021304'
        },
        {
            frets: '224242',
            fingers: '113141',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4445',
            fingers: '001112',
            barres: 4,
            capo: true
        },
        {
            frets: '797877',
            fingers: '131211',
            barres: 7,
            capo: true
        }
    ]
};

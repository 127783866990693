export default {
    key: 'A',
    suffix: '69',
    positions: [
        {
            frets: 'x04422',
            fingers: '003411',
            barres: 2
        },
        {
            frets: '544455',
            fingers: '211134',
            barres: 4,
            capo: true
        },
        {
            frets: 'x77677',
            fingers: '022134',
            barres: 7,
            capo: true
        },
        {
            frets: 'xcbbcc',
            fingers: '021134',
            barres: 11,
            capo: true
        }
    ]
};

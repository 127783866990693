export default {
    key: 'F',
    suffix: 'm6',
    positions: [
        {
            frets: '1x0111',
            fingers: '100234'
        },
        {
            frets: 'xx3534',
            fingers: '001312',
            barres: 3,
            capo: true
        },
        {
            frets: 'x86768',
            fingers: '031214',
            barres: 6,
            capo: true
        },
        {
            frets: 'xxaa9a',
            fingers: '002314'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'aug9',
    positions: [
        {
            frets: '301001',
            fingers: '302001'
        },
        {
            frets: '32324x',
            fingers: '213140'
        },
        {
            frets: 'xa9aab',
            fingers: '021334',
            barres: 10
        },
        {
            frets: 'xcd0cd',
            fingers: '013024'
        }
    ]
};

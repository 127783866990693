export default {
    key: 'A',
    suffix: 'aug',
    positions: [
        {
            frets: 'x03221',
            fingers: '004231'
        },
        {
            frets: '54322x',
            fingers: '432110',
            barres: 2,
            capo: true
        },
        {
            frets: '5x7665',
            fingers: '104231',
            barres: 5,
            capo: true
        },
        {
            frets: 'xcbaax',
            fingers: '032110',
            barres: 10,
            capo: true
        }
    ]
};

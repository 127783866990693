export default {
    key: 'F',
    suffix: '6',
    positions: [
        {
            frets: '2213',
            fingers: '2314'
        },
        {
            frets: '5555',
            fingers: '1111',
            barres: 5,
            capo: true
        },
        {
            frets: '7988',
            fingers: '1423'
        },
        {
            frets: 'acac',
            fingers: '1324'
        }
    ]
};

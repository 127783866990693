export default {
    key: 'E',
    suffix: 'm11',
    positions: [
        {
            frets: '0220',
            fingers: '0230'
        },
        {
            frets: 'b9aa',
            fingers: '3122',
            barres: 10
        },
        {
            frets: 'c9a9',
            fingers: '4121',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: 'x01114',
            fingers: '001114',
            barres: 1
        },
        {
            frets: '5665x5',
            fingers: '123101',
            barres: 5,
            capo: true
        },
        {
            frets: 'xx7898',
            fingers: '001243'
        },
        {
            frets: 'bcdddx',
            fingers: '123330',
            barres: 13
        }
    ]
};

export default {
    key: 'B',
    suffix: 'm11',
    positions: [
        {
            frets: '6455',
            fingers: '3122',
            barres: 5
        },
        {
            frets: '7454',
            fingers: '4121',
            barres: 4,
            capo: true
        },
        {
            frets: '7997',
            fingers: '1341',
            barres: 7
        }
    ]
};

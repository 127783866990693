export default {
    key: 'Bb',
    suffix: '6',
    positions: [
        {
            frets: 'x13333',
            fingers: '013333',
            barres: 3
        },
        {
            frets: '650066',
            fingers: '210034'
        },
        {
            frets: '68x786',
            fingers: '130240',
            barres: 6,
            capo: true
        },
        {
            frets: 'xdbbax',
            fingers: '042310'
        }
    ]
};

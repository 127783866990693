export default {
    key: 'Eb',
    suffix: 'mmaj7',
    positions: [
        {
            frets: 'xx1332',
            fingers: '001342'
        },
        {
            frets: 'x6433x',
            fingers: '043120'
        },
        {
            frets: 'x68776',
            fingers: '014231',
            barres: 6,
            capo: true
        },
        {
            frets: 'bdcbbb',
            fingers: '132111',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'm/F#',
    positions: [
        {
            frets: '202210',
            fingers: '203410'
        },
        {
            frets: 'xx4555',
            fingers: '001234'
        },
        {
            frets: '232555',
            fingers: '121444',
            barres: 2
        }
    ]
};

export default {
    key: 'E',
    suffix: '7b9#5',
    positions: [
        {
            frets: '1213',
            fingers: '1213',
            barres: 1,
            capo: true
        },
        {
            frets: '5545',
            fingers: '2314'
        },
        {
            frets: '7888',
            fingers: '1234'
        },
        {
            frets: 'acab',
            fingers: '1312',
            barres: 10,
            capo: true
        }
    ]
};

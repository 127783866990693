export default {
    key: 'G',
    suffix: 'minor',
    positions: [
        {
            frets: '0231',
            fingers: '0231'
        },
        {
            frets: '3231',
            fingers: '3241'
        },
        {
            frets: '3235',
            fingers: '2134'
        },
        {
            frets: '7765',
            fingers: '3421'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'sus4',
    positions: [
        {
            frets: 'xx1124',
            fingers: '001124',
            barres: 1,
            capo: true
        },
        {
            frets: '466644',
            fingers: '123411',
            barres: 4,
            capo: true
        },
        {
            frets: 'x66899',
            fingers: '011234',
            barres: 6,
            capo: true
        },
        {
            frets: 'bbddeb',
            fingers: '112341',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: '9',
    positions: [
        {
            frets: '2324',
            fingers: '1213',
            barres: 2,
            capo: true
        },
        {
            frets: '6656',
            fingers: '2314'
        },
        {
            frets: '8999',
            fingers: '1234'
        },
        {
            frets: 'bdbc',
            fingers: '1312',
            barres: 11,
            capo: true
        }
    ]
};

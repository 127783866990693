export default {
    key: 'G',
    suffix: 'm7',
    positions: [
        {
            frets: '0211',
            fingers: '0211',
            barres: 1
        },
        {
            frets: '3535',
            fingers: '1324'
        },
        {
            frets: '7768',
            fingers: '2213',
            barres: 7
        },
        {
            frets: 'aaaa',
            fingers: '1111',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'm11',
    positions: [
        {
            frets: '646544',
            fingers: '314211',
            barres: 4,
            capo: true
        },
        {
            frets: '666668',
            fingers: '111114',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx8899',
            fingers: '001123',
            barres: 8,
            capo: true
        },
        {
            frets: 'xdbddb',
            fingers: '021341',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'aug',
    positions: [
        {
            frets: '3221',
            fingers: '3221',
            barres: 2
        },
        {
            frets: '3225',
            fingers: '2114',
            barres: 2,
            capo: true
        },
        {
            frets: '3665',
            fingers: '1342'
        },
        {
            frets: '7669',
            fingers: '2114',
            barres: 6,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm/G',
    positions: [
        {
            frets: '322000',
            fingers: '312000'
        },
        {
            frets: '322003',
            fingers: '312004'
        },
        {
            frets: '322450',
            fingers: '211340',
            barres: 2
        }
    ]
};

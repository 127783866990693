export default {
    key: 'Bb',
    suffix: 'dim',
    positions: [
        {
            frets: 'x1232x',
            fingers: '012430'
        },
        {
            frets: '64x65x',
            fingers: '310420'
        },
        {
            frets: 'xx89x9',
            fingers: '001203'
        },
        {
            frets: 'xdbxbc',
            fingers: '041023'
        }
    ]
};

export default {
    key: 'C',
    suffix: 'minor',
    positions: [
        {
            frets: 'x31013',
            fingers: '032014'
        },
        {
            frets: '335543',
            fingers: '113421',
            barres: 3,
            capo: true
        },
        {
            frets: '8655xx',
            fingers: '421100',
            barres: 5
        },
        {
            frets: '8aa888',
            fingers: '134111',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'm9b5',
    positions: [
        {
            frets: '1103',
            fingers: '1204'
        },
        {
            frets: '5444',
            fingers: '2111',
            barres: 4,
            capo: true
        },
        {
            frets: '6443',
            fingers: '4221',
            barres: 4
        },
        {
            frets: '6887',
            fingers: '1342'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'maj9',
    positions: [
        {
            frets: 'x63333',
            fingers: '041111',
            barres: 3
        },
        {
            frets: 'x6576x',
            fingers: '021430'
        },
        {
            frets: 'x8888a',
            fingers: '011114',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxccbd',
            fingers: '002314'
        }
    ]
};

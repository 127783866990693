export default {
    key: 'E',
    suffix: 'aug',
    positions: [
        {
            frets: '1003',
            fingers: '1003'
        },
        {
            frets: '1443',
            fingers: '1342'
        },
        {
            frets: '5443',
            fingers: '3221',
            barres: 4
        },
        {
            frets: '5447',
            fingers: '2114',
            barres: 4,
            capo: true
        }
    ]
};

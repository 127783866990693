export default {
    key: 'Ab',
    suffix: '11',
    positions: [
        {
            frets: '3123',
            fingers: '3124'
        },
        {
            frets: '6663',
            fingers: '2341'
        },
        {
            frets: '5664',
            fingers: '2341'
        }
    ]
};

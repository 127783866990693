export default {
    key: 'Ab',
    suffix: 'madd9',
    positions: [
        {
            frets: '4341',
            fingers: '3241'
        },
        {
            frets: '3342',
            fingers: '2231',
            barres: 3
        },
        {
            frets: 'dbbd',
            fingers: '3114',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: 'major',
    positions: [
        {
            frets: '2010',
            fingers: '2010'
        },
        {
            frets: '2013',
            fingers: '2013'
        },
        {
            frets: '5558',
            fingers: '1114',
            barres: 5,
            capo: true
        },
        {
            frets: 'a988',
            fingers: '3211',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: '7b9',
    positions: [
        {
            frets: 'x10101',
            fingers: '010203'
        },
        {
            frets: '6564xx',
            fingers: '324100'
        },
        {
            frets: '6x6767',
            fingers: '101213',
            barres: 6,
            capo: true
        },
        {
            frets: 'xdcdcd',
            fingers: '021314',
            barres: 12,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'maj11',
    positions: [
        {
            frets: '1320',
            fingers: '1320'
        },
        {
            frets: 'b9bb',
            fingers: '2134'
        }
    ]
};

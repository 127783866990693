export default {
    key: 'A',
    suffix: '7',
    positions: [
        {
            frets: '0100',
            fingers: '0100'
        },
        {
            frets: '2434',
            fingers: '1324'
        },
        {
            frets: '6757',
            fingers: '2314'
        },
        {
            frets: '999a',
            fingers: '1112',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: '7b9',
    positions: [
        {
            frets: 'x32323',
            fingers: '021314',
            barres: 2,
            capo: true
        },
        {
            frets: '8786xx',
            fingers: '324100'
        },
        {
            frets: '8x8989',
            fingers: '101213',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxa9b9',
            fingers: '003142'
        }
    ]
};

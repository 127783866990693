export default {
    key: 'A',
    suffix: 'maj7#5',
    positions: [
        {
            frets: 'x03121',
            fingers: '003121',
            barres: 1
        },
        {
            frets: 'x03224',
            fingers: '002113',
            barres: 2
        },
        {
            frets: 'x06665',
            fingers: '002341'
        },
        {
            frets: '9cba99',
            fingers: '043211',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'add9',
    positions: [
        {
            frets: '1121',
            fingers: '1121',
            barres: 1,
            capo: true
        },
        {
            frets: '3644',
            fingers: '1422',
            barres: 4
        },
        {
            frets: '6869',
            fingers: '1314',
            barres: 6,
            capo: true
        },
        {
            frets: 'ba9b',
            fingers: '3214'
        }
    ]
};

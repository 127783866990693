export default {
    key: 'F',
    suffix: '11',
    positions: [
        {
            frets: '111211',
            fingers: '111211',
            barres: 1,
            capo: true
        },
        {
            frets: '333345',
            fingers: '111123',
            barres: 3,
            capo: true
        },
        {
            frets: 'x87866',
            fingers: '032411',
            barres: 6,
            capo: true
        },
        {
            frets: 'x888a8',
            fingers: '011131',
            barres: 8,
            capo: true
        }
    ]
};

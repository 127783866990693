export default {
    key: 'F',
    suffix: 'maj13',
    positions: [
        {
            frets: '100010',
            fingers: '100020'
        },
        {
            frets: 'xx3230',
            fingers: '002130'
        },
        {
            frets: 'x87755',
            fingers: '042311',
            barres: 5
        },
        {
            frets: 'x889aa',
            fingers: '011234',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: '3443x3',
            fingers: '123101',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx5676',
            fingers: '001243'
        },
        {
            frets: '9abbbx',
            fingers: '123330',
            barres: 11
        },
        {
            frets: 'xabbbx',
            fingers: '012340'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'madd9',
    positions: [
        {
            frets: '42x34x',
            fingers: '310240'
        },
        {
            frets: 'xx6446',
            fingers: '003114',
            barres: 4,
            capo: true
        },
        {
            frets: '466446',
            fingers: '123114',
            barres: 4,
            capo: true
        },
        {
            frets: 'xb98bx',
            fingers: '032140'
        }
    ]
};

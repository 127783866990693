export default {
    key: 'G',
    suffix: 'm69',
    positions: [
        {
            frets: '310230',
            fingers: '310240'
        },
        {
            frets: 'x55355',
            fingers: '022134',
            barres: 5
        },
        {
            frets: '655755',
            fingers: '211311',
            barres: 5,
            capo: true
        },
        {
            frets: 'xa89aa',
            fingers: '031244',
            barres: 10
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '1110',
            fingers: '1230'
        },
        {
            frets: '2544',
            fingers: '1423'
        },
        {
            frets: '6858',
            fingers: '2314'
        },
        {
            frets: 'a99b',
            fingers: '2113',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: 'maj7b5',
    positions: [
        {
            frets: 'x32402',
            fingers: '031402'
        },
        {
            frets: 'x3445x',
            fingers: '012340'
        },
        {
            frets: '879977',
            fingers: '213411',
            barres: 7,
            capo: true
        },
        {
            frets: 'xxabcc',
            fingers: '001234'
        }
    ]
};

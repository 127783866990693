export default {
    key: 'F',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '132113',
            fingers: '132114',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3054',
            fingers: '001042'
        },
        {
            frets: 'x8698x',
            fingers: '021430'
        },
        {
            frets: 'dbxcd0',
            fingers: '310240'
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'm11',
    positions: [
        {
            frets: '202100',
            fingers: '203100'
        },
        {
            frets: '222224',
            fingers: '111114',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4455',
            fingers: '001123',
            barres: 4,
            capo: true
        },
        {
            frets: 'x97997',
            fingers: '021341',
            barres: 7,
            capo: true
        }
    ]
};

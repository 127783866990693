export default {
    key: 'Ab',
    suffix: 'add9',
    positions: [
        {
            frets: '43x34x',
            fingers: '310240'
        },
        {
            frets: 'xx6546',
            fingers: '003214'
        },
        {
            frets: 'xba8b8',
            fingers: '032141',
            barres: 8,
            capo: true
        },
        {
            frets: 'xbaxbb',
            fingers: '021034'
        }
    ]
};

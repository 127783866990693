export default {
    key: 'E',
    suffix: '11',
    positions: [
        {
            frets: '1220',
            fingers: '1230'
        }
    ]
};

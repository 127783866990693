export default {
    key: 'Eb',
    suffix: '9',
    positions: [
        {
            frets: 'xx1021',
            fingers: '001032'
        },
        {
            frets: 'x65666',
            fingers: '021334',
            barres: 6
        },
        {
            frets: 'babaxx',
            fingers: '314200'
        },
        {
            frets: 'bdbcbd',
            fingers: '131214',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'dim7',
    positions: [
        {
            frets: 'xx0101',
            fingers: '000102'
        },
        {
            frets: '4x3434',
            fingers: '201314',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx6767',
            fingers: '001324'
        },
        {
            frets: 'abcaca',
            fingers: '123141',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: '7sus4',
    positions: [
        {
            frets: 'x33311',
            fingers: '023411',
            barres: 1,
            capo: true
        },
        {
            frets: '335363',
            fingers: '113141',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx5566',
            fingers: '001123',
            barres: 5,
            capo: true
        },
        {
            frets: '8a8a88',
            fingers: '131411',
            barres: 8,
            capo: true
        }
    ]
};

import React, { useState, useCallback, useRef } from 'react'
import SongEditor from '../editor/SongEditor'
import { Alert, Spin, Empty, Row } from 'antd';
import Authenticated from '../auth/Authenticated';
import { RouteComponentProps, useParams } from '@reach/router';
import { Song, useSong } from '../../hooks/Song';
import DocumentReferenceBuilder from '../common/DocumentReferenceBuilder';
import { filterLyrics } from '../../util/SongUtil';

interface SongEditorPageLocationProps {
  location: {
    state: {
      song: Song
    }
  }
}

const SongEditorPage: React.FC<RouteComponentProps<SongEditorPageLocationProps>> = (props) => {
  const params = useParams();
  const songDoc = useSong(params.songId);
  const [song, setSong] = useState<Song>();
  const songRef = useRef(song);
  songRef.current = song;

  const saveSong = useCallback(() => {
    if (songRef.current) {
      songDoc?.write(songRef.current);
    }
  }, [songDoc, songRef])

  console.log('render page');

  if (!songDoc) return <Empty />;

  return (
    <Authenticated strategy='signInButton'>
      <DocumentReferenceBuilder
        docRef={songDoc.doc}
        renderValue={(song) => {
          return (
            <div>
              
              <SongEditor
                song={song}
                onSave={(song) => {
                  setSong(song);
                  songRef.current = song;
                  saveSong();
                }} />
            </div>
          );
        }}
        renderError={(err) => <Alert message={err.message} />}
        renderLoading={() => <Row justify='center'><Spin /></Row>}
      />
    </Authenticated>
  );
};

export default SongEditorPage;
export default {
    key: 'Eb',
    suffix: 'm6',
    positions: [
        {
            frets: 'x11312',
            fingers: '011312',
            barres: 1,
            capo: true
        },
        {
            frets: 'x64546',
            fingers: '031214',
            barres: 4,
            capo: true
        },
        {
            frets: 'xx8878',
            fingers: '002314'
        },
        {
            frets: 'bddbdb',
            fingers: '123141',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: '13',
    positions: [
        {
            frets: '2335',
            fingers: '1234'
        },
        {
            frets: '7756',
            fingers: '3412'
        },
        {
            frets: '8755',
            fingers: '4311',
            barres: 5,
            capo: true
        },
        {
            frets: '89aa',
            fingers: '1234'
        }
    ]
};

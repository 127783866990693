export default {
    key: 'F',
    suffix: 'm7',
    positions: [
        {
            frets: '131111',
            fingers: '131111',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3544',
            fingers: '001423'
        },
        {
            frets: '88a898',
            fingers: '113121',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxaa9b',
            fingers: '002314'
        }
    ]
};

export default {
    key: 'Db',
    suffix: 'sus4',
    positions: [
        {
            frets: '1124',
            fingers: '1124',
            barres: 1,
            capo: true
        },
        {
            frets: '6644',
            fingers: '3411',
            barres: 4,
            capo: true
        },
        {
            frets: '6899',
            fingers: '1234'
        },
        {
            frets: 'b89b',
            fingers: '3124'
        }
    ]
};

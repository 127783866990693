import { DraftDecorator, EditorState, ContentBlock, ContentState } from "draft-js";
import { orEmpty } from "@chordpad/common-ts/dist/arrays";

interface EditorPluginFactoryProps {
  editorState?: EditorState
  setEditorState?: (editorState: EditorState) => void
}

interface DraftDecoratorFactoryProps extends EditorPluginFactoryProps {
  blockType?: string
}

export type DraftDecoratorFactory = (props: DraftDecoratorFactoryProps) => DraftDecorator
export type HandleStrategyFn = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => void
export type HandleStrategyFnFactory = (props: DraftDecoratorFactoryProps) => HandleStrategyFn

function isFactory<T extends Function>(obj: any): obj is T {
  return typeof obj === 'function';
}

export interface EditorPluginConfig<Result> {
  decorators?: (DraftDecorator | DraftDecoratorFactory)[]
  command?: string
  blockType?: string
  keyBindingFn?: (e: any) => boolean
  onCommandTriggered?: (editorState: EditorState) => EditorState | undefined
  onResultReceived?: (result: Result, editorState: EditorState) => EditorState | undefined
}

export interface EditorPlugin<Result> {
  decorators: DraftDecorator[]
  command?: string
  trigger: (fn: (command: string) => void) => void
  keyBindingFn: (e: any) => string | undefined
  handleKeyCommand: (command: string, editorState: EditorState) => EditorState | undefined
  acceptResult: (result: Result, state: EditorState, fn: (newState: EditorState) => void) => void
}

export type EditorPluginFactory<Result> = (props: EditorPluginFactoryProps) => EditorPlugin<Result>

export function createEditorPlugin<Result>(config: EditorPluginConfig<Result>, props: EditorPluginFactoryProps): EditorPlugin<Result> {
  let keyBindingFn: (e: any) => string | undefined = (e: any) => undefined;
  if (config.command && config.keyBindingFn) {
    const commandTriggered = config.keyBindingFn;
    keyBindingFn = (e: any) => {
      if (commandTriggered(e)) {
        e.preventDefault();
        return config.command;
      }
    };
  }

  let handleKeyCommand: (command: string, state: EditorState) => EditorState | undefined = 
    (command: string, state: EditorState) => {
      if (config.command) {
        if (command === config.command && config.onCommandTriggered) {
          return config.onCommandTriggered(state);
        }
      }
      return undefined;
    };

  const trigger = (fn: (command: string) => void) => {
    if (config.command) {
      const c = config.command;
      fn(c);
    }
  };

  const decorators = orEmpty(config.decorators?.map((decorator) => {
    if (isFactory<DraftDecoratorFactory>(decorator)) {
      return decorator({ ...props, blockType: config.blockType });
    }
    return decorator;
  }));

  return {
    decorators,
    trigger,
    keyBindingFn,
    handleKeyCommand,
    acceptResult: (result, editorState, fn) => {
      if (config.onResultReceived) {
        const newState = config.onResultReceived(result, editorState);
        if (newState) {
          fn(newState);
        }
      }
    }
  };
}
export default {
    key: 'D',
    suffix: 'maj13',
    positions: [
        {
            frets: '4424',
            fingers: '2314'
        },
        {
            frets: '6677',
            fingers: '1122',
            barres: [6, 7],
            capo: true
        },
        {
            frets: '9b99',
            fingers: '1311',
            barres: 9,
            capo: true
        },
        {
            frets: 'bdce',
            fingers: '1324'
        }
    ]
};

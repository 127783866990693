export default {
    key: 'Ab',
    suffix: 'maj11',
    positions: [
        {
            frets: '43102x',
            fingers: '431020'
        },
        {
            frets: '445544',
            fingers: '112311',
            barres: 4,
            capo: true
        },
        {
            frets: 'xx6688',
            fingers: '001134',
            barres: 6,
            capo: true
        },
        {
            frets: 'bbbcdb',
            fingers: '111231',
            barres: 11,
            capo: true
        }
    ]
};

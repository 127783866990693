export default {
    key: 'C',
    suffix: '7',
    positions: [
        {
            frets: 'x32310',
            fingers: '032410'
        },
        {
            frets: '335353',
            fingers: '113141',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx5556',
            fingers: '001112',
            barres: 5,
            capo: true
        },
        {
            frets: '8a8988',
            fingers: '131211',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'C#',
    suffix: 'minor',
    positions: [
        {
            frets: 'x4212x',
            fingers: '042130'
        },
        {
            frets: '446654',
            fingers: '113421',
            barres: 4,
            capo: true
        },
        {
            frets: '9766x9',
            fingers: '321104',
            barres: 6
        },
        {
            frets: '9bb999',
            fingers: '134111',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: 'aug9',
    positions: [
        {
            frets: 'x21223',
            fingers: '021334',
            barres: 2
        },
        {
            frets: '545445',
            fingers: '213114',
            barres: 4,
            capo: true
        },
        {
            frets: '76768x',
            fingers: '213140',
            barres: 6,
            capo: true
        },
        {
            frets: 'bcbccb',
            fingers: '121341',
            barres: 11,
            capo: true
        }
    ]
};

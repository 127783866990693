export default {
    key: 'Gb',
    suffix: '13b9',
    positions: [
        {
            frets: '0301',
            fingers: '0301'
        },
        {
            frets: '3436',
            fingers: '1214',
            barres: 3,
            capo: true
        },
        {
            frets: '9766',
            fingers: '4211',
            barres: 6,
            capo: true
        },
        {
            frets: '9aba',
            fingers: '1243'
        }
    ]
};

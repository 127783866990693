export default {
    key: 'E',
    suffix: 'sus2',
    positions: [
        {
            frets: '222452',
            fingers: '111341',
            barres: 2,
            capo: true
        },
        {
            frets: '079977',
            fingers: '013411',
            barres: 7,
            capo: true
        },
        {
            frets: '099900',
            fingers: '012300'
        },
        {
            frets: 'ceexce',
            fingers: '134014',
            barres: 12,
            capo: true
        }
    ]
};

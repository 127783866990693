export default {
    key: 'F',
    suffix: '7#9',
    positions: [
        {
            frets: '131214',
            fingers: '131214',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3244',
            fingers: '002134'
        },
        {
            frets: 'x8789x',
            fingers: '021340'
        },
        {
            frets: 'xbaaab',
            fingers: '021113',
            barres: 10,
            capo: true
        }
    ]
};

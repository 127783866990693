import React from 'react'
import { Row, Col } from 'antd';
import Padding from '../common/Padding';
import { EdgeInsets } from '../common/EdgeInsets';

const AppContent: React.FC = (props) => {
  return (
    <Padding insets={EdgeInsets.all(8)}>
      <Row align='middle' justify='center' >
        <Col xs={24} sm={22} md={18} lg={14}>
          {props.children}
        </Col>
      </Row>
    </Padding>
  );
};

export default AppContent;
export default {
    key: 'E',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '0320',
            fingers: '0320'
        },
        {
            frets: 'b9ba',
            fingers: '3142'
        },
        {
            frets: 'c9b9',
            fingers: '4131',
            barres: 9,
            capo: true
        }
    ]
};

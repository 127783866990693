export default {
    key: 'A',
    suffix: 'add9',
    positions: [
        {
            frets: 'x02420',
            fingers: '001320'
        },
        {
            frets: 'xx7657',
            fingers: '003214'
        },
        {
            frets: 'xcb9c9',
            fingers: '032141',
            barres: 9,
            capo: true
        },
        {
            frets: 'xcbxcc',
            fingers: '021034'
        }
    ]
};

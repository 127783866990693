import React from 'react';
import { ContentBlock, ContentState, Modifier, EditorState, SelectionState } from "draft-js";
import { DraftDecoratorFactory, HandleStrategyFnFactory } from './EditorPlugin';
import { forEachEnclosedSubstr, TextCallbacks } from '@chordpad/common-ts/dist/strings';


export function forEachChordInText(text: string, callbacks: TextCallbacks) {
  forEachEnclosedSubstr(text, '[', ']', callbacks);
}

const handleStrategy: HandleStrategyFnFactory = (props) => (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  let updated = false;
  let newContent = contentState;

  function handle(start: number, end: number) {
    updated = true;
    callback(start, end);
    const { editorState, blockType } = props;
    if (editorState && blockType) {
      const selection = SelectionState.createEmpty(contentBlock.getKey());
      newContent = Modifier.setBlockType(contentState, selection, blockType);
    }
  }

  function updateState() {
    if (updated) {
      const {setEditorState, editorState} = props;
      if (setEditorState && editorState) {
        const newEditorState = EditorState.push(editorState, newContent, 'change-block-type');
        console.log('updating!')
        setEditorState(newEditorState);
      }
    }
  }

  const text = contentBlock.getText();
  forEachChordInText(text, {
    onTextSpan: callback
  });
  let openIndex = text.indexOf('[');
  while (openIndex !== -1) {
    const closeIndex = text.indexOf(']', openIndex);
    if (closeIndex !== -1) {
      handle(openIndex + 1, closeIndex);
      openIndex = text.indexOf('[', closeIndex);
    } else {
      openIndex = -1;
    }
  }
  if (updated) {
    updateState()
  }
};

const HandleSpan: React.FC<Node> = (props) => {
  return (
    <span style={{ color: '#9F5563' }}>{props.children}</span>
  );
};

export const guitarChordDecorator: DraftDecoratorFactory = ({ blockType, editorState, setEditorState }) => {
  return {
    strategy: handleStrategy({ blockType, editorState, setEditorState }),
    component: HandleSpan,
  }
};
export default {
    key: 'G',
    suffix: '7sus4',
    positions: [
        {
            frets: '330011',
            fingers: '230011',
            barres: 1,
            capo: true
        },
        {
            frets: '353533',
            fingers: '131411',
            barres: 3,
            capo: true
        },
        {
            frets: 'xaaa88',
            fingers: '023411',
            barres: 8,
            capo: true
        },
        {
            frets: 'aacada',
            fingers: '113141',
            barres: 10,
            capo: true
        }
    ]
};

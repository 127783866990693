export default {
    key: 'E',
    suffix: 'maj7',
    positions: [
        {
            frets: '1302',
            fingers: '1302'
        },
        {
            frets: '4446',
            fingers: '1113',
            barres: 4,
            capo: true
        },
        {
            frets: '9876',
            fingers: '4321'
        },
        {
            frets: '8877',
            fingers: '2311',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: '/D#',
    positions: [
        {
            frets: 'xx1211',
            fingers: '001211',
            barres: 1
        },
        {
            frets: 'x67565',
            fingers: '024131',
            barres: 5
        },
        {
            frets: 'x63565',
            fingers: '031242',
            barres: 5
        }
    ]
};

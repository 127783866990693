export default {
    key: 'B',
    suffix: '13b5b9',
    positions: [
        {
            frets: '1010',
            fingers: '1020'
        },
        {
            frets: '2543',
            fingers: '1432'
        },
        {
            frets: '5858',
            fingers: '1314',
            barres: 5,
            capo: true
        },
        {
            frets: 'a98b',
            fingers: '3214'
        }
    ]
};

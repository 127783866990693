export default {
    key: 'B',
    suffix: '13b9',
    positions: [
        {
            frets: '2343',
            fingers: '1243'
        },
        {
            frets: '5856',
            fingers: '1412',
            barres: 5,
            capo: true
        },
        {
            frets: '898b',
            fingers: '1214',
            barres: 8,
            capo: true
        },
        {
            frets: 'ecbb',
            fingers: '4211',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: 'mmaj7',
    positions: [
        {
            frets: '132111',
            fingers: '132111',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3554',
            fingers: '001342'
        },
        {
            frets: '88a998',
            fingers: '114231',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxfddc',
            fingers: '004231'
        }
    ]
};

export default {
    key: 'A',
    suffix: '9',
    positions: [
        {
            frets: '542000',
            fingers: '431000'
        },
        {
            frets: 'x02423',
            fingers: '001312',
            barres: 2
        },
        {
            frets: '575657',
            fingers: '131214',
            barres: 5,
            capo: true
        },
        {
            frets: 'xcbccc',
            fingers: '021333',
            barres: 12
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm7',
    positions: [
        {
            frets: '0202',
            fingers: '0102'
        },
        {
            frets: '4435',
            fingers: '2213',
            barres: 4
        },
        {
            frets: '7777',
            fingers: '1111',
            barres: 7,
            capo: true
        },
        {
            frets: '9baa',
            fingers: '1322',
            barres: 10
        }
    ]
};

export default {
    key: 'F',
    suffix: '13',
    positions: [
        {
            frets: '131231',
            fingers: '131241',
            barres: 1,
            capo: true
        },
        {
            frets: '111233',
            fingers: '111234',
            barres: 1,
            capo: true
        },
        {
            frets: 'x878aa',
            fingers: '021344',
            barres: 10
        },
        {
            frets: '8888aa',
            fingers: '111134',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: 'maj7#5',
    positions: [
        {
            frets: 'xx1002',
            fingers: '001003'
        },
        {
            frets: '3x444x',
            fingers: '102340'
        },
        {
            frets: '7a9877',
            fingers: '143211',
            barres: 7,
            capo: true
        },
        {
            frets: 'xadbcx',
            fingers: '014230'
        }
    ]
};

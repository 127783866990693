export default {
    key: 'C#',
    suffix: 'm9',
    positions: [
        {
            frets: 'x42444',
            fingers: '021344',
            barres: 4
        },
        {
            frets: 'x66657',
            fingers: '022314',
            barres: 6
        },
        {
            frets: 'x79899',
            fingers: '013244',
            barres: 9
        },
        {
            frets: '9b999b',
            fingers: '131114',
            barres: 9,
            capo: true
        }
    ]
};

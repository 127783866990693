export default {
    key: 'E',
    suffix: 'maj7b5',
    positions: [
        {
            frets: '011140',
            fingers: '011140',
            barres: 1
        },
        {
            frets: 'xx2344',
            fingers: '001234'
        },
        {
            frets: 'x7889x',
            fingers: '012340'
        },
        {
            frets: '0bddbb',
            fingers: '013411',
            barres: 11
        }
    ]
};

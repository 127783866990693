export default {
    key: 'A',
    suffix: '6',
    positions: [
        {
            frets: 'x02222',
            fingers: '001111',
            barres: 2
        },
        {
            frets: '5x465x',
            fingers: '201430'
        },
        {
            frets: '57x675',
            fingers: '130241',
            barres: 5,
            capo: true
        },
        {
            frets: 'xcbbax',
            fingers: '042310'
        }
    ]
};

export default {
    key: 'C#',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: 'x42010',
            fingers: '042010'
        },
        {
            frets: 'x4555x',
            fingers: '012340'
        },
        {
            frets: '9aa9x9',
            fingers: '122101',
            barres: 9,
            capo: true
        },
        {
            frets: 'xxbcdc',
            fingers: '001243'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'm6',
    positions: [
        {
            frets: '2212',
            fingers: '2314'
        },
        {
            frets: '4555',
            fingers: '1234'
        },
        {
            frets: '7978',
            fingers: '1312',
            barres: 7,
            capo: true
        },
        {
            frets: 'abac',
            fingers: '1213',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'maj11',
    positions: [
        {
            frets: '001100',
            fingers: '001200'
        },
        {
            frets: '006445',
            fingers: '003112',
            barres: 4
        },
        {
            frets: '777897',
            fingers: '111231',
            barres: 7,
            capo: true
        },
        {
            frets: '0b99ab',
            fingers: '031124',
            barres: 9
        }
    ]
};

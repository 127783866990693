export default {
    key: 'A',
    suffix: 'm9b5',
    positions: [
        {
            frets: '5332',
            fingers: '4221',
            barres: 3
        },
        {
            frets: '4333',
            fingers: '2111',
            barres: 3,
            capo: true
        },
        {
            frets: '5776',
            fingers: '1342'
        },
        {
            frets: '8b8a',
            fingers: '1413',
            barres: 8,
            capo: true
        }
    ]
};

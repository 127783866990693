export default {
    key: 'F#',
    suffix: 'maj7',
    positions: [
        {
            frets: '243322',
            fingers: '142311',
            barres: 2,
            capo: true
        },
        {
            frets: 'x44666',
            fingers: '011333',
            barres: 4,
            capo: true
        },
        {
            frets: 'x98666',
            fingers: '043111',
            barres: 6,
            capo: true
        },
        {
            frets: '99bab9',
            fingers: '113241',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: 'm7b5',
    positions: [
        {
            frets: 'x2323x',
            fingers: '013240'
        },
        {
            frets: '7x776x',
            fingers: '203410'
        },
        {
            frets: '7897a7',
            fingers: '123141',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx9aaa',
            fingers: '001222',
            barres: 10
        }
    ]
};

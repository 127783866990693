export default {
    key: 'B',
    suffix: '11',
    positions: [
        {
            frets: 'x21200',
            fingers: '021300'
        },
        {
            frets: '222242',
            fingers: '111131',
            barres: 2,
            capo: true
        },
        {
            frets: '777877',
            fingers: '111211',
            barres: 7,
            capo: true
        },
        {
            frets: '9999ab',
            fingers: '111123',
            barres: 9,
            capo: true
        }
    ]
};

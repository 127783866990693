export default {
    key: 'Gb',
    suffix: 'alt',
    positions: [
        {
            frets: '3021',
            fingers: '3021'
        },
        {
            frets: '3663',
            fingers: '1341',
            barres: 3,
            capo: true
        },
        {
            frets: 'ba89',
            fingers: '4312'
        }
    ]
};

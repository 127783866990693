export default {
    key: 'A',
    suffix: 'madd9',
    positions: [
        {
            frets: 'x02410',
            fingers: '002410'
        },
        {
            frets: 'xx7557',
            fingers: '003114',
            barres: 5,
            capo: true
        },
        {
            frets: 'xx7908',
            fingers: '001302'
        },
        {
            frets: 'xca9cx',
            fingers: '032140'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '3220',
            fingers: '3120'
        },
        {
            frets: '2221',
            fingers: '2341'
        },
        {
            frets: '3655',
            fingers: '1423'
        },
        {
            frets: '7969',
            fingers: '2314'
        }
    ]
};

export default {
    key: 'C#',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '143211',
            fingers: '143211',
            barres: 1,
            capo: true
        },
        {
            frets: 'x4756x',
            fingers: '014230'
        },
        {
            frets: 'xxbaa8',
            fingers: '004231'
        },
        {
            frets: 'xxbedd',
            fingers: '001423'
        }
    ]
};

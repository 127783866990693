export default {
    key: 'F',
    suffix: '9b5',
    positions: [
        {
            frets: '101001',
            fingers: '102003'
        },
        {
            frets: 'x87887',
            fingers: '021341',
            barres: 7,
            capo: true
        },
        {
            frets: 'x890ab',
            fingers: '012034'
        },
        {
            frets: 'dcd00d',
            fingers: '212003',
            barres: 13
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'major',
    positions: [
        {
            frets: 'x13331',
            fingers: '012341',
            barres: 1,
            capo: true
        },
        {
            frets: '65333x',
            fingers: '431110',
            barres: 3,
            capo: true
        },
        {
            frets: '688766',
            fingers: '134211',
            barres: 6,
            capo: true
        },
        {
            frets: 'x88aba',
            fingers: '011243',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: '9',
    positions: [
        {
            frets: '554555',
            fingers: '221334',
            barres: 5
        },
        {
            frets: 'x70778',
            fingers: '010234'
        },
        {
            frets: 'a9a9ax',
            fingers: '213140',
            barres: 9,
            capo: true
        },
        {
            frets: 'acabac',
            fingers: '131214',
            barres: 10,
            capo: true
        }
    ]
};

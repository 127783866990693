export default {
    key: 'C#',
    suffix: 'maj7',
    positions: [
        {
            frets: 'x43111',
            fingers: '043111',
            barres: 1,
            capo: true
        },
        {
            frets: '446564',
            fingers: '113241',
            barres: 4,
            capo: true
        },
        {
            frets: 'xxx668',
            fingers: '000113',
            barres: 6
        },
        {
            frets: '9xaa9x',
            fingers: '103420'
        }
    ]
};

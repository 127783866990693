export default {
    key: 'E',
    suffix: 'maj13',
    positions: [
        {
            frets: '1324',
            fingers: '1324'
        },
        {
            frets: '6646',
            fingers: '2314'
        },
        {
            frets: '8899',
            fingers: '1122',
            barres: [8, 9],
            capo: true
        },
        {
            frets: 'bdbb',
            fingers: '1311',
            barres: 11,
            capo: true
        }
    ]
};

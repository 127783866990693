export default {
    key: 'Ab',
    suffix: 'maj11',
    positions: [
        {
            frets: '3133',
            fingers: '2134'
        },
        {
            frets: '5764',
            fingers: '2134'
        }
    ]
};

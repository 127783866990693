export default {
    key: 'G',
    suffix: 'minor',
    positions: [
        {
            frets: '310033',
            fingers: '210034'
        },
        {
            frets: '355333',
            fingers: '134111',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx5786',
            fingers: '001342'
        },
        {
            frets: 'aaccba',
            fingers: '113421',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'm9',
    positions: [
        {
            frets: '3524',
            fingers: '2413'
        },
        {
            frets: '6668',
            fingers: '1113',
            barres: 6,
            capo: true
        },
        {
            frets: 'ba98',
            fingers: '4321'
        },
        {
            frets: 'aa99',
            fingers: '2211',
            barres: [9, 10],
            capo: true
        }
    ]
};

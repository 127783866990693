export default {
    key: 'C',
    suffix: 'aug9',
    positions: [
        {
            frets: 'x32334',
            fingers: '021334',
            barres: 3
        },
        {
            frets: 'x30354',
            fingers: '010243'
        },
        {
            frets: '656556',
            fingers: '213114',
            barres: 5,
            capo: true
        },
        {
            frets: '87879x',
            fingers: '213140',
            barres: 7,
            capo: true
        }
    ]
};

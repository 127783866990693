export default {
    key: 'E',
    suffix: '/C#',
    positions: [
        {
            frets: 'x46454',
            fingers: '013121',
            barres: 4
        },
        {
            frets: 'x46457',
            fingers: '013124',
            barres: 4
        },
        {
            frets: '9b99cc',
            fingers: '131144',
            barres: 9
        }
    ]
};

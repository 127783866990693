export default {
    key: 'D',
    suffix: 'alt',
    positions: [
        {
            frets: '7645',
            fingers: '4312'
        },
        {
            frets: '78a9',
            fingers: '1243'
        },
        {
            frets: 'b8a9',
            fingers: '4132'
        }
    ]
};

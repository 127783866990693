export default {
    key: 'G',
    suffix: 'm7',
    positions: [
        {
            frets: '353333',
            fingers: '131111',
            barres: 3,
            capo: true
        },
        {
            frets: 'x55766',
            fingers: '011423',
            barres: 5,
            capo: true
        },
        {
            frets: 'xa8a8a',
            fingers: '021314',
            barres: 8,
            capo: true
        },
        {
            frets: 'aacaba',
            fingers: '113121',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: '/F#',
    positions: [
        {
            frets: '202220',
            fingers: '102340'
        },
        {
            frets: '242255',
            fingers: '131144',
            barres: 2
        },
        {
            frets: '242225',
            fingers: '131114',
            barres: 2
        }
    ]
};

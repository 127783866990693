export default {
    key: 'F',
    suffix: 'm11',
    positions: [
        {
            frets: '1331',
            fingers: '1341',
            barres: 1,
            capo: true
        },
        {
            frets: 'cabb',
            fingers: '3122',
            barres: 11
        },
        {
            frets: 'daba',
            fingers: '4121',
            barres: 10,
            capo: true
        }
    ]
};

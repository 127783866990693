export default {
    key: 'Eb',
    suffix: 'maj7',
    positions: [
        {
            frets: '3335',
            fingers: '1113',
            barres: 3,
            capo: true
        },
        {
            frets: '8765',
            fingers: '4321'
        },
        {
            frets: '7766',
            fingers: '2311',
            barres: 6,
            capo: true
        },
        {
            frets: '8aaa',
            fingers: '1234'
        }
    ]
};

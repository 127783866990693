export default {
    key: 'A',
    suffix: 'maj13',
    positions: [
        {
            frets: '1122',
            fingers: '1122',
            barres: [1, 2],
            capo: true
        },
        {
            frets: '4644',
            fingers: '1311',
            barres: 4,
            capo: true
        },
        {
            frets: '6879',
            fingers: '1324'
        },
        {
            frets: 'bb9b',
            fingers: '2314'
        }
    ]
};

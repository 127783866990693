export default {
    key: 'D',
    suffix: '7sus4',
    positions: [
        {
            frets: 'xx0213',
            fingers: '000214'
        },
        {
            frets: 'x5553x',
            fingers: '023410'
        },
        {
            frets: '557585',
            fingers: '113141',
            barres: 5,
            capo: true
        },
        {
            frets: 'acacaa',
            fingers: '131411',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Ab',
    suffix: '6',
    positions: [
        {
            frets: 'x31111',
            fingers: '031111',
            barres: 1,
            capo: true
        },
        {
            frets: '4x354x',
            fingers: '201430'
        },
        {
            frets: 'x66868',
            fingers: '011314',
            barres: 6,
            capo: true
        },
        {
            frets: 'xbaa9x',
            fingers: '042310'
        }
    ]
};

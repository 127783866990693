export default {
    key: 'Bb',
    suffix: '7#9',
    positions: [
        {
            frets: 'x1012x',
            fingers: '010230'
        },
        {
            frets: '686799',
            fingers: '131244',
            barres: [6, 9],
            capo: true
        },
        {
            frets: 'xx8799',
            fingers: '002134'
        },
        {
            frets: 'xdcdex',
            fingers: '021340'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '0322',
            fingers: '0211',
            barres: 2
        },
        {
            frets: '4636',
            fingers: '2314'
        },
        {
            frets: '8779',
            fingers: '2113',
            barres: 7,
            capo: true
        },
        {
            frets: 'cbb9',
            fingers: '4231'
        }
    ]
};

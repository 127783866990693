export default {
    key: 'G',
    suffix: 'maj7b5',
    positions: [
        {
            frets: '324422',
            fingers: '213411',
            barres: 2,
            capo: true
        },
        {
            frets: '3444xx',
            fingers: '123400'
        },
        {
            frets: 'xx5677',
            fingers: '001234'
        },
        {
            frets: 'xabbcx',
            fingers: '012340'
        }
    ]
};

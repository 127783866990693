export default {
    key: 'Bb',
    suffix: 'm7',
    positions: [
        {
            frets: 'x13121',
            fingers: '013121',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3324',
            fingers: '002314'
        },
        {
            frets: '686666',
            fingers: '131111',
            barres: 6,
            capo: true
        },
        {
            frets: 'x88a99',
            fingers: '011423',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'dim',
    positions: [
        {
            frets: '1242',
            fingers: '1243'
        },
        {
            frets: '4242',
            fingers: '3141',
            barres: 2,
            capo: true
        },
        {
            frets: '7875',
            fingers: '2431'
        },
        {
            frets: 'dbab',
            fingers: '4213'
        }
    ]
};

import React, { useState, useMemo, useEffect } from 'react'
import { Form, Input } from 'antd';
import { SaveOutlined, CheckOutlined } from '@ant-design/icons';

interface EditableText {
  text?: string | null
  onTextChange: (newText: string) => void
  onPressEnter?: () => void
}

const EditableText: React.FC<EditableText> = (props) => {
  const text = useMemo(() => props.text ? props.text : '', [props.text]);
  const [internalText, setInternalText] = useState(text);

  useEffect(() => {
    setInternalText(text);
  }, [text]);

  const textDiff = internalText === text;
  const validateStatus = textDiff ? 'success' : 'warning';

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalText(e.target.value);
    props.onTextChange(e.target.value);
  }

  return (
    <Form className='EditableText'>
      <Form.Item validateStatus={validateStatus}>
        <Input 
          addonAfter={validateStatus === 'warning' ? <SaveOutlined /> : <CheckOutlined />}
          value={internalText}
          onChange={onChange}
          onPressEnter={props.onPressEnter} />
      </Form.Item>
    </Form>
  );
};

export default EditableText;
export default {
    key: 'Bb',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '2113',
            fingers: '2113',
            barres: 1,
            capo: true
        },
        {
            frets: '5554',
            fingers: '2341'
        },
        {
            frets: '6553',
            fingers: '4231'
        },
        {
            frets: '6988',
            fingers: '1423'
        }
    ]
};

export default {
    key: 'A',
    suffix: 'mmaj11',
    positions: [
        {
            frets: 'x00110',
            fingers: '000120'
        },
        {
            frets: '556557',
            fingers: '112114',
            barres: 5,
            capo: true
        },
        {
            frets: 'x07798',
            fingers: '001132',
            barres: 7
        },
        {
            frets: 'xcadca',
            fingers: '021431',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: '11',
    positions: [
        {
            frets: '320011',
            fingers: '320011',
            barres: 1
        },
        {
            frets: '555567',
            fingers: '111123',
            barres: 5,
            capo: true
        },
        {
            frets: 'xa9a88',
            fingers: '032411',
            barres: 8,
            capo: true
        },
        {
            frets: 'xaaaca',
            fingers: '011131',
            barres: 10,
            capo: true
        }
    ]
};

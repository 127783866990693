export default {
    key: 'E',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '032144',
            fingers: '032144',
            barres: 4
        },
        {
            frets: '476544',
            fingers: '143211',
            barres: 4,
            capo: true
        },
        {
            frets: 'x7a89x',
            fingers: '014230'
        },
        {
            frets: '0ba99b',
            fingers: '032114',
            barres: 9
        }
    ]
};

export default {
    key: 'D',
    suffix: 'major',
    positions: [
        {
            frets: '2220',
            fingers: '1230'
        },
        {
            frets: '2225',
            fingers: '1114',
            barres: 2,
            capo: true
        },
        {
            frets: '7655',
            fingers: '3211',
            barres: 5,
            capo: true
        },
        {
            frets: '79a9',
            fingers: '1243'
        }
    ]
};

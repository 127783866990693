export default {
    key: 'F#',
    suffix: '7b5',
    positions: [
        {
            frets: '2x231x',
            fingers: '203410'
        },
        {
            frets: 'xx4556',
            fingers: '001234'
        },
        {
            frets: 'x78978',
            fingers: '012413',
            barres: 7,
            capo: true
        },
        {
            frets: 'x9a9b0',
            fingers: '012130',
            barres: 9
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'dim',
    positions: [
        {
            frets: '3101',
            fingers: '3102'
        },
        {
            frets: '3464',
            fingers: '1243'
        },
        {
            frets: '6464',
            fingers: '3141',
            barres: 4,
            capo: true
        },
        {
            frets: '9a97',
            fingers: '2431'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'm69',
    positions: [
        {
            frets: 'x20122',
            fingers: '020134'
        },
        {
            frets: 'x56677',
            fingers: '012233',
            barres: [6, 7]
        },
        {
            frets: '799799',
            fingers: '122134',
            barres: [7, 9],
            capo: true
        },
        {
            frets: 'ax9b99',
            fingers: '201311',
            barres: 9,
            capo: true
        }
    ]
};

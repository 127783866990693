export default {
    key: 'Bb',
    suffix: 'maj7',
    positions: [
        {
            frets: '3210',
            fingers: '3210'
        },
        {
            frets: '2211',
            fingers: '2311',
            barres: 1,
            capo: true
        },
        {
            frets: '3555',
            fingers: '1234'
        },
        {
            frets: '7968',
            fingers: '2413'
        }
    ]
};

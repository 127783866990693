import { useState, useEffect } from "react";

export function useDebounced<T>(state: T, ms: number): T {
  const [internal, setInternal] = useState(state);

  useEffect(() => {
    console.log('starting timer')
    const timer = setTimeout(() => {
      console.log('timer complete')
      setInternal(state);
    }, ms);
    return () => {
      clearTimeout(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return internal;
}
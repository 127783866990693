export default {
    key: 'A',
    suffix: 'm9',
    positions: [
        {
            frets: 'x02413',
            fingers: '002413'
        },
        {
            frets: '575557',
            fingers: '131114',
            barres: 5,
            capo: true
        },
        {
            frets: '809080',
            fingers: '103020'
        },
        {
            frets: 'xcaccc',
            fingers: '021344',
            barres: 12
        }
    ]
};

export default {
    key: 'F',
    suffix: 'm7b5',
    positions: [
        {
            frets: '1x110x',
            fingers: '102300'
        },
        {
            frets: 'xx3444',
            fingers: '001222',
            barres: 4
        },
        {
            frets: 'x8989x',
            fingers: '013240'
        },
        {
            frets: 'dbdd0x',
            fingers: '213400'
        }
    ]
};

export default {
    key: 'F',
    suffix: '6',
    positions: [
        {
            frets: '1x3231',
            fingers: '103241',
            barres: 1,
            capo: true
        },
        {
            frets: 'x33535',
            fingers: '011314',
            barres: 3,
            capo: true
        },
        {
            frets: 'x8776x',
            fingers: '042310'
        },
        {
            frets: '88aaaa',
            fingers: '113333',
            barres: [8, 10]
        }
    ]
};

export default {
    key: 'C#',
    suffix: '7#9',
    positions: [
        {
            frets: 'x43420',
            fingers: '032410'
        },
        {
            frets: 'x4345x',
            fingers: '021340'
        },
        {
            frets: 'x89999',
            fingers: '012234',
            barres: 9
        },
        {
            frets: '9b9a9c',
            fingers: '131214',
            barres: 9,
            capo: true
        }
    ]
};

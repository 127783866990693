export default {
    key: 'F',
    suffix: 'm11',
    positions: [
        {
            frets: '111113',
            fingers: '111114',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3344',
            fingers: '001123',
            barres: 3,
            capo: true
        },
        {
            frets: 'x86886',
            fingers: '021341',
            barres: 6,
            capo: true
        },
        {
            frets: 'dbdcbb',
            fingers: '314211',
            barres: 11,
            capo: true
        }
    ]
};

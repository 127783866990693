export default {
    key: 'D',
    suffix: 'sus2',
    positions: [
        {
            frets: 'xx0230',
            fingers: '000230'
        },
        {
            frets: 'xx2235',
            fingers: '001124',
            barres: 2
        },
        {
            frets: '557755',
            fingers: '113411',
            barres: 5,
            capo: true
        },
        {
            frets: 'x777aa',
            fingers: '011144',
            barres: [7, 10],
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'add9',
    positions: [
        {
            frets: '022102',
            fingers: '023104'
        },
        {
            frets: 'x76474',
            fingers: '032141',
            barres: 4,
            capo: true
        },
        {
            frets: 'x76x77',
            fingers: '021034'
        },
        {
            frets: 'xxedce',
            fingers: '003214'
        }
    ]
};

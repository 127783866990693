export default {
    key: 'Bb',
    suffix: '9#11',
    positions: [
        {
            frets: 'x10110',
            fingers: '010230'
        },
        {
            frets: '65655x',
            fingers: '213110',
            barres: 5,
            capo: true
        },
        {
            frets: 'xx899a',
            fingers: '001234'
        },
        {
            frets: 'xdcddc',
            fingers: '021341',
            barres: 12,
            capo: true
        }
    ]
};

export default {
    key: 'Db',
    suffix: '7',
    positions: [
        {
            frets: '1112',
            fingers: '1112',
            barres: 1,
            capo: true
        },
        {
            frets: '4544',
            fingers: '1211',
            barres: 4,
            capo: true
        },
        {
            frets: '6878',
            fingers: '1324'
        },
        {
            frets: 'ab9b',
            fingers: '2314'
        }
    ]
};

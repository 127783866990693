export default {
    key: 'B',
    suffix: 'mmaj7',
    positions: [
        {
            frets: 'x20302',
            fingers: '010302'
        },
        {
            frets: '224332',
            fingers: '114231',
            barres: 2,
            capo: true
        },
        {
            frets: '798777',
            fingers: '132111',
            barres: 7,
            capo: true
        },
        {
            frets: 'x99bba',
            fingers: '011342',
            barres: 9,
            capo: true
        }
    ]
};

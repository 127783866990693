export default {
    key: 'B',
    suffix: 'aug7',
    positions: [
        {
            frets: 'x21203',
            fingers: '021304'
        },
        {
            frets: 'x25243',
            fingers: '002113',
            barres: 2,
            capo: true
        },
        {
            frets: '7x788x',
            fingers: '102340'
        },
        {
            frets: 'xx9cab',
            fingers: '001423'
        }
    ]
};

import React from 'react';
import { createEditorPlugin, DraftDecoratorFactory } from "./EditorPlugin";
import { forEachEnclosedSubstr } from '@chordpad/common-ts/dist/strings';

const HandleSpan: React.FC = ({children}) => {
  return (
    <span style={{ fontSize: '1.1em', fontFamily: '"Trebuchet MS", Helvetica, sans-serif', color: 'rgb(20, 20, 100)'}}>{children}</span>
  );
}

const sectionDecorator: DraftDecoratorFactory = (props) => {
  return {
    strategy: (block, callback) => {
      forEachEnclosedSubstr(block.getText(), '--', '--', {
        onTextSpan: callback
      }, {
        inclusive: true
      });
    },
    component: HandleSpan,
  };
};

export const sectionPlugin = createEditorPlugin({
  decorators: [ sectionDecorator ]
}, {});
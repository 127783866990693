export default {
    key: 'E',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '021002',
            fingers: '021003'
        },
        {
            frets: '0x4443',
            fingers: '002241',
            barres: 4
        },
        {
            frets: '0a9870',
            fingers: '043210'
        },
        {
            frets: 'cedcce',
            fingers: '132114',
            barres: 12,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'alt',
    positions: [
        {
            frets: 'x0122x',
            fingers: '001230'
        },
        {
            frets: 'x07645',
            fingers: '004312'
        },
        {
            frets: 'x0b8a9',
            fingers: '004132'
        },
        {
            frets: 'xcdeex',
            fingers: '012340'
        }
    ]
};

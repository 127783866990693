export default {
    key: 'G',
    suffix: '/F#',
    positions: [
        {
            frets: '220003',
            fingers: '120003'
        },
        {
            frets: '220033',
            fingers: '120034'
        },
        {
            frets: 'xx4433',
            fingers: '002311',
            barres: 3
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '11',
    positions: [
        {
            frets: 'a89a',
            fingers: '3124'
        }
    ]
};

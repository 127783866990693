export default {
    key: 'A',
    suffix: '/G#',
    positions: [
        {
            frets: '442255',
            fingers: '231144',
            barres: 2
        },
        {
            frets: '442225',
            fingers: '231114',
            barres: 2
        },
        {
            frets: 'xx6655',
            fingers: '002311',
            barres: 5
        }
    ]
};

export default {
    key: 'Ab',
    suffix: '69',
    positions: [
        {
            frets: 'x11111',
            fingers: '011111',
            barres: 1,
            capo: true
        },
        {
            frets: '433344',
            fingers: '211134',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx6566',
            fingers: '002134'
        },
        {
            frets: 'xbaabb',
            fingers: '021134',
            barres: 10,
            capo: true
        }
    ]
};

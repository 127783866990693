export default {
    key: 'C',
    suffix: 'm6',
    positions: [
        {
            frets: 'x31213',
            fingers: '031214',
            barres: 1,
            capo: true
        },
        {
            frets: 'x35x45',
            fingers: '013024'
        },
        {
            frets: '8x7888',
            fingers: '201334',
            barres: 8
        },
        {
            frets: '8aa8a8',
            fingers: '123141',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'Db',
    suffix: 'm9b5',
    positions: [
        {
            frets: '0302',
            fingers: '0201'
        },
        {
            frets: '4436',
            fingers: '2214',
            barres: 4
        },
        {
            frets: '9776',
            fingers: '4221',
            barres: 7
        },
        {
            frets: '8777',
            fingers: '2111',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: '7b9',
    positions: [
        {
            frets: '320131',
            fingers: '320141',
            barres: 1
        },
        {
            frets: '3x3434',
            fingers: '101213',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx5464',
            fingers: '002131',
            barres: 4,
            capo: true
        },
        {
            frets: 'xa9a9a',
            fingers: '021314',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: '7sus4',
    positions: [
        {
            frets: '131311',
            fingers: '131411',
            barres: 1,
            capo: true
        },
        {
            frets: '688866',
            fingers: '123411',
            barres: 6,
            capo: true
        },
        {
            frets: '88a8b8',
            fingers: '113141',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxaabb',
            fingers: '001134',
            barres: 10,
            capo: true
        }
    ]
};

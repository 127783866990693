export default {
    key: 'F',
    suffix: '13b9',
    positions: [
        {
            frets: '2325',
            fingers: '1214',
            barres: 2,
            capo: true
        },
        {
            frets: '8655',
            fingers: '4211',
            barres: 5,
            capo: true
        },
        {
            frets: '89a9',
            fingers: '1243'
        },
        {
            frets: 'bebc',
            fingers: '1412',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Gb',
    suffix: '13b5b9',
    positions: [
        {
            frets: '0303',
            fingers: '0102'
        },
        {
            frets: '5436',
            fingers: '3214'
        },
        {
            frets: '9786',
            fingers: '4231'
        },
        {
            frets: '8787',
            fingers: '3142'
        }
    ]
};

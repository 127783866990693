export default {
    key: 'F#',
    suffix: 'sus4',
    positions: [
        {
            frets: '244422',
            fingers: '123411',
            barres: 2,
            capo: true
        },
        {
            frets: 'x44677',
            fingers: '011234',
            barres: 4,
            capo: true
        },
        {
            frets: '99bbc9',
            fingers: '112341',
            barres: 9,
            capo: true
        },
        {
            frets: 'xxbbce',
            fingers: '001124',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: '/G',
    positions: [
        {
            frets: '303211',
            fingers: '304211',
            barres: 1
        },
        {
            frets: '3332xx',
            fingers: '234100'
        },
        {
            frets: '333565',
            fingers: '111343',
            barres: 3
        }
    ]
};

export default {
    key: 'D',
    suffix: '7#9',
    positions: [
        {
            frets: '2523',
            fingers: '1412',
            barres: 2,
            capo: true
        },
        {
            frets: '5658',
            fingers: '1214',
            barres: 5,
            capo: true
        },
        {
            frets: 'a989',
            fingers: '4213'
        },
        {
            frets: 'b988',
            fingers: '4211',
            barres: 8,
            capo: true
        }
    ]
};

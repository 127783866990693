export default {
    key: 'D',
    suffix: 'm69',
    positions: [
        {
            frets: 'x53200',
            fingers: '042100'
        },
        {
            frets: 'x53450',
            fingers: '031240'
        },
        {
            frets: 'x70767',
            fingers: '020314'
        },
        {
            frets: 'xccacc',
            fingers: '022134',
            barres: 12
        }
    ]
};

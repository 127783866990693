export default {
    key: 'Eb',
    suffix: 'sus4',
    positions: [
        {
            frets: 'xx1344',
            fingers: '001234'
        },
        {
            frets: '668896',
            fingers: '112341',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx889x',
            fingers: '001120',
            barres: 8
        },
        {
            frets: 'bdddbb',
            fingers: '123411',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: 'sus2',
    positions: [
        {
            frets: '224422',
            fingers: '113411',
            barres: 2,
            capo: true
        },
        {
            frets: '7xx677',
            fingers: '200134'
        },
        {
            frets: '999bc9',
            fingers: '111341',
            barres: 9,
            capo: true
        },
        {
            frets: 'xebbce',
            fingers: '031124',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: 'maj7b5',
    positions: [
        {
            frets: '0122',
            fingers: '0123'
        },
        {
            frets: '4634',
            fingers: '2413'
        },
        {
            frets: '6779',
            fingers: '1234'
        },
        {
            frets: 'cb99',
            fingers: '4311',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: 'minor',
    positions: [
        {
            frets: '224432',
            fingers: '113421',
            barres: 2,
            capo: true
        },
        {
            frets: '799777',
            fingers: '134111',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx9bca',
            fingers: '001342'
        },
        {
            frets: 'xxcbca',
            fingers: '003241'
        }
    ]
};

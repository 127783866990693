export default {
    key: 'Db',
    suffix: 'maj7',
    positions: [
        {
            frets: '1113',
            fingers: '1113',
            barres: 1,
            capo: true
        },
        {
            frets: '5544',
            fingers: '2311',
            barres: 4,
            capo: true
        },
        {
            frets: '6543',
            fingers: '4321'
        },
        {
            frets: '6888',
            fingers: '1234'
        }
    ]
};

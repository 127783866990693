export default {
    key: 'F#',
    suffix: 'dim',
    positions: [
        {
            frets: '20x21x',
            fingers: '200310'
        },
        {
            frets: 'xx45x5',
            fingers: '001203'
        },
        {
            frets: 'x97x78',
            fingers: '041023'
        },
        {
            frets: 'x9abax',
            fingers: '012430'
        }
    ]
};

export default {
    key: 'F#',
    suffix: '7b9',
    positions: [
        {
            frets: '212020',
            fingers: '213040'
        },
        {
            frets: 'xx4353',
            fingers: '002131',
            barres: 3,
            capo: true
        },
        {
            frets: 'x98989',
            fingers: '021314',
            barres: 8,
            capo: true
        },
        {
            frets: 'edecxx',
            fingers: '324100'
        }
    ]
};

export default {
    key: 'E',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '001002',
            fingers: '001003'
        },
        {
            frets: 'x22243',
            fingers: '011132',
            barres: 2,
            capo: true
        },
        {
            frets: 'x75875',
            fingers: '021431',
            barres: 5,
            capo: true
        },
        {
            frets: '777887',
            fingers: '111231',
            barres: 7,
            capo: true
        }
    ]
};

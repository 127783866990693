export default {
    key: 'C',
    suffix: 'sus2',
    positions: [
        {
            frets: '0233',
            fingers: '0122',
            barres: 3
        },
        {
            frets: '5785',
            fingers: '1341',
            barres: 5,
            capo: true
        },
        {
            frets: '778a',
            fingers: '1124',
            barres: 7,
            capo: true
        },
        {
            frets: 'ccaa',
            fingers: '3411',
            barres: 10,
            capo: true
        }
    ]
};

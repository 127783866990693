export default {
    key: 'G',
    suffix: 'maj9',
    positions: [
        {
            frets: '2222',
            fingers: '1111',
            barres: 2,
            capo: true
        },
        {
            frets: '4655',
            fingers: '1322',
            barres: 5
        },
        {
            frets: '7979',
            fingers: '1324'
        },
        {
            frets: 'bbac',
            fingers: '2213',
            barres: 11
        }
    ]
};

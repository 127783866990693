export default {
    key: 'G',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '334335',
            fingers: '112114',
            barres: 3,
            capo: true
        },
        {
            frets: 'x55576',
            fingers: '011132',
            barres: 5,
            capo: true
        },
        {
            frets: 'xa8ba8',
            fingers: '021431',
            barres: 8,
            capo: true
        },
        {
            frets: 'aaabba',
            fingers: '111231',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: 'm6',
    positions: [
        {
            frets: '3x2333',
            fingers: '201344',
            barres: 3
        },
        {
            frets: '355353',
            fingers: '123141',
            barres: 3,
            capo: true
        },
        {
            frets: 'x55756',
            fingers: '011312',
            barres: 5,
            capo: true
        },
        {
            frets: 'xa898a',
            fingers: '031214',
            barres: 8,
            capo: true
        }
    ]
};

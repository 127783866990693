export default {
    key: 'B',
    suffix: '6',
    positions: [
        {
            frets: '1322',
            fingers: '1423'
        },
        {
            frets: '4646',
            fingers: '1324'
        },
        {
            frets: '8879',
            fingers: '2314'
        },
        {
            frets: 'bbbb',
            fingers: '1111',
            barres: 11,
            capo: true
        }
    ]
};

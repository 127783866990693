export default {
    key: 'C#',
    suffix: 'm7',
    positions: [
        {
            frets: 'x46454',
            fingers: '013121',
            barres: 4,
            capo: true
        },
        {
            frets: 'xx6657',
            fingers: '002314'
        },
        {
            frets: '9b9999',
            fingers: '141111',
            barres: 9,
            capo: true
        },
        {
            frets: 'xxbdcc',
            fingers: '001423'
        }
    ]
};

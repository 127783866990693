export default {
    key: 'C',
    suffix: 'm7',
    positions: [
        {
            frets: 'x3134x',
            fingers: '021340'
        },
        {
            frets: '335343',
            fingers: '113121',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx5546',
            fingers: '002314'
        },
        {
            frets: '8a8888',
            fingers: '131111',
            barres: 8,
            capo: true
        }
    ]
};

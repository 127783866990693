import React from 'react'

interface CopyrightProps {
  ownerName: string
  fullStartingYear: number
}

const Copyright: React.FC<CopyrightProps> = ({ ownerName, fullStartingYear }) => {
  const thisYear = new Date().getFullYear();
  let yearText = `${fullStartingYear}`;
  if (thisYear !== fullStartingYear) {
    yearText += `-${thisYear}`
  }
  return (
    <div className='Copyright'>Copyright © {yearText} {ownerName}</div>
  );
};

export default Copyright;
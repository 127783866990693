export default {
    key: 'E',
    suffix: 'm6',
    positions: [
        {
            frets: '022020',
            fingers: '012030'
        },
        {
            frets: '022423',
            fingers: '011312',
            barres: 2
        },
        {
            frets: 'x75657',
            fingers: '031214',
            barres: 5,
            capo: true
        },
        {
            frets: 'xx9989',
            fingers: '002314'
        }
    ]
};

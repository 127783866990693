export default {
    key: 'F',
    suffix: 'minor',
    positions: [
        {
            frets: '1013',
            fingers: '1024'
        },
        {
            frets: '5543',
            fingers: '3421'
        },
        {
            frets: '5888',
            fingers: '1234'
        },
        {
            frets: 'a888',
            fingers: '3111',
            barres: 8,
            capo: true
        }
    ]
};

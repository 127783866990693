export default {
    key: 'Bb',
    suffix: '6',
    positions: [
        {
            frets: '0211',
            fingers: '0211',
            barres: 1
        },
        {
            frets: '3535',
            fingers: '1324'
        },
        {
            frets: '7768',
            fingers: '2314'
        },
        {
            frets: 'aaaa',
            fingers: '1111',
            barres: 10,
            capo: true
        }
    ]
};

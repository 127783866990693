export default {
    key: 'B',
    suffix: 'm9',
    positions: [
        {
            frets: 'x20222',
            fingers: '010234'
        },
        {
            frets: 'x44435',
            fingers: '023314',
            barres: 4
        },
        {
            frets: '797779',
            fingers: '131114',
            barres: 7,
            capo: true
        },
        {
            frets: 'acbbca',
            fingers: '132241',
            barres: [10, 11],
            capo: true
        }
    ]
};

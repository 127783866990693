export default {
    key: 'D',
    suffix: '9#11',
    positions: [
        {
            frets: 'xx0112',
            fingers: '000123'
        },
        {
            frets: 'x54554',
            fingers: '021341',
            barres: 4,
            capo: true
        },
        {
            frets: 'x90798',
            fingers: '030142'
        },
        {
            frets: 'a9a99a',
            fingers: '213114',
            barres: 9,
            capo: true
        }
    ]
};

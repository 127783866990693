export default {
    key: 'B',
    suffix: '69',
    positions: [
        {
            frets: 'x21122',
            fingers: '021134',
            barres: 1,
            capo: true
        },
        {
            frets: '766677',
            fingers: '211134',
            barres: 6,
            capo: true
        },
        {
            frets: 'x99899',
            fingers: '022134',
            barres: 9
        },
        {
            frets: 'xebdcb',
            fingers: '041321',
            barres: 11,
            capo: true
        }
    ]
};

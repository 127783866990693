export default {
    key: 'D',
    suffix: '/A',
    positions: [
        {
            frets: 'x00232',
            fingers: '000132'
        },
        {
            frets: '557775',
            fingers: '112341',
            barres: 5
        },
        {
            frets: '55477x',
            fingers: '231440',
            barres: 7
        }
    ]
};

import React, { useState, useEffect } from 'react';
import { useAuth } from "./Auth";
import { createContext, useContext } from "react";
import firebase from 'firebase';
import {  empty, mapValues, merge, Entry, entries } from '@chordpad/common-ts/dist/records';
import { FeatureName } from '../config/Features';
import { groupBy } from '@chordpad/common-ts/dist/arrays';
import seed from 'seed-random';
import { FingerprintUtil } from '../util/FingerprintUtil';

interface Named {
  name: FeatureName
}

export interface BooleanFeature extends Named {
  enabled: boolean
}

function isBooleanFeature(feature: Feature): feature is BooleanFeature {
  return typeof (feature as any).enabled === 'boolean';
}

export interface NumberFeature extends Named {
  value: number
}

function isNumberFeature(feature: Feature): feature is NumberFeature {
  return typeof (feature as any).value === 'number';
}

// Percentage of user base enabled
function isNumberFeatureEnabled(feature: NumberFeature) {
  const fingerprint = FingerprintUtil.getFingerprint();
  const rand = seed(fingerprint + feature.name);
  return rand() * 100 < feature.value;
}

export interface DateRangeFeature extends Named {
  from?: firebase.firestore.Timestamp
  to?: firebase.firestore.Timestamp
}

function isDateRangeFeature(feature: Feature): feature is DateRangeFeature {
  return (feature as any).from  || (feature as any).to;
}

// Enabled during time range
function isDateRangeFeatureEnabled(feature: DateRangeFeature): boolean {
  const now = firebase.firestore.Timestamp.now();
  if (feature.from === undefined && feature.to === undefined) return false;
  if (feature.from) {
    if (now < feature.from) return false;
  }
  if (feature.to) {
    if (now > feature.to) return false;
  }
  return true;
}

export function isEnabled(feature: Feature | undefined) {
  if (feature === undefined) return false;
  if (isNumberFeature(feature)) {
    return isNumberFeatureEnabled(feature);
  }
  if (isDateRangeFeature(feature)) {
    return isDateRangeFeatureEnabled(feature);
  }
  if (isBooleanFeature(feature)) {
    return feature.enabled;
  }
}

export type Feature = BooleanFeature | NumberFeature | DateRangeFeature;

type FeatureRecord = Record<FeatureName, Feature | undefined>;

export const FeaturesContext = createContext<FeatureRecord>({} as FeatureRecord);

function featuresFromQuerySnapshot(query: firebase.firestore.QuerySnapshot): FeatureRecord {
  if (query.empty) return empty();
  return mapValues(
    groupBy(
      query.docs, 
      (doc) => doc.id
    ), 
    (v) => ({ ...v.data(), name: v.id }) as Feature
  );
}

function featureFromEntry(entry: Entry<any, any>): Feature {
  return {
    ...entry.value,
    name: entry.key,
  };
}

function featuresFromDocumentSnapshot(doc: firebase.firestore.DocumentSnapshot): FeatureRecord {
  const data = doc.data()
  if (data) {
    return groupBy(entries(data).map(featureFromEntry), (e) => e.name);
  }
  return empty();
}

export const FeaturesProvider: React.FC = (props) => {
  const auth = useAuth();
  const [features, setFeatures] = useState<FeatureRecord>(empty());
  const [userFeatures, setUserFeatures] = useState<FeatureRecord>(empty());
  const [globalFeatures, setGlobalFeatures] = useState<FeatureRecord>(empty());

  // Global Features
  useEffect(() => {
    const collection = firebase.firestore().collection('features');
    return collection
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          setGlobalFeatures(empty());
        } else {
          setGlobalFeatures(featuresFromQuerySnapshot(snapshot));
        }
      }, (err) => {
        setGlobalFeatures(empty());
      });
  }, []);

  // User Features
  useEffect(() => {
    const collection = firebase.firestore().collection('userFeatures');
    if (auth.user) {
      return collection
        .doc(auth.user.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setUserFeatures(featuresFromDocumentSnapshot(snapshot));
          } else {
            setUserFeatures(empty());
          }
        }, (err) => {
          setUserFeatures(empty());
        });
    }
  }, [auth.user]);

  useEffect(() => {
    setFeatures(merge(globalFeatures, userFeatures));
  }, [globalFeatures, userFeatures]);

  return (
    <FeaturesContext.Provider value={features}>
      {props.children}
    </FeaturesContext.Provider>
  )
}

export function useFeature<T extends Feature>(featureName: FeatureName): T | undefined {
  const features = useContext(FeaturesContext);
  const feature = features[featureName];
  if (feature) {
    return feature as T;
  }
  return undefined;
}
export default {
    key: 'Gb',
    suffix: '11',
    positions: [
        {
            frets: '3442',
            fingers: '2341'
        },
        {
            frets: '4441',
            fingers: '2341'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'maj9',
    positions: [
        {
            frets: '3324',
            fingers: '2213',
            barres: 3
        },
        {
            frets: '6666',
            fingers: '1111',
            barres: 6,
            capo: true
        },
        {
            frets: '8a99',
            fingers: '1322',
            barres: 9
        },
        {
            frets: 'bdbd',
            fingers: '1324'
        }
    ]
};

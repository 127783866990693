import guitar from './db/guitar';
import ukulele from './db/ukulele';
import guitarLib from '../lib/guitar.json';
import ukuleleLib from '../lib/ukulele.json';
export default {
    guitar: guitar,
    ukulele: ukulele,
    guitarLib: guitarLib,
    ukuleleLib: ukuleleLib
};

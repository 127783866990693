export default {
    key: 'Bb',
    suffix: 'madd9',
    positions: [
        {
            frets: '3113',
            fingers: '3114',
            barres: 1,
            capo: true
        },
        {
            frets: '6563',
            fingers: '3241'
        },
        {
            frets: '5564',
            fingers: '2231',
            barres: 5
        }
    ]
};

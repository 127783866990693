import { useAuth } from "./Auth";
import firebase from "firebase";
import { useCallback } from "react";
import { Archivable, Timestamped, ArchiveDoc } from "./Docs";
import { useUserPreferences } from "./UserPreferences";
import { RawDraftContentState } from "draft-js";

type SongId = string;

export interface Song extends Archivable, Timestamped {
  id: SongId,
  title: string,
  content?: RawDraftContentState
}

const initialSong = {
  title: 'Untitled',
  archived: false,
};


export function useOwnedSongs() {
  const { prefs } = useUserPreferences();
  const limit = prefs.songLimit ? prefs.songLimit : 10;
  const collection = firebase.firestore()
    .collection('songs');
  const auth = useAuth();
  const create = useCallback(() => {
    async function internalCreate() {
      if (auth.user) {
        const now = firebase.firestore.Timestamp.now();
        const doc = collection.doc();
        await doc.set({
          ...initialSong,
          owner: auth.user.uid,
          archived: false,
          createdAt: now,
          updatedAt: now,
        });
        const snapshot = await doc.get();
        return { 
          ...snapshot.data(), 
          id: snapshot.id 
        } as Song;
      }
    }
    return internalCreate();
  }, [auth.user, collection]);

  if (auth.user) {

    const query = collection.where('owner', '==', auth.user.uid)
      .where('archived', '==', false)
      .orderBy('updatedAt', 'desc')
      .limit(limit);

    return {
      query: query as firebase.firestore.Query<Song>,
      create: create,
      songLimit: limit,
    };
  }
  return null;
}

function isRaw(content: any): content is RawDraftContentState {
  return content.getCurrentContent === undefined && content.blocks?.length && content.entityMap;
}

export function useSong(songId: string): ArchiveDoc<Song> | null {

  console.log('useSong')
  const auth = useAuth();
  if (auth.user) {
    const doc = firebase.firestore()
      .collection('songs')
      .doc(songId) as firebase.firestore.DocumentReference<Song>;

    return {
      doc,
      write: (value) => {
        return doc.set({
          ...value,
          content: isRaw(value.content) ? value.content : { blocks: [], entityMap: {} },
          updatedAt: firebase.firestore.Timestamp.now(),
        });
      },
      archive: () => {
        return doc.update({
          archived: true,
          updatedAt: firebase.firestore.Timestamp.now(),
        });
      },
      unarchive: () => {
        return doc.update({
          archived: false,
          updatedAt: firebase.firestore.Timestamp.now(),
        })
      }
    };
  }
  return null;
}
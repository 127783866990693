export default {
    key: 'Eb',
    suffix: '7#9',
    positions: [
        {
            frets: 'xx1022',
            fingers: '002034'
        },
        {
            frets: 'x6567x',
            fingers: '021340'
        },
        {
            frets: 'x98889',
            fingers: '021113',
            barres: 8,
            capo: true
        },
        {
            frets: 'babbxx',
            fingers: '213400'
        }
    ]
};

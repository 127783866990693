export default {
    key: 'E',
    suffix: 'alt',
    positions: [
        {
            frets: '9867',
            fingers: '4312'
        },
        {
            frets: '9acb',
            fingers: '1243'
        },
        {
            frets: 'dacb',
            fingers: '4132'
        }
    ]
};

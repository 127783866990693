import React, { createContext, useContext, useState, useMemo, useCallback } from "react";

interface DebugMode {
  enabled: boolean
  toggle: () => void
}

export const DebugMode = createContext<DebugMode>({ 
  enabled: process.env.NODE_ENV === 'development', 
  toggle: () => {},
});

export const DebugProvider: React.FC = ({children}) => {
  const [debug, setDebug] = useState(process.env.NODE_ENV === 'development');
  const toggleDebug = useCallback(() => {
    if (process.env.NODE_ENV === 'development') {
      setDebug(!debug)
    }
  }, [debug]);
  const debugMode = useMemo(() => ({
    enabled: debug,
    toggle: toggleDebug
  }), [debug, toggleDebug]);

  return (
    <DebugMode.Provider value={debugMode}>
      {children}
    </DebugMode.Provider>
  );
};

export function useDebugMode() {
  return useContext(DebugMode);
} 
export default {
    key: 'B',
    suffix: 'dim',
    positions: [
        {
            frets: '4212',
            fingers: '4213'
        },
        {
            frets: '4575',
            fingers: '1243'
        },
        {
            frets: '7575',
            fingers: '3141',
            barres: 5,
            capo: true
        },
        {
            frets: 'aba8',
            fingers: '2431'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '6',
    positions: [
        {
            frets: 'xx1313',
            fingers: '001314',
            barres: 1
        },
        {
            frets: 'x6554x',
            fingers: '042310'
        },
        {
            frets: 'x68888',
            fingers: '013333',
            barres: 8
        },
        {
            frets: 'bxacbx',
            fingers: '201430'
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'sus2',
    positions: [
        {
            frets: '113311',
            fingers: '113411',
            barres: 1,
            capo: true
        },
        {
            frets: '63356x',
            fingers: '311240',
            barres: 3,
            capo: true
        },
        {
            frets: '888ab8',
            fingers: '111341',
            barres: 8,
            capo: true
        },
        {
            frets: 'xdaabd',
            fingers: '031124',
            barres: 10,
            capo: true
        }
    ]
};

import { createEditorPlugin, EditorPluginFactory } from "./EditorPlugin";
import { guitarChordDecorator, forEachChordInText } from "./GuitarChordDecorator";
import { getSelectedText } from "../../draftUtils";
import { replaceSelection } from "../../EditorUtils";
import { KeyBindingUtil } from "draft-js";
import { enclosedSubstrs } from "@chordpad/common-ts/dist/strings";

const CHORD_COMMAND = 'chord';
const CHORD_BLOCK_TYPE = 'chord';

export function chordsInText(text: string) {
  return enclosedSubstrs(text, '[', ']');
}

export const guitarChordEditorPlugin =  createEditorPlugin({
  decorators: [guitarChordDecorator],
  command: CHORD_COMMAND,
  blockType: CHORD_BLOCK_TYPE,
  keyBindingFn: (e) => {
    if (e.keyCode === 71 && KeyBindingUtil.hasCommandModifier(e)) {
      return true;
    }
    return false;
  },
  onCommandTriggered: (state) => {
    const selectedText = getSelectedText(state);
    if (selectedText) {
      const newState = replaceSelection(state, `[${selectedText}]`);
      return newState;
    }
    return undefined;
  },
  
}, {});
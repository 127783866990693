export default {
    key: 'F#',
    suffix: '9#11',
    positions: [
        {
            frets: '212112',
            fingers: '213114',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx4556',
            fingers: '001234'
        },
        {
            frets: 'x98998',
            fingers: '021341',
            barres: 8,
            capo: true
        },
        {
            frets: 'x9a9b9',
            fingers: '012131',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: '7b9#5',
    positions: [
        {
            frets: '1101',
            fingers: '1203'
        },
        {
            frets: '3444',
            fingers: '1234'
        },
        {
            frets: '6867',
            fingers: '1312',
            barres: 6,
            capo: true
        },
        {
            frets: '9a9b',
            fingers: '1213',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'add9',
    positions: [
        {
            frets: '3213',
            fingers: '3214'
        },
        {
            frets: '5565',
            fingers: '1121',
            barres: 5,
            capo: true
        },
        {
            frets: '7a88',
            fingers: '1422',
            barres: 8
        },
        {
            frets: 'acad',
            fingers: '1314',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'C#',
    suffix: '11',
    positions: [
        {
            frets: 'x43004',
            fingers: '021003'
        },
        {
            frets: 'x45464',
            fingers: '012131',
            barres: 4,
            capo: true
        },
        {
            frets: '989889',
            fingers: '213114',
            barres: 8,
            capo: true
        },
        {
            frets: '9a9a99',
            fingers: '121311',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '7',
    positions: [
        {
            frets: 'xx1323',
            fingers: '001324'
        },
        {
            frets: 'x68686',
            fingers: '013141',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx8889',
            fingers: '001112',
            barres: 8,
            capo: true
        },
        {
            frets: 'bdbcbb',
            fingers: '131211',
            barres: 11,
            capo: true
        }
    ]
};

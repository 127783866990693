export default {
    key: 'B',
    suffix: 'maj7b5',
    positions: [
        {
            frets: 'x2334x',
            fingers: '012340'
        },
        {
            frets: '768866',
            fingers: '213411',
            barres: 6,
            capo: true
        },
        {
            frets: '78880x',
            fingers: '123400'
        },
        {
            frets: 'xx9abb',
            fingers: '001234'
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm/D',
    positions: [
        {
            frets: 'xx0000',
            fingers: '000000'
        },
        {
            frets: 'x5545x',
            fingers: '023140'
        },
        {
            frets: 'xxcccc',
            fingers: '001111',
            barres: 12
        }
    ]
};

export default {
    key: 'A',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '536400',
            fingers: '314200'
        },
        {
            frets: 'x06504',
            fingers: '003201'
        },
        {
            frets: '576557',
            fingers: '132114',
            barres: 5,
            capo: true
        },
        {
            frets: 'x09998',
            fingers: '002341'
        }
    ]
};

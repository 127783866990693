export default {
    key: 'Eb',
    suffix: 'm11',
    positions: [
        {
            frets: 'xx1122',
            fingers: '001134',
            barres: 1,
            capo: true
        },
        {
            frets: 'x64664',
            fingers: '021341',
            barres: 4,
            capo: true
        },
        {
            frets: 'b9ba99',
            fingers: '314211',
            barres: 9,
            capo: true
        },
        {
            frets: 'bbbbbd',
            fingers: '111114',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: 'sus2',
    positions: [
        {
            frets: 'x30013',
            fingers: '030014'
        },
        {
            frets: 'x30033',
            fingers: '010023'
        },
        {
            frets: '335533',
            fingers: '113411',
            barres: 3,
            capo: true
        },
        {
            frets: '8x0788',
            fingers: '200134'
        }
    ]
};

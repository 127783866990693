export default {
    key: 'C#',
    suffix: 'maj11',
    positions: [
        {
            frets: 'x43522',
            fingers: '032411',
            barres: 2,
            capo: true
        },
        {
            frets: '444564',
            fingers: '111231',
            barres: 4,
            capo: true
        },
        {
            frets: '98xx78',
            fingers: '420013'
        },
        {
            frets: '99aa99',
            fingers: '112311',
            barres: 9,
            capo: true
        }
    ]
};

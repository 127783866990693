export default {
    key: 'Bb',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: 'x12220',
            fingers: '012340'
        },
        {
            frets: '67x655',
            fingers: '240311',
            barres: 5,
            capo: true
        },
        {
            frets: '6776x6',
            fingers: '123101',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx89a9',
            fingers: '001243'
        }
    ]
};

export default {
    key: 'G',
    suffix: '13b5b9',
    positions: [
        {
            frets: '1414',
            fingers: '1314',
            barres: 1,
            capo: true
        },
        {
            frets: '6547',
            fingers: '3214'
        },
        {
            frets: 'a897',
            fingers: '4231'
        },
        {
            frets: '9898',
            fingers: '3142'
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'mmaj7',
    positions: [
        {
            frets: '243222',
            fingers: '132111',
            barres: 2,
            capo: true
        },
        {
            frets: 'x44665',
            fingers: '011342',
            barres: 4,
            capo: true
        },
        {
            frets: 'x9766x',
            fingers: '042110',
            barres: 6,
            capo: true
        },
        {
            frets: '99baa9',
            fingers: '114231',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'mmaj7',
    positions: [
        {
            frets: 'xx0221',
            fingers: '000231'
        },
        {
            frets: 'x53220',
            fingers: '042110',
            barres: 2
        },
        {
            frets: '557665',
            fingers: '114231',
            barres: 5,
            capo: true
        },
        {
            frets: 'acbaaa',
            fingers: '132111',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: '7sus4',
    positions: [
        {
            frets: 'x13141',
            fingers: '013141',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3344',
            fingers: '001122',
            barres: [3, 4],
            capo: true
        },
        {
            frets: '686866',
            fingers: '131411',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx8a9b',
            fingers: '001324'
        }
    ]
};

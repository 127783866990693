export default {
    key: 'Eb',
    suffix: '13',
    positions: [
        {
            frets: 'x65688',
            fingers: '021344',
            barres: 8
        },
        {
            frets: '666688',
            fingers: '111134',
            barres: 6,
            capo: true
        },
        {
            frets: 'baa099',
            fingers: '423011',
            barres: 9
        },
        {
            frets: 'bbbcdd',
            fingers: '111234',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: '7#9',
    positions: [
        {
            frets: '212222',
            fingers: '213334',
            barres: 2
        },
        {
            frets: '242325',
            fingers: '131214',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4355',
            fingers: '002134'
        },
        {
            frets: 'x989ax',
            fingers: '021340'
        }
    ]
};

export default {
    key: 'A',
    suffix: 'm11',
    positions: [
        {
            frets: 'x00010',
            fingers: '000010'
        },
        {
            frets: '535433',
            fingers: '314211',
            barres: 3,
            capo: true
        },
        {
            frets: '555557',
            fingers: '111114',
            barres: 5,
            capo: true
        },
        {
            frets: 'xcacca',
            fingers: '021341',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'm69',
    positions: [
        {
            frets: '6x5668',
            fingers: '201334',
            barres: 6
        },
        {
            frets: '6x8688',
            fingers: '102134',
            barres: 6,
            capo: true
        },
        {
            frets: '9x8a88',
            fingers: '201411',
            barres: 8,
            capo: true
        },
        {
            frets: 'xdbcdx',
            fingers: '031240'
        }
    ]
};

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Editor,
  EditorState, RichUtils, DraftHandleValue, CompositeDecorator, getDefaultKeyBinding,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Button, Card, Row, Col, Affix, Tooltip, Alert, Divider, Switch, Typography } from 'antd';
import { getSelectedText } from '../../draftUtils';
import SynonymModal from './SynonymModal';
import { guitarChordEditorPlugin, chordsInText } from './GuitarChordEditorPlugin';
import { replaceWithSynonymEditorPlugin } from './ReplaceWithSynonymEditorPlugin';
import { Song } from '../../hooks/Song';
import EditableText from '../common/EditableText';
import Padding from '../common/Padding';
import { EdgeInsets } from '../common/EdgeInsets';
import { useDebounced } from '../../hooks/Debounced';
import Feature from '../core/Feature';
import ChordRow from './ChordRow';
import Visibility from '../common/Visibility';
import { filterLyrics, wordDistance, stanzaDistance } from '../../util/SongUtil';
import { Lyrics } from '@chordpad/common-ts/dist/songs';
import { commentPlugin } from './CommentEditorPlugin';
import { sectionPlugin } from './SectionEditorPlugin';

interface SongEditorProps {
  song: Song
  onSave?: (song: Song) => void
}

export default function SongEditor(props: SongEditorProps) {

  const compositeDecorator = new CompositeDecorator([
    ...sectionPlugin.decorators,
    ...guitarChordEditorPlugin.decorators,
    ...commentPlugin.decorators,
  ]);
  const startingContentBlocks = useMemo(() => props.song.content ? props.song.content : convertToRaw(ContentState.createFromText('')), [props.song.content]);
  const startingState = useMemo(() => EditorState.createWithContent(
    convertFromRaw(
      startingContentBlocks
    ),
    compositeDecorator
  ), [compositeDecorator, startingContentBlocks]);

  const [isSynonymModalVisible, setIsSynonymModalVisible] = React.useState(false);
  const [editorState, setEditorState] = React.useState<EditorState>(startingState);

  const [unsavedTitle, setUnsavedTitle] = useState(props.song.title);
  const [selectedText, setSelectedText] = React.useState('');

  const save = useCallback(() => {
    if (props.onSave) {
      console.log('saving')
      props.onSave({
        ...props.song,
        title: unsavedTitle,
        content: convertToRaw(editorState.getCurrentContent()),
      });
    }
  }, [editorState, unsavedTitle, props]);

  const saveRef = useRef(save);
  saveRef.current = save;

  const debouncedText = useDebounced(editorState, 10000).getCurrentContent().getPlainText();

  const editorStateRef = useRef<EditorState>(editorState);
  const chordText = useDebounced(editorState, 1500).getCurrentContent().getPlainText();

  // Only push new editor state if remote text changes
  useEffect(() => {
    const selection = editorState.getSelection();
    const newState = EditorState.push(editorStateRef.current, convertFromRaw(startingContentBlocks), 'change-block-data');
    setEditorState(EditorState.forceSelection(newState, selection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingContentBlocks]);

  // Only diff text when debounced text changes.
  useEffect(() => {
    console.log('Checking text')
    if (debouncedText !== convertFromRaw(startingContentBlocks).getPlainText()) {
      console.log('saving text')
      saveRef.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  function onChange(newEditorState: EditorState) {
    setSelectedText(getSelectedText(newEditorState));
    setEditorState(newEditorState);
  }

  function songKeyBinding(e: any): string | null {
    const synonymCommand = replaceWithSynonymEditorPlugin.keyBindingFn(e);
    if (synonymCommand) {
      return synonymCommand;
    }
    const guitarChordCommand = guitarChordEditorPlugin.keyBindingFn(e);
    if (guitarChordCommand) {
      return guitarChordCommand;
    }
    return getDefaultKeyBinding(e);
  }

  function handleKeyCommand(command: string, editorState: EditorState): DraftHandleValue {
    if (guitarChordEditorPlugin.handleKeyCommand) {
      const newState = guitarChordEditorPlugin.handleKeyCommand(command, editorState);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
    }

    if (replaceWithSynonymEditorPlugin.handleKeyCommand) {
      const newState = replaceWithSynonymEditorPlugin.handleKeyCommand(command, editorState);
      if (newState) {
        setIsSynonymModalVisible(true);
        return 'handled';
      }
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  }


  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.keyCode === 83 && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        save();
      }
    }
    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    }
  }, [save]);

  const [stickyChords, setStickyChords] = useState(false);

  if (props.song?.archived === true) {
    return (
      <div>
        <Alert type='warning' message='This song has been archived and is no longer editable.' />
        <Card
          title={props.song?.title}
          style={{
            fontFamily: 'monospace'
          }}>
          <Editor
            editorState={editorState}
            readOnly={true}
            onChange={() => { }} />
        </Card>
      </div>
    );
  }

  const chords = chordsInText(chordText);

  return (
    <div>
      <EditableText
        text={props.song.title}
        onTextChange={setUnsavedTitle} />
      <Visibility visible={!stickyChords && chords.length > 0}>
        <Card extra={[
          <Switch
            checked={stickyChords}
            onChange={setStickyChords}
            checkedChildren='Sticky'
            unCheckedChildren='Not sticky' />
        ]}>
          <ChordRow chords={chords} />
        </Card>
      </Visibility>
      <Affix>
        <Visibility visible={stickyChords && chords.length > 0}>
          <Card extra={[
            <Switch
              checked={stickyChords}
              onChange={setStickyChords}
              checkedChildren='Sticky'
              unCheckedChildren='Not sticky' />
          ]}>
            <ChordRow chords={chords} />
          </Card>
        </Visibility>
        <Card
          bodyStyle={{ padding: 4 }}>
          <Padding
            insets={EdgeInsets.all(4)}>
            <Row>
              <Col flex={1}>
                <Button
                  onClick={save}>
                  Save
                </Button>
              </Col>
              <Col>
                <Button
                  type='link'
                  onClick={() => guitarChordEditorPlugin.trigger((command) => handleKeyCommand(command, editorState))}>
                  Chord
                </Button>
                <Button
                  type='link'
                  onClick={() => handleKeyCommand('bold', editorState)}>
                  Bold
                </Button>
                <Feature
                  feature={'synonyms'}
                  disabledElement={
                    <Tooltip placement='top' title='Chordpad pro coming soon!'>
                      <Button
                        type='link'
                        disabled>Synonym</Button>
                    </Tooltip>
                  }>
                  <Button
                    type='link'
                    disabled={selectedText === ''}
                    onClick={() => replaceWithSynonymEditorPlugin.trigger((command) => handleKeyCommand(command, editorState))}>
                    Synonym
                  </Button>
                </Feature>
              </Col>
            </Row>
          </Padding>
        </Card>
      </Affix>
      <Card
        style={{
          fontFamily: 'monospace'
        }}>
        <Editor
          editorState={editorState}
          onChange={onChange}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={songKeyBinding}
          autoCapitalize='off'
          autoCorrect='off'
          autoComplete='off'
          spellCheck={false}
        />
        <SynonymModal
          rootWord={selectedText}
          visible={isSynonymModalVisible}
          onSynonymSelected={(syn) => replaceWithSynonymEditorPlugin.acceptResult(syn, editorState, (newState) => {
            setEditorState(newState);
            setIsSynonymModalVisible(false);
          })}
          onCancel={() => setIsSynonymModalVisible(false)}
        />
      </Card>
    </div>
  );
}
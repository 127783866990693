export default {
    key: 'C',
    suffix: 'm11',
    positions: [
        {
            frets: '7566',
            fingers: '3122',
            barres: 6
        },
        {
            frets: '8565',
            fingers: '4121',
            barres: 5,
            capo: true
        },
        {
            frets: '8aa8',
            fingers: '1341',
            barres: 8,
            capo: true
        }
    ]
};

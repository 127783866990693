export default {
    key: 'B',
    suffix: 'mmaj11',
    positions: [
        {
            frets: 'x20320',
            fingers: '010320'
        },
        {
            frets: '222332',
            fingers: '111231',
            barres: 2,
            capo: true
        },
        {
            frets: '778779',
            fingers: '112114',
            barres: 7,
            capo: true
        },
        {
            frets: 'x999ba',
            fingers: '011132',
            barres: 9,
            capo: true
        }
    ]
};

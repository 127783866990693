export default {
    key: 'Eb',
    suffix: 'dim',
    positions: [
        {
            frets: 'xx12x2',
            fingers: '001203'
        },
        {
            frets: 'x64x45',
            fingers: '041023'
        },
        {
            frets: 'x6787x',
            fingers: '012430'
        },
        {
            frets: 'b9xbax',
            fingers: '310420'
        }
    ]
};

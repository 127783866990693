export default {
    key: 'D',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '9798',
            fingers: '3142'
        },
        {
            frets: 'a797',
            fingers: '4131',
            barres: 7,
            capo: true
        },
        {
            frets: 'adca',
            fingers: '1431',
            barres: 10,
            capo: true
        }
    ]
};

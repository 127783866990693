export default {
    key: 'C',
    suffix: '11',
    positions: [
        {
            frets: 'x32311',
            fingers: '032411',
            barres: 1,
            capo: true
        },
        {
            frets: 'x33353',
            fingers: '011131',
            barres: 3,
            capo: true
        },
        {
            frets: '870066',
            fingers: '320011',
            barres: 6
        },
        {
            frets: '888988',
            fingers: '111211',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: '/G',
    positions: [
        {
            frets: '322100',
            fingers: '423100'
        },
        {
            frets: '322454',
            fingers: '211343',
            barres: 2
        },
        {
            frets: 'xx5454',
            fingers: '003142'
        }
    ]
};

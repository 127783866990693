export default {
    key: 'A',
    suffix: 'm9',
    positions: [
        {
            frets: '0002',
            fingers: '0002'
        },
        {
            frets: '5432',
            fingers: '4321'
        },
        {
            frets: '4433',
            fingers: '2211',
            barres: [3, 4],
            capo: true
        },
        {
            frets: '5777',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'm9',
    positions: [
        {
            frets: '1100',
            fingers: '1200'
        },
        {
            frets: '2444',
            fingers: '1234'
        },
        {
            frets: '6857',
            fingers: '2413'
        },
        {
            frets: '999b',
            fingers: '1113',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'aug',
    positions: [
        {
            frets: 'xx4332',
            fingers: '004231'
        },
        {
            frets: 'xx433x',
            fingers: '002110',
            barres: 3,
            capo: true
        },
        {
            frets: 'x9877x',
            fingers: '032110',
            barres: 7,
            capo: true
        },
        {
            frets: 'edcbbx',
            fingers: '432110',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'm9',
    positions: [
        {
            frets: 'x64666',
            fingers: '021344',
            barres: 6
        },
        {
            frets: 'xx8a79',
            fingers: '002413'
        },
        {
            frets: 'x9babb',
            fingers: '013244',
            barres: 11
        },
        {
            frets: 'bdbbbd',
            fingers: '131114',
            barres: 11,
            capo: true
        }
    ]
};

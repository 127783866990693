export default {
    key: 'Bb',
    suffix: 'maj7',
    positions: [
        {
            frets: 'x13231',
            fingers: '013241',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3335',
            fingers: '001114',
            barres: 3,
            capo: true
        },
        {
            frets: '687766',
            fingers: '142311',
            barres: 6,
            capo: true
        },
        {
            frets: 'x88aaa',
            fingers: '011333',
            barres: [8, 10],
            capo: true
        }
    ]
};

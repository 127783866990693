export default {
    key: 'C#',
    suffix: 'm11',
    positions: [
        {
            frets: 'x42422',
            fingers: '021311',
            barres: 2,
            capo: true
        },
        {
            frets: '979877',
            fingers: '314211',
            barres: 7,
            capo: true
        },
        {
            frets: '99999b',
            fingers: '111114',
            barres: 9,
            capo: true
        },
        {
            frets: 'xxbbcc',
            fingers: '001123',
            barres: 11
        }
    ]
};

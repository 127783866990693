export default {
    key: 'E',
    suffix: 'm/G#',
    positions: [
        {
            frets: '422000',
            fingers: '412000'
        },
        {
            frets: '47545x',
            fingers: '142130',
            barres: 4
        },
        {
            frets: 'xx6453',
            fingers: '004231'
        }
    ]
};

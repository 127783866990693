export default {
    key: 'G',
    suffix: 'madd9',
    positions: [
        {
            frets: '310233',
            fingers: '310244',
            barres: 3
        },
        {
            frets: 'xx5335',
            fingers: '003114',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx7086',
            fingers: '002031'
        },
        {
            frets: 'xa87ax',
            fingers: '032140'
        }
    ]
};

export default {
    key: 'A',
    suffix: 'maj7b5',
    positions: [
        {
            frets: 'x01124',
            fingers: '001124',
            barres: 1
        },
        {
            frets: 'x01224',
            fingers: '001224',
            barres: 2
        },
        {
            frets: '5666xx',
            fingers: '123400'
        },
        {
            frets: 'x07899',
            fingers: '001234'
        }
    ]
};

export default {
    key: 'F',
    suffix: '7b5',
    positions: [
        {
            frets: '2312',
            fingers: '2413'
        },
        {
            frets: '4556',
            fingers: '1234'
        },
        {
            frets: '8978',
            fingers: '2413'
        },
        {
            frets: 'abbc',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'sus2',
    positions: [
        {
            frets: '133x13',
            fingers: '123014',
            barres: 1,
            capo: true
        },
        {
            frets: '333563',
            fingers: '111341',
            barres: 3,
            capo: true
        },
        {
            frets: '88aa88',
            fingers: '113411',
            barres: 8,
            capo: true
        },
        {
            frets: 'daacdx',
            fingers: '311240',
            barres: 10,
            capo: true
        }
    ]
};

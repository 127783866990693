export default {
    key: 'G',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '2021',
            fingers: '2031'
        },
        {
            frets: '3020',
            fingers: '3020'
        },
        {
            frets: '3653',
            fingers: '1431',
            barres: 3,
            capo: true
        },
        {
            frets: '5660',
            fingers: '1230'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'aug9',
    positions: [
        {
            frets: '212112',
            fingers: '213114',
            barres: 1,
            capo: true
        },
        {
            frets: '434350',
            fingers: '213140',
            barres: 3
        },
        {
            frets: 'xx4556',
            fingers: '001234'
        },
        {
            frets: 'xbabb0',
            fingers: '021340'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'alt',
    positions: [
        {
            frets: '32x023',
            fingers: '310024'
        },
        {
            frets: 'xx5607',
            fingers: '001203'
        },
        {
            frets: 'xa9009',
            fingers: '031002'
        },
        {
            frets: 'xabccx',
            fingers: '012340'
        }
    ]
};

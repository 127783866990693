export default {
    key: 'C',
    suffix: 'alt',
    positions: [
        {
            frets: 'x32552',
            fingers: '021341',
            barres: 2,
            capo: true
        },
        {
            frets: 'x34550',
            fingers: '012340'
        },
        {
            frets: 'xxa978',
            fingers: '004312'
        },
        {
            frets: 'xxabdc',
            fingers: '001243'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'm6',
    positions: [
        {
            frets: 'xx0201',
            fingers: '000201'
        },
        {
            frets: 'x53435',
            fingers: '031214',
            barres: 3,
            capo: true
        },
        {
            frets: 'x57x67',
            fingers: '013024'
        },
        {
            frets: 'ax9aaa',
            fingers: '201333',
            barres: 10
        }
    ]
};

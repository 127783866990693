export default {
    key: 'Gb',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '2542',
            fingers: '1431',
            barres: 2,
            capo: true
        },
        {
            frets: 'dbdc',
            fingers: '3142'
        },
        {
            frets: 'ebdb',
            fingers: '4131',
            barres: 11
        }
    ]
};

export default {
    key: 'C',
    suffix: 'm69',
    positions: [
        {
            frets: 'x31233',
            fingers: '031244',
            barres: 3
        },
        {
            frets: 'x30045',
            fingers: '010024'
        },
        {
            frets: '8677xx',
            fingers: '412300'
        },
        {
            frets: 'xaa8aa',
            fingers: '022134',
            barres: 10
        }
    ]
};

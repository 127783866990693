export default {
    key: 'Bb',
    suffix: '7',
    positions: [
        {
            frets: 'x13131',
            fingers: '013141',
            barres: 1,
            capo: true
        },
        {
            frets: '686766',
            fingers: '131211',
            barres: 6,
            capo: true
        },
        {
            frets: 'x88a9a',
            fingers: '011324',
            barres: 8,
            capo: true
        },
        {
            frets: 'xdcdbx',
            fingers: '032410'
        }
    ]
};

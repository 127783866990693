export default {
    key: 'A',
    suffix: 'b13#9',
    positions: [
        {
            frets: '0113',
            fingers: '0123'
        },
        {
            frets: '5534',
            fingers: '3412'
        },
        {
            frets: '6533',
            fingers: '4311',
            barres: 3,
            capo: true
        },
        {
            frets: '6788',
            fingers: '1234'
        }
    ]
};

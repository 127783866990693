export default {
    key: 'Db',
    suffix: '7#9',
    positions: [
        {
            frets: '1412',
            fingers: '1412',
            barres: 1,
            capo: true
        },
        {
            frets: '4547',
            fingers: '1214',
            barres: 4,
            capo: true
        },
        {
            frets: '9878',
            fingers: '4213'
        },
        {
            frets: 'a877',
            fingers: '4211',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'madd9',
    positions: [
        {
            frets: '7557',
            fingers: '3114',
            barres: 5,
            capo: true
        },
        {
            frets: 'a9a7',
            fingers: '3241'
        },
        {
            frets: '99a8',
            fingers: '2231',
            barres: 9
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'aug',
    positions: [
        {
            frets: 'xx5443',
            fingers: '004231'
        },
        {
            frets: 'x6544x',
            fingers: '032110',
            barres: 4,
            capo: true
        },
        {
            frets: 'ba988x',
            fingers: '432110',
            barres: 8,
            capo: true
        },
        {
            frets: 'bxdccx',
            fingers: '104230'
        }
    ]
};

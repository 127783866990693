export default {
    key: 'Bb',
    suffix: 'm11',
    positions: [
        {
            frets: '5344',
            fingers: '3122',
            barres: 4
        },
        {
            frets: '6343',
            fingers: '4121',
            barres: 3,
            capo: true
        },
        {
            frets: '6886',
            fingers: '1341',
            barres: 6,
            capo: true
        }
    ]
};

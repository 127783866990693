export default {
    key: 'C',
    suffix: 'm7b5',
    positions: [
        {
            frets: 'x3434x',
            fingers: '013240'
        },
        {
            frets: 'xx4546',
            fingers: '001214',
            barres: 4,
            capo: true
        },
        {
            frets: '89a8b8',
            fingers: '123141',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxabbb',
            fingers: '001333',
            barres: 11
        }
    ]
};

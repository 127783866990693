export default {
    key: 'G',
    suffix: '11',
    positions: [
        {
            frets: '2012',
            fingers: '2013'
        },
        {
            frets: '5552',
            fingers: '2341'
        },
        {
            frets: '4553',
            fingers: '2341'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'm11',
    positions: [
        {
            frets: '3x331x',
            fingers: '203410'
        },
        {
            frets: '333335',
            fingers: '111114',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx5577',
            fingers: '001123',
            barres: 5,
            capo: true
        },
        {
            frets: 'xa8aa8',
            fingers: '021341',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: '7',
    positions: [
        {
            frets: '020100',
            fingers: '020100'
        },
        {
            frets: 'x7675x',
            fingers: '032410'
        },
        {
            frets: '779797',
            fingers: '113141',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx999a',
            fingers: '001112',
            barres: 9,
            capo: true
        }
    ]
};

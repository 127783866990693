import './NamedChord.css';
import React from 'react'
import Chord, { instruments } from '@chordpad/react-chords';

interface NamedChordProps {
  name: string,
}

function isAccidental(name: string): boolean {
  try {
    const accidental = name.charAt(1);
    return accidental === '#' || accidental === 'b';
  } catch {
    return false;
  }
}

interface ChordDef {
  displayName: string,
  note: string,
  suffix?: string,
  position?: number
}

function parseChord(name: string): ChordDef {
  const [_name, positionStr] = name.split(' ');
  let position = 1; 
  try {
    const pos = parseInt(positionStr);
    position = pos ? pos : 1;
  } catch(e) {
    console.error('Invalid chord position: ', e);
  }
  const chordNameLength = isAccidental(_name) ? 2 : 1;
  let note = _name.substr(0, chordNameLength);
  const suffix = _name.length > chordNameLength ? _name.substr(chordNameLength) : undefined;
  return {
    displayName: note + (suffix ? suffix : ''),
    note,
    suffix,
    position,
  };
}

const suffixAliases: Record<string, string> = {
  'maj': 'major',
  'min': 'minor',
  'm': 'minor',
}

const NOTE_REGEX = /([A-G])(#|b)?/

const NamedChord: React.FC<NamedChordProps> = ({ name }) => {
  const { note, suffix, position, displayName } = parseChord(name);
  const _pos = Math.max(position ? position : 1, 1);
  const alias = suffix ? suffixAliases[suffix] : undefined;
  const matches = note.match(NOTE_REGEX);
  if (matches === null) {
    return null;
  }

  return (
    <div className='NamedChord'>
      <p className='name'>{displayName} <sub className='meta'>{_pos}</sub></p>
      <Chord
        note={note}
        suffix={alias ? alias : suffix}
        position={Math.max(_pos - 1, 0)}
        instrument={instruments.guitar} />
    </div>
  );
};

export default NamedChord;
export default {
    key: 'C',
    suffix: '13',
    positions: [
        {
            frets: 'x32355',
            fingers: '021344',
            barres: 5
        },
        {
            frets: '333355',
            fingers: '111134',
            barres: 3,
            capo: true
        },
        {
            frets: '877786',
            fingers: '322241',
            barres: 7
        },
        {
            frets: '8a89a8',
            fingers: '131241',
            barres: 8,
            capo: true
        }
    ]
};

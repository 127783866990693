export default {
    key: 'Gb',
    suffix: 'm11',
    positions: [
        {
            frets: '2442',
            fingers: '1341',
            barres: 2,
            capo: true
        },
        {
            frets: 'dbcc',
            fingers: '3122',
            barres: 12
        },
        {
            frets: 'ebcb',
            fingers: '4121',
            barres: 11
        }
    ]
};

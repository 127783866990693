export default {
    key: 'C',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: '4323',
            fingers: '4213'
        },
        {
            frets: '5322',
            fingers: '4211',
            barres: 2,
            capo: true
        },
        {
            frets: '5676',
            fingers: '1243'
        }
    ]
};

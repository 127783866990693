export default {
    key: 'G',
    suffix: 'm9',
    positions: [
        {
            frets: '300331',
            fingers: '200341'
        },
        {
            frets: '353335',
            fingers: '131114',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx7066',
            fingers: '002011',
            barres: 6
        },
        {
            frets: 'xa8aaa',
            fingers: '021344',
            barres: 10
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'm7b5',
    positions: [
        {
            frets: 'x1212x',
            fingers: '013240'
        },
        {
            frets: 'xx2324',
            fingers: '001214',
            barres: 2,
            capo: true
        },
        {
            frets: '6x665x',
            fingers: '203410'
        },
        {
            frets: 'xx8999',
            fingers: '001222',
            barres: 9
        }
    ]
};

export default {
    key: 'F',
    suffix: 'madd9',
    positions: [
        {
            frets: '0543',
            fingers: '0321'
        },
        {
            frets: 'a88a',
            fingers: '3114',
            barres: 8,
            capo: true
        },
        {
            frets: 'dcda',
            fingers: '3241'
        },
        {
            frets: 'ccdb',
            fingers: '2231',
            barres: 12
        }
    ]
};

export default {
    key: 'F',
    suffix: '7',
    positions: [
        {
            frets: '131211',
            fingers: '131211',
            barres: 1,
            capo: true
        },
        {
            frets: 'x33545',
            fingers: '011324',
            barres: 3,
            capo: true
        },
        {
            frets: '88a8a8',
            fingers: '113141',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxaaab',
            fingers: '001112',
            barres: 10,
            capo: true
        }
    ]
};

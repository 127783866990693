export default {
    key: 'Bb',
    suffix: 'sus2',
    positions: [
        {
            frets: '3563',
            fingers: '1341',
            barres: 3
        },
        {
            frets: '5568',
            fingers: '1124',
            barres: 5
        },
        {
            frets: 'aa88',
            fingers: '3411',
            barres: 8
        },
        {
            frets: 'acdd',
            fingers: '1233',
            barres: 13
        }
    ]
};

export default {
    key: 'A',
    suffix: 'dim',
    positions: [
        {
            frets: '2353',
            fingers: '1243'
        },
        {
            frets: '5353',
            fingers: '3141',
            barres: 3,
            capo: true
        },
        {
            frets: '8986',
            fingers: '2431'
        },
        {
            frets: 'ecbc',
            fingers: '4213'
        }
    ]
};

export default {
    key: 'A',
    suffix: 'mmaj7',
    positions: [
        {
            frets: 'x02110',
            fingers: '003120'
        },
        {
            frets: '576555',
            fingers: '132111',
            barres: 5,
            capo: true
        },
        {
            frets: 'x77998',
            fingers: '011342',
            barres: 7,
            capo: true
        },
        {
            frets: 'xca99x',
            fingers: '042110',
            barres: 9,
            capo: true
        }
    ]
};

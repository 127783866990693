export default {
    key: 'B',
    suffix: '7#9',
    positions: [
        {
            frets: 'x2123x',
            fingers: '021340'
        },
        {
            frets: '760705',
            fingers: '320401'
        },
        {
            frets: '7978aa',
            fingers: '131244',
            barres: [7, 10],
            capo: true
        },
        {
            frets: 'xx98aa',
            fingers: '002134'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'maj7',
    positions: [
        {
            frets: '2224',
            fingers: '1113',
            barres: 2,
            capo: true
        },
        {
            frets: '7654',
            fingers: '4321'
        },
        {
            frets: '6655',
            fingers: '2311',
            barres: 5,
            capo: true
        },
        {
            frets: '7999',
            fingers: '1234'
        }
    ]
};

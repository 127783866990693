export default {
    key: 'A',
    suffix: 'm6',
    positions: [
        {
            frets: 'x02212',
            fingers: '002314'
        },
        {
            frets: '5x4555',
            fingers: '201334',
            barres: 5
        },
        {
            frets: '577575',
            fingers: '123141',
            barres: 5,
            capo: true
        },
        {
            frets: 'x77978',
            fingers: '011312',
            barres: 7,
            capo: true
        }
    ]
};

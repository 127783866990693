export default {
    key: 'A',
    suffix: '/F',
    positions: [
        {
            frets: '102220',
            fingers: '102340'
        },
        {
            frets: 'xx3220',
            fingers: '003120'
        },
        {
            frets: '14222x',
            fingers: '142220',
            barres: 2
        }
    ]
};

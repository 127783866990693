export default {
    key: 'A',
    suffix: 'maj13',
    positions: [
        {
            frets: 'x02122',
            fingers: '002134'
        },
        {
            frets: '544454',
            fingers: '211131',
            barres: 4,
            capo: true
        },
        {
            frets: 'x06677',
            fingers: '001134',
            barres: 6
        },
        {
            frets: 'xcbb99',
            fingers: '042311',
            barres: 9,
            capo: true
        }
    ]
};

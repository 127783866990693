export default {
    key: 'Eb',
    suffix: 'maj11',
    positions: [
        {
            frets: 'xx1133',
            fingers: '001134',
            barres: 1
        },
        {
            frets: 'x65744',
            fingers: '032411',
            barres: 4,
            capo: true
        },
        {
            frets: '666786',
            fingers: '111231',
            barres: 6,
            capo: true
        },
        {
            frets: 'bbccbb',
            fingers: '112311',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'mmaj9',
    positions: [
        {
            frets: 'x53650',
            fingers: '021430'
        },
        {
            frets: 'x70665',
            fingers: '040231'
        },
        {
            frets: 'x809a9',
            fingers: '010243'
        },
        {
            frets: 'acbaac',
            fingers: '132114',
            barres: 10,
            capo: true
        }
    ]
};

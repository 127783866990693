export default {
    key: 'Ab',
    suffix: 'alt',
    positions: [
        {
            frets: 'xx6534',
            fingers: '004312'
        },
        {
            frets: 'xx6798',
            fingers: '001243'
        },
        {
            frets: 'xbadda',
            fingers: '021441',
            barres: [10, 13],
            capo: true
        },
        {
            frets: 'xbcddx',
            fingers: '012340'
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'm6',
    positions: [
        {
            frets: 'x13x23',
            fingers: '013024'
        },
        {
            frets: 'x4536x',
            fingers: '023140'
        },
        {
            frets: '6x5666',
            fingers: '201334',
            barres: 6
        },
        {
            frets: '688686',
            fingers: '123141',
            barres: 6,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'aug9',
    positions: [
        {
            frets: '1223',
            fingers: '1223',
            barres: 2
        },
        {
            frets: '5645',
            fingers: '2413'
        },
        {
            frets: '7889',
            fingers: '1234'
        },
        {
            frets: 'bcab',
            fingers: '2413'
        }
    ]
};

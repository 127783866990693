export default {
    key: 'F',
    suffix: '9',
    positions: [
        {
            frets: '131213',
            fingers: '131214',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3243',
            fingers: '002143'
        },
        {
            frets: '887888',
            fingers: '221334',
            barres: 8
        },
        {
            frets: 'xaaaab',
            fingers: '011112',
            barres: 10
        }
    ]
};

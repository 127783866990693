export default {
    key: 'C',
    suffix: 'm11',
    positions: [
        {
            frets: 'x31331',
            fingers: '021341',
            barres: 1,
            capo: true
        },
        {
            frets: 'x33343',
            fingers: '011121',
            barres: 3,
            capo: true
        },
        {
            frets: '868766',
            fingers: '314211',
            barres: 6,
            capo: true
        },
        {
            frets: '88888a',
            fingers: '111114',
            barres: 8,
            capo: true
        }
    ]
};

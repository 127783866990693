export default {
    key: 'E',
    suffix: 'aug7',
    positions: [
        {
            frets: '030110',
            fingers: '040120'
        },
        {
            frets: 'xx2534',
            fingers: '001423'
        },
        {
            frets: 'x7a798',
            fingers: '014132',
            barres: 7,
            capo: true
        },
        {
            frets: 'cxcddx',
            fingers: '102340'
        }
    ]
};

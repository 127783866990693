export default {
    key: 'Eb',
    suffix: 'sus2',
    positions: [
        {
            frets: '111341',
            fingers: '111341',
            barres: 1,
            capo: true
        },
        {
            frets: '668866',
            fingers: '113411',
            barres: 6,
            capo: true
        },
        {
            frets: 'b88abx',
            fingers: '311240',
            barres: 8,
            capo: true
        },
        {
            frets: 'bddxbd',
            fingers: '123014',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'dim7',
    positions: [
        {
            frets: '2x121x',
            fingers: '201310',
            barres: 1,
            capo: true
        },
        {
            frets: '234242',
            fingers: '123141',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4545',
            fingers: '001324'
        },
        {
            frets: '89a8a8',
            fingers: '123141',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'add9',
    positions: [
        {
            frets: '2102',
            fingers: '2103'
        },
        {
            frets: '4454',
            fingers: '1121',
            barres: 4,
            capo: true
        },
        {
            frets: '6977',
            fingers: '1422',
            barres: 7
        },
        {
            frets: '9b9c',
            fingers: '1314',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: 'dim',
    positions: [
        {
            frets: 'x31x12',
            fingers: '041023'
        },
        {
            frets: 'x3454x',
            fingers: '012430'
        },
        {
            frets: '86x87x',
            fingers: '310420'
        },
        {
            frets: 'xxabxb',
            fingers: '001203'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '112113',
            fingers: '112114',
            barres: 1,
            capo: true
        },
        {
            frets: 'x33354',
            fingers: '011132',
            barres: 3,
            capo: true
        },
        {
            frets: 'x86986',
            fingers: '021431',
            barres: 6,
            capo: true
        },
        {
            frets: '888998',
            fingers: '111231',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: 'x2333x',
            fingers: '012340'
        },
        {
            frets: '78x766',
            fingers: '240311',
            barres: 6,
            capo: true
        },
        {
            frets: '7887x7',
            fingers: '123101',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx9aba',
            fingers: '001243'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'm6',
    positions: [
        {
            frets: '4x344x',
            fingers: '201340'
        },
        {
            frets: '466464',
            fingers: '123141',
            barres: 4,
            capo: true
        },
        {
            frets: 'x66867',
            fingers: '011312',
            barres: 6,
            capo: true
        },
        {
            frets: 'xb9a9b',
            fingers: '031214',
            barres: 9,
            capo: true
        }
    ]
};

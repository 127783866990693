export default {
    key: 'C',
    suffix: 'add9',
    positions: [
        {
            frets: 'x32030',
            fingers: '021030'
        },
        {
            frets: 'x30030',
            fingers: '010030'
        },
        {
            frets: '870080',
            fingers: '210030'
        },
        {
            frets: 'xxa98a',
            fingers: '003214'
        }
    ]
};

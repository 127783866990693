export default {
    key: 'G',
    suffix: '9#11',
    positions: [
        {
            frets: '323223',
            fingers: '213114',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx5667',
            fingers: '001234'
        },
        {
            frets: 'xa9aa9',
            fingers: '021341',
            barres: 9,
            capo: true
        },
        {
            frets: 'xabaca',
            fingers: '012131',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm9b5',
    positions: [
        {
            frets: '0221',
            fingers: '0231'
        },
        {
            frets: '3635',
            fingers: '1413',
            barres: 3,
            capo: true
        },
        {
            frets: '7769',
            fingers: '2214',
            barres: 7
        },
        {
            frets: 'caa9',
            fingers: '4221',
            barres: 10
        }
    ]
};

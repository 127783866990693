export default {
    key: 'C',
    suffix: 'aug7',
    positions: [
        {
            frets: 'x323x4',
            fingers: '021304'
        },
        {
            frets: 'x36354',
            fingers: '014132',
            barres: 3,
            capo: true
        },
        {
            frets: '8x8990',
            fingers: '102340'
        },
        {
            frets: 'xxadbc',
            fingers: '001423'
        }
    ]
};

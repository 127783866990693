import firebase from 'firebase';


export function initializeFirebase() {
  firebase.initializeApp({
    apiKey: "AIzaSyARDno4FpAJk6GSUt8JIn-1b1U4pTfoR2w",
    authDomain: "songwriter-5fbaf.firebaseapp.com",
    databaseURL: "https://songwriter-5fbaf.firebaseio.com",
    projectId: "songwriter-5fbaf",
    storageBucket: "songwriter-5fbaf.appspot.com",
    messagingSenderId: "51964598223",
    appId: "1:51964598223:web:1f6bda3ba4b3f345c224ba",
    measurementId: "G-MKQJKH0P5Q"
  });
  firebase.analytics();
  firebase.auth();
  firebase.storage();
  firebase.firestore();
};

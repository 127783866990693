export default {
    key: 'A',
    suffix: '13b9',
    positions: [
        {
            frets: '0121',
            fingers: '0132'
        },
        {
            frets: '3634',
            fingers: '1412',
            barres: 3,
            capo: true
        },
        {
            frets: '6769',
            fingers: '1214',
            barres: 6,
            capo: true
        },
        {
            frets: 'ca99',
            fingers: '4211',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: '6',
    positions: [
        {
            frets: 'x32210',
            fingers: '042310'
        },
        {
            frets: 'x35555',
            fingers: '013334',
            barres: 5
        },
        {
            frets: '8x798x',
            fingers: '201430'
        },
        {
            frets: '8xa9a8',
            fingers: '103241',
            barres: 8,
            capo: true
        }
    ]
};

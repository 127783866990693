export default {
    key: 'A',
    suffix: 'm/F',
    positions: [
        {
            frets: '102210',
            fingers: '103420'
        },
        {
            frets: 'xx3210',
            fingers: '003210'
        },
        {
            frets: '13221x',
            fingers: '142310',
            barres: 1
        }
    ]
};

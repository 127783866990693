export default {
    key: 'A',
    suffix: '13',
    positions: [
        {
            frets: 'x02022',
            fingers: '001023'
        },
        {
            frets: '544030',
            fingers: '423010'
        },
        {
            frets: '575675',
            fingers: '131241',
            barres: 5,
            capo: true
        },
        {
            frets: 'xcbcee',
            fingers: '021344',
            barres: 14
        }
    ]
};

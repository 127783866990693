export default {
    key: 'Bb',
    suffix: '11',
    positions: [
        {
            frets: '5345',
            fingers: '3124'
        },
        {
            frets: '8885',
            fingers: '2341'
        },
        {
            frets: '7886',
            fingers: '2341'
        }
    ]
};

export default {
    key: 'E',
    suffix: '7b9',
    positions: [
        {
            frets: '020101',
            fingers: '030102'
        },
        {
            frets: '050464',
            fingers: '030141',
            barres: 4
        },
        {
            frets: 'x76767',
            fingers: '021314',
            barres: 6,
            capo: true
        },
        {
            frets: 'cbcaxx',
            fingers: '324100'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '9b5',
    positions: [
        {
            frets: 'x65665',
            fingers: '021341',
            barres: 5,
            capo: true
        },
        {
            frets: 'x67069',
            fingers: '013024'
        },
        {
            frets: 'babaab',
            fingers: '120304',
            barres: 10,
            capo: true
        },
        {
            frets: 'bcbcxd',
            fingers: '121304',
            barres: 11,
            capo: true
        }
    ]
};

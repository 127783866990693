export default {
    key: 'F#',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: '2332x2',
            fingers: '123101',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4565',
            fingers: '001243'
        },
        {
            frets: '89aaax',
            fingers: '123330',
            barres: 10
        },
        {
            frets: 'x9aaax',
            fingers: '012340'
        }
    ]
};

export default {
    key: 'E',
    suffix: 'madd9',
    positions: [
        {
            frets: '9779',
            fingers: '3114',
            barres: 7,
            capo: true
        },
        {
            frets: 'cbc9',
            fingers: '3241'
        },
        {
            frets: 'bbca',
            fingers: '2231',
            barres: 11
        }
    ]
};

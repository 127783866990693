
export class EdgeInsets {
  top?: number
  bottom?: number
  left?: number
  right?: number

  constructor(insets: {
    top?: number,
    bottom?: number,
    left?: number,
    right?: number,
  }) {
    this.top = insets.top;
    this.bottom = insets.bottom;
    this.left = insets.left;
    this.right = insets.right;
  }

  static symmetric(insets: {
    horizontal?: number,
    vertical?: number,
  }): EdgeInsets {
    return new EdgeInsets({
      top: insets.vertical,
      bottom: insets.vertical,
      left: insets.horizontal,
      right: insets.horizontal,
    });
  }

  static all(value: number) {
    return EdgeInsets.symmetric({
      horizontal: value,
      vertical: value, 
    });
  }
}

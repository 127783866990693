export default {
    key: 'D',
    suffix: 'maj7',
    positions: [
        {
            frets: 'xx0222',
            fingers: '000111',
            barres: 2,
            capo: true
        },
        {
            frets: 'x54222',
            fingers: '043111',
            barres: 2,
            capo: true
        },
        {
            frets: '557675',
            fingers: '113241',
            barres: 5,
            capo: true
        },
        {
            frets: 'xx7779',
            fingers: '001114',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: '6',
    positions: [
        {
            frets: '2424',
            fingers: '1324'
        },
        {
            frets: '6657',
            fingers: '2314'
        },
        {
            frets: '9999',
            fingers: '1111',
            barres: 9,
            capo: true
        },
        {
            frets: 'bdcc',
            fingers: '1423'
        }
    ]
};

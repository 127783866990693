export default {
    key: 'E',
    suffix: '7',
    positions: [
        {
            frets: '1202',
            fingers: '1203'
        },
        {
            frets: '4445',
            fingers: '1112',
            barres: 4,
            capo: true
        },
        {
            frets: '7877',
            fingers: '1211',
            barres: 7,
            capo: true
        },
        {
            frets: '9bab',
            fingers: '1324'
        }
    ]
};

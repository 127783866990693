export default {
    key: 'Bb',
    suffix: '13b5b9',
    positions: [
        {
            frets: '1432',
            fingers: '1432'
        },
        {
            frets: '4747',
            fingers: '1314',
            barres: 4,
            capo: true
        },
        {
            frets: '987a',
            fingers: '3214'
        },
        {
            frets: 'dbca',
            fingers: '4231'
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '203121',
            fingers: '204131',
            barres: 1
        },
        {
            frets: '20312x',
            fingers: '204130'
        },
        {
            frets: '243224',
            fingers: '132114',
            barres: 2,
            capo: true
        },
        {
            frets: 'x97a9x',
            fingers: '021430'
        }
    ]
};

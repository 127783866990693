export default {
    key: 'Eb',
    suffix: 'm11',
    positions: [
        {
            frets: 'a899',
            fingers: '3122',
            barres: 9
        },
        {
            frets: 'b898',
            fingers: '4121',
            barres: 8,
            capo: true
        },
        {
            frets: 'bddb',
            fingers: '1341',
            barres: 11,
            capo: true
        }
    ]
};

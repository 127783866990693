export default {
    key: 'D',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '3224',
            fingers: '2113',
            barres: 2,
            capo: true
        },
        {
            frets: '7664',
            fingers: '4231'
        },
        {
            frets: '6665',
            fingers: '2341'
        },
        {
            frets: '7a99',
            fingers: '1423'
        }
    ]
};

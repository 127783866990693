export default {
    key: 'B',
    suffix: 'alt',
    positions: [
        {
            frets: 'x2344x',
            fingers: '012340'
        },
        {
            frets: 'xx9867',
            fingers: '004312'
        },
        {
            frets: '78980x',
            fingers: '124300'
        },
        {
            frets: 'xx9a0b',
            fingers: '001203'
        }
    ]
};

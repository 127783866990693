export default {
    key: 'G',
    suffix: '9',
    positions: [
        {
            frets: '300001',
            fingers: '300001'
        },
        {
            frets: '32323x',
            fingers: '213140',
            barres: 2,
            capo: true
        },
        {
            frets: '353435',
            fingers: '131214',
            barres: 3,
            capo: true
        },
        {
            frets: 'aa9aaa',
            fingers: '221334',
            barres: 10
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'madd9',
    positions: [
        {
            frets: 'xx4341',
            fingers: '003241'
        },
        {
            frets: 'x6436x',
            fingers: '032140'
        },
        {
            frets: 'x64x66',
            fingers: '021034'
        },
        {
            frets: 'xxdbbd',
            fingers: '003114',
            barres: 11,
            capo: true
        }
    ]
};

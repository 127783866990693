export default {
    key: 'Bb',
    suffix: 'alt',
    positions: [
        {
            frets: 'x12330',
            fingers: '012340'
        },
        {
            frets: '670750',
            fingers: '230410'
        },
        {
            frets: '6707x6',
            fingers: '130402'
        },
        {
            frets: 'xx89ba',
            fingers: '001243'
        }
    ]
};

export default {
    key: 'C#',
    suffix: '69',
    positions: [
        {
            frets: 'x41321',
            fingers: '041321',
            barres: 1,
            capo: true
        },
        {
            frets: 'x43344',
            fingers: '021134',
            barres: 3,
            capo: true
        },
        {
            frets: '988899',
            fingers: '211134',
            barres: 8,
            capo: true
        },
        {
            frets: 'xxbabb',
            fingers: '002134'
        }
    ]
};

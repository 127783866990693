export default {
    key: 'A',
    suffix: 'sus4',
    positions: [
        {
            frets: 'x02230',
            fingers: '001230'
        },
        {
            frets: 'x00x30',
            fingers: '000010'
        },
        {
            frets: '577755',
            fingers: '133411',
            barres: [5, 7],
            capo: true
        },
        {
            frets: 'x779aa',
            fingers: '011234',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: '13b5b9',
    positions: [
        {
            frets: '5342',
            fingers: '4231'
        },
        {
            frets: '4343',
            fingers: '3142'
        },
        {
            frets: '5876',
            fingers: '1432'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'sus2',
    positions: [
        {
            frets: '3311',
            fingers: '3411',
            barres: 1,
            capo: true
        },
        {
            frets: '3566',
            fingers: '1233',
            barres: 6
        },
        {
            frets: '8ab8',
            fingers: '1341',
            barres: 8,
            capo: true
        },
        {
            frets: 'aabd',
            fingers: '1124',
            barres: 10,
            capo: true
        }
    ]
};

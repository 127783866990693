export default {
    key: 'C',
    suffix: '/F',
    positions: [
        {
            frets: 'xx3010',
            fingers: '003010'
        },
        {
            frets: '132010',
            fingers: '143020'
        },
        {
            frets: 'xx3553',
            fingers: '001342'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'minor',
    positions: [
        {
            frets: 'xx1342',
            fingers: '001342'
        },
        {
            frets: 'xx4342',
            fingers: '003241'
        },
        {
            frets: '668876',
            fingers: '113421',
            barres: 6,
            capo: true
        },
        {
            frets: 'bddbbb',
            fingers: '134111',
            barres: 11,
            capo: true
        }
    ]
};

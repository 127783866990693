export default {
    key: 'B',
    suffix: '7#9',
    positions: [
        {
            frets: '2325',
            fingers: '1214',
            barres: 2,
            capo: true
        },
        {
            frets: '7656',
            fingers: '4213'
        },
        {
            frets: '8655',
            fingers: '4211',
            barres: 5,
            capo: true
        },
        {
            frets: '89a9',
            fingers: '1243'
        }
    ]
};

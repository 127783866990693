export default {
    key: 'C#',
    suffix: 'mmaj7',
    positions: [
        {
            frets: 'x4211x',
            fingers: '042110',
            barres: 1
        },
        {
            frets: '446554',
            fingers: '114231',
            barres: 4,
            capo: true
        },
        {
            frets: '9ba999',
            fingers: '132111',
            barres: 9,
            capo: true
        },
        {
            frets: 'xbbddc',
            fingers: '011342',
            barres: 11,
            capo: true
        }
    ]
};

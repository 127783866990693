export default {
    key: 'Gb',
    suffix: '7',
    positions: [
        {
            frets: '3424',
            fingers: '2314'
        },
        {
            frets: '6667',
            fingers: '1112',
            barres: 6,
            capo: true
        },
        {
            frets: '9a99',
            fingers: '1211',
            barres: 9,
            capo: true
        },
        {
            frets: 'bdcd',
            fingers: '1324'
        }
    ]
};

export default {
    key: 'C',
    suffix: '7sus4',
    positions: [
        {
            frets: '0011',
            fingers: '0012'
        },
        {
            frets: '3533',
            fingers: '1311',
            barres: 3,
            capo: true
        },
        {
            frets: '5768',
            fingers: '1324'
        },
        {
            frets: 'aa8a',
            fingers: '2314'
        }
    ]
};

export default {
    key: 'C',
    suffix: '9#11',
    positions: [
        {
            frets: 'x32332',
            fingers: '021341',
            barres: 2,
            capo: true
        },
        {
            frets: 'x34353',
            fingers: '012131',
            barres: 3,
            capo: true
        },
        {
            frets: '878778',
            fingers: '213114',
            barres: 7,
            capo: true
        },
        {
            frets: 'xxabbc',
            fingers: '001234'
        }
    ]
};

export default {
    key: 'A',
    suffix: 'mmaj7',
    positions: [
        {
            frets: '1000',
            fingers: '1000'
        },
        {
            frets: '2443',
            fingers: '1342'
        },
        {
            frets: '5857',
            fingers: '1413',
            barres: 5,
            capo: true
        },
        {
            frets: '998b',
            fingers: '2214',
            barres: 9
        }
    ]
};

export default {
    key: 'G',
    suffix: 'maj13',
    positions: [
        {
            frets: '2422',
            fingers: '1311',
            barres: 2,
            capo: true
        },
        {
            frets: '4657',
            fingers: '1324'
        },
        {
            frets: '9979',
            fingers: '2314'
        },
        {
            frets: 'bbcc',
            fingers: '1122',
            barres: [11, 12],
            capo: true
        }
    ]
};

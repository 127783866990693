export default {
    key: 'F#',
    suffix: 'maj9',
    positions: [
        {
            frets: '213121',
            fingers: '214131',
            barres: 1,
            capo: true
        },
        {
            frets: '2x3324',
            fingers: '102314',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4364',
            fingers: '002143'
        },
        {
            frets: '998a9x',
            fingers: '221430',
            barres: 9
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '365433',
            fingers: '143211',
            barres: 3
        },
        {
            frets: 'x657x7',
            fingers: '021304'
        },
        {
            frets: 'x6978x',
            fingers: '014230'
        },
        {
            frets: 'bxcccb',
            fingers: '102341',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'maj11',
    positions: [
        {
            frets: 'xx0022',
            fingers: '000011',
            barres: 2
        },
        {
            frets: '555675',
            fingers: '111231',
            barres: 5,
            capo: true
        },
        {
            frets: 'x90789',
            fingers: '030124'
        },
        {
            frets: 'aabbaa',
            fingers: '112311',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: '69',
    positions: [
        {
            frets: 'x54200',
            fingers: '043100'
        },
        {
            frets: 'x54455',
            fingers: '021134',
            barres: 4
        },
        {
            frets: 'a999aa',
            fingers: '211134',
            barres: 9,
            capo: true
        },
        {
            frets: 'xccbcc',
            fingers: '022134',
            barres: 12
        }
    ]
};

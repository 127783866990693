export default {
    key: 'G',
    suffix: 'sus4',
    positions: [
        {
            frets: '0233',
            fingers: '0123'
        },
        {
            frets: '5233',
            fingers: '4122',
            barres: 3
        },
        {
            frets: '5235',
            fingers: '3124'
        },
        {
            frets: '5785',
            fingers: '1341',
            barres: 5,
            capo: true
        }
    ]
};

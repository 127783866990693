export default {
    key: 'F#',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '223224',
            fingers: '112114',
            barres: 2,
            capo: true
        },
        {
            frets: 'x44465',
            fingers: '011132',
            barres: 4,
            capo: true
        },
        {
            frets: 'x97a97',
            fingers: '021431',
            barres: 7,
            capo: true
        },
        {
            frets: '999aa9',
            fingers: '111231',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'sus4',
    positions: [
        {
            frets: '2200',
            fingers: '2300'
        },
        {
            frets: '2455',
            fingers: '1234'
        },
        {
            frets: '7455',
            fingers: '4122',
            barres: 5
        },
        {
            frets: '7457',
            fingers: '3124'
        }
    ]
};

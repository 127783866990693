export default {
    key: 'D',
    suffix: '9',
    positions: [
        {
            frets: '2423',
            fingers: '1312',
            barres: 2,
            capo: true
        },
        {
            frets: '5657',
            fingers: '1213',
            barres: 5,
            capo: true
        },
        {
            frets: '9989',
            fingers: '2314'
        },
        {
            frets: 'bccc',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'm7b5',
    positions: [
        {
            frets: '1222',
            fingers: '1234'
        },
        {
            frets: '4645',
            fingers: '1312',
            barres: 4,
            capo: true
        },
        {
            frets: '7879',
            fingers: '1213',
            barres: 7,
            capo: true
        },
        {
            frets: 'bbab',
            fingers: '2314'
        }
    ]
};

export default {
    key: 'C',
    suffix: 'maj7#5',
    positions: [
        {
            frets: 'x32100',
            fingers: '032100'
        },
        {
            frets: 'x36450',
            fingers: '014230'
        },
        {
            frets: '876500',
            fingers: '432100'
        },
        {
            frets: '8ba900',
            fingers: '143200'
        }
    ]
};

export default {
    key: 'E',
    suffix: '7#9',
    positions: [
        {
            frets: '020103',
            fingers: '020104'
        },
        {
            frets: '050034',
            fingers: '030012'
        },
        {
            frets: 'x7678x',
            fingers: '021340'
        },
        {
            frets: '0a999a',
            fingers: '021113',
            barres: 9
        }
    ]
};

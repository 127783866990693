export default {
    key: 'B',
    suffix: 'maj13',
    positions: [
        {
            frets: 'x22344',
            fingers: '011234',
            barres: 2,
            capo: true
        },
        {
            frets: '766676',
            fingers: '211131',
            barres: 6,
            capo: true
        },
        {
            frets: '778897',
            fingers: '112341',
            barres: 7,
            capo: true
        },
        {
            frets: 'xeddbb',
            fingers: '042311',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Db',
    suffix: 'maj11',
    positions: [
        {
            frets: '8688',
            fingers: '2134'
        },
        {
            frets: 'acb9',
            fingers: '2431'
        }
    ]
};

export default {
    key: 'E',
    suffix: '7b5',
    positions: [
        {
            frets: '010130',
            fingers: '010240'
        },
        {
            frets: 'xx2334',
            fingers: '001234'
        },
        {
            frets: 'xx6756',
            fingers: '002413'
        },
        {
            frets: 'x7879x',
            fingers: '012130',
            barres: 7,
            capo: true
        }
    ]
};

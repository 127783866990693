export default {
    key: 'Eb',
    suffix: '13b5b9',
    positions: [
        {
            frets: '2103',
            fingers: '2103'
        },
        {
            frets: '5454',
            fingers: '3142'
        },
        {
            frets: '6453',
            fingers: '4231'
        },
        {
            frets: '6987',
            fingers: '1432'
        }
    ]
};

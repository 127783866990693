export default {
    key: 'D',
    suffix: 'm69',
    positions: [
        {
            frets: '2412',
            fingers: '2413'
        },
        {
            frets: '4557',
            fingers: '1234'
        },
        {
            frets: '9978',
            fingers: '3412'
        },
        {
            frets: 'a977',
            fingers: '4311',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'Db',
    suffix: '13b9',
    positions: [
        {
            frets: '4211',
            fingers: '4211',
            barres: 1,
            capo: true
        },
        {
            frets: '3212',
            fingers: '4213'
        },
        {
            frets: '4565',
            fingers: '1243'
        },
        {
            frets: '7a78',
            fingers: '1412',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: 'maj11',
    positions: [
        {
            frets: '3304',
            fingers: '1203'
        },
        {
            frets: '6466',
            fingers: '2134'
        },
        {
            frets: '8a97',
            fingers: '2431'
        }
    ]
};

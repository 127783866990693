export default {
    key: 'Gb',
    suffix: 'aug9',
    positions: [
        {
            frets: '1201',
            fingers: '1302'
        },
        {
            frets: '3445',
            fingers: '1223',
            barres: 4
        },
        {
            frets: '7867',
            fingers: '2413'
        },
        {
            frets: '9aab',
            fingers: '1234'
        }
    ]
};

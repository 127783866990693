export default {
    key: 'Bb',
    suffix: 'm9',
    positions: [
        {
            frets: '1113',
            fingers: '1113',
            barres: 1,
            capo: true
        },
        {
            frets: '5544',
            fingers: '2211',
            barres: [4, 5],
            capo: true
        },
        {
            frets: '6543',
            fingers: '4321'
        },
        {
            frets: '6888',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '11',
    positions: [
        {
            frets: '111123',
            fingers: '111123',
            barres: 1,
            capo: true
        },
        {
            frets: 'x65644',
            fingers: '032411',
            barres: 4,
            capo: true
        },
        {
            frets: '066686',
            fingers: '011131',
            barres: 6,
            capo: true
        },
        {
            frets: 'bbbcbb',
            fingers: '111211',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'add9',
    positions: [
        {
            frets: 'x65363',
            fingers: '032141',
            barres: 3,
            capo: true
        },
        {
            frets: 'x65066',
            fingers: '021034'
        },
        {
            frets: 'baxabx',
            fingers: '310240'
        },
        {
            frets: 'xxdcbd',
            fingers: '003214'
        }
    ]
};

export default {
    key: 'Db',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '1303',
            fingers: '1203'
        },
        {
            frets: '5446',
            fingers: '2113',
            barres: 4,
            capo: true
        },
        {
            frets: '9886',
            fingers: '4231'
        },
        {
            frets: '8887',
            fingers: '2341'
        }
    ]
};

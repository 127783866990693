export default {
    key: 'D',
    suffix: 'maj7b5',
    positions: [
        {
            frets: 'xx0122',
            fingers: '000123'
        },
        {
            frets: 'x5667x',
            fingers: '012340'
        },
        {
            frets: 'a9bb99',
            fingers: '213411',
            barres: 9,
            capo: true
        },
        {
            frets: 'abbbxa',
            fingers: '123401',
            barres: 10,
            capo: true
        }
    ]
};

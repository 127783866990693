export default {
    key: 'Ab',
    suffix: '7',
    positions: [
        {
            frets: 'xx1112',
            fingers: '001112',
            barres: 1,
            capo: true
        },
        {
            frets: '464544',
            fingers: '131211',
            barres: 4,
            capo: true
        },
        {
            frets: 'x66878',
            fingers: '011324',
            barres: 6,
            capo: true
        },
        {
            frets: 'bbdbdb',
            fingers: '113141',
            barres: 11,
            capo: true
        }
    ]
};

import { useState, useEffect, ReactElement } from 'react'

interface QueryBuilderProps<T> {
  query: firebase.firestore.Query<T>
  renderError: (err: Error) => ReactElement
  renderLoading: () => ReactElement
  renderItems: (items: T[]) => ReactElement
}

function QueryBuilder<T>(props: QueryBuilderProps<T>) {
  const [snapshot, setSnapshot] = useState<firebase.firestore.QuerySnapshot<T> | null | Error>(null);

  useEffect(() => {
    return props.query.onSnapshot(snapshot => {
      if (snapshot) setSnapshot(snapshot);
    }, err => {
      setSnapshot(err);
    });
  }, [props.query]);

  if (snapshot) {
    if (snapshot instanceof Error) {
      return props.renderError(snapshot);
    }
    return props.renderItems(snapshot.docs.map((doc) => ({ 
      ...doc.data(),
      id: doc.id,
    }) as T));
  } else {
    return props.renderLoading();
  }
};

export default QueryBuilder;
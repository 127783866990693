export default {
    key: 'Db',
    suffix: 'm11',
    positions: [
        {
            frets: '8677',
            fingers: '3122',
            barres: 7
        },
        {
            frets: '9676',
            fingers: '4121',
            barres: 6,
            capo: true
        },
        {
            frets: '9bb9',
            fingers: '1341',
            barres: 9,
            capo: true
        }
    ]
};

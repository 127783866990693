
import React from 'react'
import { Button, Menu } from 'antd';
import { useAuth } from '../../hooks/Auth';
import Authenticated from './Authenticated';

interface SignOutButtonProps {
  type?: 'button' | 'menu-item'
}

const SignOutButton: React.FC<SignOutButtonProps> = (props) => {
  const auth = useAuth();
  const type = props.type ? props.type : 'button';

  switch (type) {
    case 'button':
      return (
        <Authenticated>
          <Button onClick={auth.signOut}>Sign Out</Button>
        </Authenticated>
      );
    case 'menu-item':
      return (
        <Authenticated>
          <Menu.Item onClick={auth.signOut} title='Sign Out'/>
        </Authenticated>
      )
  }
};

export default SignOutButton;
export default {
    key: 'F#',
    suffix: 'maj13',
    positions: [
        {
            frets: '211121',
            fingers: '211131',
            barres: 1,
            capo: true
        },
        {
            frets: 'x64466',
            fingers: '021134',
            barres: 4,
            capo: true
        },
        {
            frets: 'x98866',
            fingers: '042311',
            barres: 6
        },
        {
            frets: 'x99abb',
            fingers: '011234',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'minor',
    positions: [
        {
            frets: '466444',
            fingers: '134111',
            barres: 4,
            capo: true
        },
        {
            frets: 'xx6897',
            fingers: '001342'
        },
        {
            frets: 'xx9897',
            fingers: '003241'
        },
        {
            frets: 'bbddcb',
            fingers: '113421',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'sus4',
    positions: [
        {
            frets: '1341',
            fingers: '1231',
            barres: 1,
            capo: true
        },
        {
            frets: '3341',
            fingers: '2341'
        },
        {
            frets: '3346',
            fingers: '1124',
            barres: 3,
            capo: true
        },
        {
            frets: '8866',
            fingers: '3411',
            barres: 6,
            capo: true
        }
    ]
};

export default {
    key: 'C#',
    suffix: '13',
    positions: [
        {
            frets: 'x43302',
            fingers: '042301'
        },
        {
            frets: '444466',
            fingers: '111134',
            barres: 4,
            capo: true
        },
        {
            frets: '988897',
            fingers: '322241',
            barres: 8
        },
        {
            frets: '999abb',
            fingers: '111234',
            barres: 9,
            capo: true
        }
    ]
};

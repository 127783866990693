export default {
    key: 'C',
    suffix: 'mmaj9',
    positions: [
        {
            frets: 'x3143x',
            fingers: '021430'
        },
        {
            frets: 'x30443',
            fingers: '010342'
        },
        {
            frets: '86x708',
            fingers: '310204'
        },
        {
            frets: '8a988a',
            fingers: '132114',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'dim7',
    positions: [
        {
            frets: 'x12020',
            fingers: '012030'
        },
        {
            frets: 'xx2323',
            fingers: '001324'
        },
        {
            frets: '678686',
            fingers: '123141',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx8989',
            fingers: '001324'
        }
    ]
};

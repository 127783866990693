export default {
    key: 'Gb',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '3525',
            fingers: '2314'
        },
        {
            frets: '7668',
            fingers: '2113',
            barres: 6,
            capo: true
        },
        {
            frets: 'baa8',
            fingers: '4231'
        },
        {
            frets: 'aaa9',
            fingers: '2341'
        }
    ]
};

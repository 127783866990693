export default {
    key: 'A',
    suffix: 'aug7',
    positions: [
        {
            frets: 'x03021',
            fingers: '003021'
        },
        {
            frets: 'x03223',
            fingers: '002113',
            barres: 2
        },
        {
            frets: 'x05665',
            fingers: '001231',
            barres: 5,
            capo: true
        },
        {
            frets: 'xx7a89',
            fingers: '001423'
        }
    ]
};

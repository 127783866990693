export default {
    key: 'C',
    suffix: '13b5b9',
    positions: [
        {
            frets: '2121',
            fingers: '3142'
        },
        {
            frets: '3120',
            fingers: '3120'
        },
        {
            frets: '3654',
            fingers: '1432'
        }
    ]
};

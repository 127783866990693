export default {
    key: 'D',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: '1214',
            fingers: '1214',
            barres: 1,
            capo: true
        },
        {
            frets: '6545',
            fingers: '4213'
        },
        {
            frets: '7544',
            fingers: '4211',
            barres: 4,
            capo: true
        },
        {
            frets: '7898',
            fingers: '1243'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'major',
    positions: [
        {
            frets: '224442',
            fingers: '112341',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4447',
            fingers: '001114',
            barres: 4,
            capo: true
        },
        {
            frets: '799877',
            fingers: '134211',
            barres: 7,
            capo: true
        },
        {
            frets: 'x99bcb',
            fingers: '011243',
            barres: 9,
            capo: true
        }
    ]
};

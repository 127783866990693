export default {
    key: 'D',
    suffix: '6',
    positions: [
        {
            frets: '2222',
            fingers: '1111',
            barres: 2,
            capo: true
        },
        {
            frets: '4655',
            fingers: '1423'
        },
        {
            frets: '7979',
            fingers: '1324'
        },
        {
            frets: 'bbac',
            fingers: '2213',
            barres: 11
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: '4554x4',
            fingers: '123101',
            barres: 4,
            capo: true
        },
        {
            frets: 'xx6787',
            fingers: '001243'
        },
        {
            frets: 'abcccx',
            fingers: '123330',
            barres: 12
        },
        {
            frets: 'xbcccx',
            fingers: '012340'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: '9#11',
    positions: [
        {
            frets: '3223',
            fingers: '2113',
            barres: 2,
            capo: true
        },
        {
            frets: '5665',
            fingers: '1221',
            barres: [5, 6],
            capo: true
        },
        {
            frets: '7a89',
            fingers: '1423'
        },
        {
            frets: 'bcad',
            fingers: '2314'
        }
    ]
};

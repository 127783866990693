export default {
    key: 'E',
    suffix: '11',
    positions: [
        {
            frets: '000100',
            fingers: '000100'
        },
        {
            frets: '004434',
            fingers: '002314'
        },
        {
            frets: 'x76755',
            fingers: '032411',
            barres: 5,
            capo: true
        },
        {
            frets: 'x77797',
            fingers: '011131',
            barres: 7,
            capo: true
        }
    ]
};

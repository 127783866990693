export default {
    key: 'Eb',
    suffix: '7b5',
    positions: [
        {
            frets: '2334',
            fingers: '1234'
        },
        {
            frets: '6756',
            fingers: '2413'
        },
        {
            frets: '899a',
            fingers: '1234'
        },
        {
            frets: 'cdbc',
            fingers: '2413'
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'm7b5',
    positions: [
        {
            frets: '202210',
            fingers: '203410'
        },
        {
            frets: 'xx4555',
            fingers: '001222',
            barres: 5
        },
        {
            frets: 'x9a9ax',
            fingers: '013240'
        },
        {
            frets: 'xxabac',
            fingers: '001214',
            barres: 10,
            capo: true
        }
    ]
};

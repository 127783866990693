export default {
    key: 'Bb',
    suffix: '13',
    positions: [
        {
            frets: 'x10133',
            fingers: '010244',
            barres: 3
        },
        {
            frets: '650044',
            fingers: '320011',
            barres: 4
        },
        {
            frets: '666788',
            fingers: '111234',
            barres: 6,
            capo: true
        },
        {
            frets: 'xdcdff',
            fingers: '021344',
            barres: 15
        }
    ]
};

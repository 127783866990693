export default {
    key: 'E',
    suffix: 'aug',
    positions: [
        {
            frets: '032110',
            fingers: '043120'
        },
        {
            frets: 'x7655x',
            fingers: '032110',
            barres: 5,
            capo: true
        },
        {
            frets: 'x7a99x',
            fingers: '432110'
        },
        {
            frets: 'cba990',
            fingers: '432110',
            barres: 9
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'maj11',
    positions: [
        {
            frets: 'x11231',
            fingers: '011231',
            barres: 1,
            capo: true
        },
        {
            frets: '6x0545',
            fingers: '400213'
        },
        {
            frets: '667766',
            fingers: '112311',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx88aa',
            fingers: '001134',
            barres: 8,
            capo: true
        }
    ]
};

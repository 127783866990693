export default {
    key: 'F',
    suffix: 'aug7',
    positions: [
        {
            frets: '10122x',
            fingers: '102340'
        },
        {
            frets: 'xx3645',
            fingers: '001423'
        },
        {
            frets: 'x8b8a9',
            fingers: '014132',
            barres: 8,
            capo: true
        },
        {
            frets: 'dcdxex',
            fingers: '213040'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'add9',
    positions: [
        {
            frets: 'x54252',
            fingers: '032141',
            barres: 2
        },
        {
            frets: 'x57770',
            fingers: '012340'
        },
        {
            frets: 'xx097a',
            fingers: '000314'
        },
        {
            frets: 'xxcbac',
            fingers: '003214'
        }
    ]
};

import React, { ReactElement, useState, useEffect } from 'react'
import firebase from 'firebase';

interface DocumentSnapshotBuilderProps<T> {
  snapshot: firebase.firestore.DocumentSnapshot<T>
  renderValue: (value: T) => ReactElement
  renderNull: () => ReactElement
}

function DocumentSnapshotBuilder<T>(props: DocumentSnapshotBuilderProps<T>) {
  if (props.snapshot.exists) {
    return props.renderValue(props.snapshot.data() as T);
  }
  return props.renderNull();
}

interface DocumentReferenceBuilderProps<T> {
  docRef: firebase.firestore.DocumentReference<T>
  renderValue: (value: T) => ReactElement
  renderLoading: () => ReactElement
  renderError: (err: Error) => ReactElement
}

function DocumentReferenceBuilder<T>(props: DocumentReferenceBuilderProps<T>) {
  const [snapshot, setSnapshot] = useState<firebase.firestore.DocumentSnapshot<T> | null | Error>(null);

  useEffect(() => {
    return props.docRef.onSnapshot(snapshot => {
      setSnapshot(snapshot);
    }, err => {
      setSnapshot(err);
    });
  }, [props.docRef]);

  if (snapshot) {
    if (snapshot instanceof Error) {
      return props.renderError(snapshot);
    }
    return (
      <DocumentSnapshotBuilder 
        snapshot={snapshot}
        renderValue={props.renderValue}
        renderNull={() => props.renderError(new Error('Document does not exist'))}
        />
    );
  } else {
    return props.renderLoading();
  }
};

export default DocumentReferenceBuilder;
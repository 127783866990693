export default {
    key: 'C',
    suffix: 'maj13',
    positions: [
        {
            frets: '2202',
            fingers: '1203'
        },
        {
            frets: '4455',
            fingers: '1122',
            barres: [4, 5],
            capo: true
        },
        {
            frets: '7977',
            fingers: '1311',
            barres: 7,
            capo: true
        },
        {
            frets: '9bac',
            fingers: '1324'
        }
    ]
};

export default {
    key: 'A',
    suffix: '11',
    positions: [
        {
            frets: 'x00020',
            fingers: '000020'
        },
        {
            frets: '540003',
            fingers: '320001'
        },
        {
            frets: '555655',
            fingers: '111211',
            barres: 5,
            capo: true
        },
        {
            frets: '777789',
            fingers: '111123',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: '69',
    positions: [
        {
            frets: 'x10011',
            fingers: '010023'
        },
        {
            frets: '655566',
            fingers: '211134',
            barres: 5,
            capo: true
        },
        {
            frets: 'x88788',
            fingers: '022134',
            barres: 8
        },
        {
            frets: 'xdccdd',
            fingers: '021134',
            barres: 12,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'maj11',
    positions: [
        {
            frets: '4244',
            fingers: '2134'
        },
        {
            frets: '6875',
            fingers: '2431'
        }
    ]
};

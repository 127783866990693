export default {
    key: 'Bb',
    suffix: 'alt',
    positions: [
        {
            frets: '3201',
            fingers: '3201'
        },
        {
            frets: '3465',
            fingers: '1243'
        },
        {
            frets: '7465',
            fingers: '4132'
        },
        {
            frets: '7aa7',
            fingers: '1341',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: '7b9',
    positions: [
        {
            frets: '1212',
            fingers: '1324'
        },
        {
            frets: '4545',
            fingers: '1324'
        },
        {
            frets: '7878',
            fingers: '1324'
        },
        {
            frets: 'abab',
            fingers: '1324'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'major',
    positions: [
        {
            frets: 'xx1343',
            fingers: '001243'
        },
        {
            frets: 'x65343',
            fingers: '043121',
            barres: 3
        },
        {
            frets: 'x68886',
            fingers: '012341',
            barres: 6,
            capo: true
        },
        {
            frets: 'xx888b',
            fingers: '001114',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'mmaj7',
    positions: [
        {
            frets: '021000',
            fingers: '021000'
        },
        {
            frets: '022443',
            fingers: '011342',
            barres: 2
        },
        {
            frets: 'x7544x',
            fingers: '042110',
            barres: 4,
            capo: true
        },
        {
            frets: '779887',
            fingers: '114231',
            barres: 7,
            capo: true
        }
    ]
};

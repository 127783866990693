export default {
    key: 'G',
    suffix: 'maj7',
    positions: [
        {
            frets: '320002',
            fingers: '320001'
        },
        {
            frets: '354433',
            fingers: '142311',
            barres: 3,
            capo: true
        },
        {
            frets: 'x55777',
            fingers: '011333',
            barres: [5, 7],
            capo: true
        },
        {
            frets: 'xacbca',
            fingers: '013241',
            barres: 10,
            capo: true
        }
    ]
};

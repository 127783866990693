export default {
    key: 'D',
    suffix: 'minor',
    positions: [
        {
            frets: 'xx0231',
            fingers: '000231'
        },
        {
            frets: '557765',
            fingers: '113421',
            barres: 5,
            capo: true
        },
        {
            frets: 'x8776x',
            fingers: '042310'
        },
        {
            frets: 'accaaa',
            fingers: '134111',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: 'dim7',
    positions: [
        {
            frets: 'x01212',
            fingers: '001324'
        },
        {
            frets: '5x454x',
            fingers: '201310',
            barres: 4,
            capo: true
        },
        {
            frets: '567575',
            fingers: '123141',
            barres: 5,
            capo: true
        },
        {
            frets: 'x07878',
            fingers: '001324',
            barres: 7
        }
    ]
};

export default {
    key: 'B',
    suffix: 'aug',
    positions: [
        {
            frets: 'x2100x',
            fingers: '021000'
        },
        {
            frets: 'xx5443',
            fingers: '004231'
        },
        {
            frets: '7x988x',
            fingers: '104230'
        },
        {
            frets: 'xedcc0',
            fingers: '032110',
            barres: 12,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: '/F',
    positions: [
        {
            frets: '122100',
            fingers: '134200'
        },
        {
            frets: 'xx3100',
            fingers: '003100'
        },
        {
            frets: 'xx3454',
            fingers: '001243'
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'mmaj7',
    positions: [
        {
            frets: '2524',
            fingers: '1413',
            barres: 2,
            capo: true
        },
        {
            frets: '6658',
            fingers: '2214',
            barres: 6
        },
        {
            frets: 'b998',
            fingers: '4221',
            barres: 9
        },
        {
            frets: 'a999',
            fingers: '2111',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm69',
    positions: [
        {
            frets: '022022',
            fingers: '011023',
            barres: 2
        },
        {
            frets: '3x2422',
            fingers: '201311',
            barres: 2
        },
        {
            frets: 'x75677',
            fingers: '031244',
            barres: 7
        },
        {
            frets: '0abbc0',
            fingers: '012340'
        }
    ]
};

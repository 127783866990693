export default {
    key: 'C',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '7576',
            fingers: '3142'
        },
        {
            frets: '8575',
            fingers: '4131',
            barres: 5,
            capo: true
        },
        {
            frets: '8ba8',
            fingers: '1431',
            barres: 8,
            capo: true
        }
    ]
};

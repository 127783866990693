export default {
    key: 'G',
    suffix: '7sus4',
    positions: [
        {
            frets: '0213',
            fingers: '0213'
        },
        {
            frets: '5535',
            fingers: '2314'
        },
        {
            frets: '7788',
            fingers: '1122',
            barres: [7, 7],
            capo: true
        },
        {
            frets: 'acaa',
            fingers: '1311',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'aug7',
    positions: [
        {
            frets: 'x14132',
            fingers: '014132',
            barres: 1,
            capo: true
        },
        {
            frets: '6x677x',
            fingers: '102340'
        },
        {
            frets: 'xx8b9a',
            fingers: '001423'
        },
        {
            frets: 'xdcdxe',
            fingers: '021304'
        }
    ]
};

export default {
    key: 'G',
    suffix: '13',
    positions: [
        {
            frets: '302001',
            fingers: '302001'
        },
        {
            frets: '3x3455',
            fingers: '101234',
            barres: 3,
            capo: true
        },
        {
            frets: '353453',
            fingers: '131241',
            barres: 3,
            capo: true
        },
        {
            frets: 'xa9aac',
            fingers: '021334',
            barres: 10
        }
    ]
};

export default {
    key: 'F',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: '122100',
            fingers: '123100',
            barres: 1
        },
        {
            frets: 'xx3454',
            fingers: '001243'
        },
        {
            frets: '78999x',
            fingers: '123330',
            barres: 9
        },
        {
            frets: 'x8999x',
            fingers: '012340'
        }
    ]
};

export default {
    key: 'Db',
    suffix: 'aug9',
    positions: [
        {
            frets: '2312',
            fingers: '2413'
        },
        {
            frets: '4310',
            fingers: '3210'
        },
        {
            frets: '4556',
            fingers: '1234'
        },
        {
            frets: '8978',
            fingers: '2413'
        }
    ]
};

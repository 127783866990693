import React from 'react';
import { createEditorPlugin, DraftDecoratorFactory } from "./EditorPlugin";
import { forEachEnclosedSubstr } from '@chordpad/common-ts/dist/strings';

const HandleSpan: React.FC = ({children}) => {
  return (
    <span style={{color: 'rgb(20, 100, 20)'}}>{children}</span>
  );
}

const commentDecorator: DraftDecoratorFactory = (props) => {
  return {
    strategy: (block, callback) => {
      const text = block.getText();
      let offset = 0;
      while (offset >= 0 && offset < text.length) {
        const start = text.indexOf('#', offset);
        const end = text.indexOf('\n', start);
        if (start >= 0) {
          if (end >= 0) {
            callback(start, end);
            offset = end;
          } else {
            callback(start, text.length);
            offset = -1;
          }
        } else {
          offset = -1;
        }
      }
    },
    component: HandleSpan,
  };
};

export const commentPlugin = createEditorPlugin({
  decorators: [ commentDecorator ]
}, {});
export default {
    key: 'A',
    suffix: 'm69',
    positions: [
        {
            frets: 'x04500',
            fingers: '002400'
        },
        {
            frets: '577577',
            fingers: '122134',
            barres: [5, 7],
            capo: true
        },
        {
            frets: '807977',
            fingers: '201311',
            barres: 7,
            capo: true
        },
        {
            frets: 'xcabcc',
            fingers: '031244',
            barres: 12
        }
    ]
};

export default {
    key: 'A',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '4243',
            fingers: '3142'
        },
        {
            frets: '5242',
            fingers: '4131',
            barres: 2,
            capo: true
        },
        {
            frets: '5875',
            fingers: '1431',
            barres: 5,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'aug9',
    positions: [
        {
            frets: '3423',
            fingers: '2413'
        },
        {
            frets: '5667',
            fingers: '1234'
        },
        {
            frets: '9a89',
            fingers: '2413'
        }
    ]
};

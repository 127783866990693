export default {
    key: 'F',
    suffix: 'maj9',
    positions: [
        {
            frets: '102010',
            fingers: '103020'
        },
        {
            frets: 'xx2213',
            fingers: '002314'
        },
        {
            frets: 'x8798x',
            fingers: '021430'
        },
        {
            frets: 'xxacac',
            fingers: '001314',
            barres: 10,
            capo: true
        }
    ]
};

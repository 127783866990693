import { createEditorPlugin } from "./EditorPlugin";
import { EditorState } from "draft-js";
import { replaceSelection } from "../../EditorUtils";

export const replaceWithSynonymEditorPlugin = createEditorPlugin({
  command: 'replace-with-synonym',
  onCommandTriggered: (state) => {
    return state;
  },
  onResultReceived: (result: string, state: EditorState) => {
    if (!result || result === '') return undefined;
    const newState = replaceSelection(state, result);
    return newState;
  }
}, {});
export default {
    key: 'D',
    suffix: 'sus4',
    positions: [
        {
            frets: 'xx0233',
            fingers: '000123'
        },
        {
            frets: 'x50035',
            fingers: '030014'
        },
        {
            frets: '557785',
            fingers: '112341',
            barres: 5,
            capo: true
        },
        {
            frets: 'acccaa',
            fingers: '123411',
            barres: 10,
            capo: true
        }
    ]
};

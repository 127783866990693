export default {
    key: 'E',
    suffix: '7sus4',
    positions: [
        {
            frets: '020200',
            fingers: '020300'
        },
        {
            frets: 'x77755',
            fingers: '023411',
            barres: 5,
            capo: true
        },
        {
            frets: '7797a7',
            fingers: '113141',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx99aa',
            fingers: '001123',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'major',
    positions: [
        {
            frets: '3121',
            fingers: '3121',
            barres: 1,
            capo: true
        },
        {
            frets: '3124',
            fingers: '3124'
        },
        {
            frets: '6669',
            fingers: '1114',
            barres: 6,
            capo: true
        },
        {
            frets: 'ba99',
            fingers: '3211',
            barres: 9,
            capo: true
        }
    ]
};

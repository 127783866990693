export default {
    key: 'E',
    suffix: '69',
    positions: [
        {
            frets: '022122',
            fingers: '022134',
            barres: 2
        },
        {
            frets: 'x76677',
            fingers: '021134',
            barres: 6,
            capo: true
        },
        {
            frets: 'x99999',
            fingers: '011111',
            barres: 9,
            capo: true
        },
        {
            frets: '0bbbcc',
            fingers: '011134',
            barres: 11
        }
    ]
};

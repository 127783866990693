export default {
    key: 'Db',
    suffix: '13b5b9',
    positions: [
        {
            frets: '4231',
            fingers: '4231'
        },
        {
            frets: '3232',
            fingers: '3142'
        },
        {
            frets: '4765',
            fingers: '1432'
        }
    ]
};

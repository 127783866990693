export default {
    key: 'G',
    suffix: '69',
    positions: [
        {
            frets: '300000',
            fingers: '100000'
        },
        {
            frets: '322233',
            fingers: '211134',
            barres: 2,
            capo: true
        },
        {
            frets: 'x55455',
            fingers: '022134',
            barres: 5,
            capo: true
        },
        {
            frets: 'xa99aa',
            fingers: '021134',
            barres: 9,
            capo: true
        }
    ]
};

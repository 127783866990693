export default {
    key: 'B',
    suffix: 'mmaj11',
    positions: [
        {
            frets: '6465',
            fingers: '3142'
        },
        {
            frets: '7464',
            fingers: '4131',
            barres: 4,
            capo: true
        },
        {
            frets: '7a97',
            fingers: '1431',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: 'dim',
    positions: [
        {
            frets: '4542',
            fingers: '2431'
        },
        {
            frets: 'a878',
            fingers: '4213'
        },
        {
            frets: 'abdb',
            fingers: '1243'
        },
        {
            frets: 'dbdb',
            fingers: '3141',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: '9#11',
    positions: [
        {
            frets: '1203',
            fingers: '1203'
        },
        {
            frets: '5445',
            fingers: '2113',
            barres: 4,
            capo: true
        },
        {
            frets: '7887',
            fingers: '1221',
            barres: [7, 8],
            capo: true
        },
        {
            frets: '9cab',
            fingers: '1423'
        }
    ]
};

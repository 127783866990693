export default {
    key: 'C',
    suffix: '9#11',
    positions: [
        {
            frets: '3425',
            fingers: '2314'
        },
        {
            frets: '7667',
            fingers: '2113',
            barres: 6,
            capo: true
        },
        {
            frets: '9aa9',
            fingers: '1221',
            barres: [9, 10],
            capo: true
        },
        {
            frets: 'becd',
            fingers: '1423'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'maj11',
    positions: [
        {
            frets: 'a8aa',
            fingers: '2134'
        },
        {
            frets: 'cedb',
            fingers: '2431'
        }
    ]
};

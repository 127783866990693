export default {
    key: 'F',
    suffix: 'b13#9',
    positions: [
        {
            frets: '2344',
            fingers: '1234'
        },
        {
            frets: '6856',
            fingers: '2413'
        },
        {
            frets: '899b',
            fingers: '1234'
        },
        {
            frets: 'edbb',
            fingers: '4311',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: 'm7b5',
    positions: [
        {
            frets: '3323',
            fingers: '2314'
        },
        {
            frets: '5666',
            fingers: '1234'
        },
        {
            frets: '8a89',
            fingers: '1312',
            barres: 8,
            capo: true
        },
        {
            frets: 'bcbd',
            fingers: '1213',
            barres: 11,
            capo: true
        }
    ]
};

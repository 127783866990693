export default {
    key: 'F#',
    suffix: '6',
    positions: [
        {
            frets: '2x132x',
            fingers: '201430'
        },
        {
            frets: '2x4342',
            fingers: '103241',
            barres: 2,
            capo: true
        },
        {
            frets: 'x44646',
            fingers: '011314',
            barres: 4,
            capo: true
        },
        {
            frets: 'x9bbbb',
            fingers: '013333',
            barres: 11
        }
    ]
};

import A from './A';
import Ab from './Ab';
import B from './B';
import Bb from './Bb';
import C from './C';
import D from './D';
import Db from './Db';
import E from './E';
import Eb from './Eb';
import F from './F';
import G from './G';
import Gb from './Gb';
var chords = { A: A, Ab: Ab, B: B, Bb: Bb, C: C, D: D, Db: Db, E: E, Eb: Eb, F: F, G: G, Gb: Gb };
export default chords;

export default {
    key: 'Ab',
    suffix: 'maj7',
    positions: [
        {
            frets: '465544',
            fingers: '142311',
            barres: 4,
            capo: true
        },
        {
            frets: 'x66888',
            fingers: '011333',
            barres: 6,
            capo: true
        },
        {
            frets: 'xbac9x',
            fingers: '032410'
        },
        {
            frets: 'bbdcdb',
            fingers: '113241',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'm9',
    positions: [
        {
            frets: '202120',
            fingers: '203140'
        },
        {
            frets: '242224',
            fingers: '121113',
            barres: 2,
            capo: true
        },
        {
            frets: 'x97999',
            fingers: '021344',
            barres: 9
        },
        {
            frets: 'xxbdac',
            fingers: '002413'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'm11',
    positions: [
        {
            frets: '3122',
            fingers: '3122',
            barres: 2
        },
        {
            frets: '4121',
            fingers: '4121',
            barres: 1,
            capo: true
        },
        {
            frets: '4664',
            fingers: '1341',
            barres: 4,
            capo: true
        }
    ]
};

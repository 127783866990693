export default {
    key: 'Bb',
    suffix: 'mmaj7',
    positions: [
        {
            frets: 'x13221',
            fingers: '014231',
            barres: 1,
            capo: true
        },
        {
            frets: '687666',
            fingers: '132111',
            barres: 6,
            capo: true
        },
        {
            frets: 'x88aa9',
            fingers: '011342',
            barres: 8,
            capo: true
        },
        {
            frets: 'xdbaax',
            fingers: '042110',
            barres: 10,
            capo: true
        }
    ]
};

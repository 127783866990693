export default {
    key: 'F',
    suffix: 'm69',
    positions: [
        {
            frets: '1233',
            fingers: '1234'
        },
        {
            frets: '5745',
            fingers: '2413'
        },
        {
            frets: '788a',
            fingers: '1234'
        },
        {
            frets: 'ccab',
            fingers: '3412'
        }
    ]
};

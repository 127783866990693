export default {
    key: 'Ab',
    suffix: '13b9',
    positions: [
        {
            frets: '2523',
            fingers: '1412',
            barres: 2,
            capo: true
        },
        {
            frets: '5658',
            fingers: '1214',
            barres: 5,
            capo: true
        },
        {
            frets: 'b988',
            fingers: '4211',
            barres: 8,
            capo: true
        },
        {
            frets: 'bcdc',
            fingers: '1243'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'alt',
    positions: [
        {
            frets: '4312',
            fingers: '4312'
        },
        {
            frets: '4576',
            fingers: '1243'
        },
        {
            frets: '8576',
            fingers: '4132'
        }
    ]
};

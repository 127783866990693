export default {
    key: 'Ab',
    suffix: 'm11',
    positions: [
        {
            frets: '424322',
            fingers: '314211',
            barres: 2,
            capo: true
        },
        {
            frets: '444446',
            fingers: '111114',
            barres: 4,
            capo: true
        },
        {
            frets: 'xx6677',
            fingers: '001123',
            barres: 6,
            capo: true
        },
        {
            frets: 'xb9bb9',
            fingers: '031341',
            barres: 9,
            capo: true
        }
    ]
};

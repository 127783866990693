export default {
    key: 'E',
    suffix: 'sus2',
    positions: [
        {
            frets: '4422',
            fingers: '3411',
            barres: 2,
            capo: true
        },
        {
            frets: '4677',
            fingers: '1233',
            barres: 7
        },
        {
            frets: '9bc9',
            fingers: '1341',
            barres: 9,
            capo: true
        },
        {
            frets: 'bbce',
            fingers: '1124',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'm69',
    positions: [
        {
            frets: '2344',
            fingers: '1234'
        },
        {
            frets: '6856',
            fingers: '2413'
        },
        {
            frets: '899b',
            fingers: '1234'
        },
        {
            frets: 'ddbc',
            fingers: '3412'
        }
    ]
};

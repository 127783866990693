export default {
    key: 'C#',
    suffix: 'dim7',
    positions: [
        {
            frets: 'xx2323',
            fingers: '001324'
        },
        {
            frets: 'x45353',
            fingers: '023141',
            barres: 3,
            capo: true
        },
        {
            frets: '9x898x',
            fingers: '201310',
            barres: 8
        },
        {
            frets: 'xxbcbc',
            fingers: '001324'
        }
    ]
};

export default {
    key: 'A',
    suffix: '9#11',
    positions: [
        {
            frets: 'x01020',
            fingers: '001030'
        },
        {
            frets: '545445',
            fingers: '213114',
            barres: 4,
            capo: true
        },
        {
            frets: 'xx7889',
            fingers: '001234'
        },
        {
            frets: 'xcbccb',
            fingers: '021341',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'madd9',
    positions: [
        {
            frets: '8668',
            fingers: '3114',
            barres: 6,
            capo: true
        },
        {
            frets: 'bab8',
            fingers: '3241'
        },
        {
            frets: 'aab9',
            fingers: '2231',
            barres: 10
        }
    ]
};

export default {
    key: 'G',
    suffix: 'b13#9',
    positions: [
        {
            frets: '4311',
            fingers: '4311',
            barres: 1,
            capo: true
        },
        {
            frets: '3312',
            fingers: '3412'
        },
        {
            frets: '4566',
            fingers: '1234'
        },
        {
            frets: '8a78',
            fingers: '2413'
        }
    ]
};

export default {
    key: 'B',
    suffix: '9b5',
    positions: [
        {
            frets: 'x21221',
            fingers: '021341',
            barres: 1,
            capo: true
        },
        {
            frets: '767667',
            fingers: '213114',
            barres: 6,
            capo: true
        },
        {
            frets: '7878x9',
            fingers: '121304',
            barres: 7,
            capo: true
        },
        {
            frets: 'xedeed',
            fingers: '021341',
            barres: 13,
            capo: true
        }
    ]
};

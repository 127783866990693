export default {
    key: 'D',
    suffix: '11',
    positions: [
        {
            frets: '0423',
            fingers: '0312'
        },
        {
            frets: '9789',
            fingers: '3124'
        }
    ]
};

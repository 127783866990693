export default {
    key: 'B',
    suffix: 'add9',
    positions: [
        {
            frets: 'x21x22',
            fingers: '021034'
        },
        {
            frets: '76x677',
            fingers: '310244',
            barres: 7
        },
        {
            frets: 'xx9879',
            fingers: '003214'
        },
        {
            frets: 'xedbeb',
            fingers: '032141',
            barres: 11,
            capo: true
        }
    ]
};

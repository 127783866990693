export default {
    key: 'D',
    suffix: 'sus2',
    positions: [
        {
            frets: '2455',
            fingers: '1233',
            barres: 5
        },
        {
            frets: '79a7',
            fingers: '1341',
            barres: 7,
            capo: true
        },
        {
            frets: '99ac',
            fingers: '1124',
            barres: 9,
            capo: true
        },
        {
            frets: 'eecc',
            fingers: '3411',
            barres: 12,
            capo: true
        }
    ]
};

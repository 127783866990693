export default {
    key: 'Bb',
    suffix: '7',
    positions: [
        {
            frets: '1211',
            fingers: '1211',
            barres: 1,
            capo: true
        },
        {
            frets: '3545',
            fingers: '1324'
        },
        {
            frets: '7868',
            fingers: '2314'
        },
        {
            frets: 'aaab',
            fingers: '1112',
            barres: 10,
            capo: true
        }
    ]
};

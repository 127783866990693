export default {
    key: 'A',
    suffix: 'dim',
    positions: [
        {
            frets: 'x0121x',
            fingers: '001320'
        },
        {
            frets: '53x43x',
            fingers: '310420'
        },
        {
            frets: 'xx78x8',
            fingers: '001203'
        },
        {
            frets: 'xcaxab',
            fingers: '041023'
        }
    ]
};

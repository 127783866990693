export default {
    key: 'G',
    suffix: 'dim',
    positions: [
        {
            frets: '31x32x',
            fingers: '310420'
        },
        {
            frets: 'xx56x6',
            fingers: '001203'
        },
        {
            frets: 'xa8x89',
            fingers: '012430'
        },
        {
            frets: 'xabcbx',
            fingers: '012430'
        }
    ]
};

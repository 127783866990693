export default {
    key: 'D',
    suffix: '7b9',
    positions: [
        {
            frets: 'xx0542',
            fingers: '000431'
        },
        {
            frets: 'x54545',
            fingers: '021314',
            barres: 4,
            capo: true
        },
        {
            frets: 'x60575',
            fingers: '020141',
            barres: 5
        },
        {
            frets: 'a9a8xx',
            fingers: '324100'
        }
    ]
};

export default {
    key: 'E',
    suffix: '7b5',
    positions: [
        {
            frets: '1201',
            fingers: '1302'
        },
        {
            frets: '3445',
            fingers: '1234'
        },
        {
            frets: '7867',
            fingers: '2413'
        },
        {
            frets: '9aab',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'maj9',
    positions: [
        {
            frets: 'x52222',
            fingers: '041111',
            barres: 2,
            capo: true
        },
        {
            frets: 'x5464x',
            fingers: '021430'
        },
        {
            frets: 'x90979',
            fingers: '020314'
        },
        {
            frets: 'axbbac',
            fingers: '102314',
            barres: 10,
            capo: true
        }
    ]
};

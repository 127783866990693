export default {
    key: 'C',
    suffix: 'sus4',
    positions: [
        {
            frets: '0013',
            fingers: '0013'
        },
        {
            frets: '5533',
            fingers: '2311',
            barres: 3,
            capo: true
        },
        {
            frets: '5788',
            fingers: '1234'
        },
        {
            frets: 'a78a',
            fingers: '3124'
        }
    ]
};

export default {
    key: 'E',
    suffix: 'dim7',
    positions: [
        {
            frets: '012020',
            fingers: '012030'
        },
        {
            frets: 'xx2323',
            fingers: '001324'
        },
        {
            frets: 'x7868x',
            fingers: '023140'
        },
        {
            frets: 'cxbcbx',
            fingers: '201310',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'C#',
    suffix: '7sus4',
    positions: [
        {
            frets: 'x44422',
            fingers: '023411',
            barres: 2,
            capo: true
        },
        {
            frets: '446474',
            fingers: '113141',
            barres: 4,
            capo: true
        },
        {
            frets: 'xxx677',
            fingers: '000123'
        },
        {
            frets: '9b9b99',
            fingers: '131411',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: 'aug',
    positions: [
        {
            frets: 'x3211x',
            fingers: '032110',
            barres: 1
        },
        {
            frets: 'x3655x',
            fingers: '014230'
        },
        {
            frets: '87655x',
            fingers: '432110',
            barres: 5
        },
        {
            frets: 'xxa998',
            fingers: '004231'
        }
    ]
};

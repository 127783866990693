export default {
    key: 'E',
    suffix: 'minor',
    positions: [
        {
            frets: '022000',
            fingers: '023000'
        },
        {
            frets: '022453',
            fingers: '011342',
            barres: 2,
            capo: true
        },
        {
            frets: '779987',
            fingers: '113421',
            barres: 7,
            capo: true
        },
        {
            frets: 'ca99xx',
            fingers: '431200'
        }
    ]
};

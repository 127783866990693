export default {
    key: 'A',
    suffix: 'm/G',
    positions: [
        {
            frets: 'xx5555',
            fingers: '001111',
            barres: 5
        },
        {
            frets: '302210',
            fingers: '402310'
        },
        {
            frets: '332555',
            fingers: '231444',
            barres: 5
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '4331',
            fingers: '4231'
        },
        {
            frets: '3332',
            fingers: '2341'
        },
        {
            frets: '4766',
            fingers: '1423'
        },
        {
            frets: '8a7a',
            fingers: '2314'
        }
    ]
};

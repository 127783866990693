export default {
    key: 'Gb',
    suffix: 'maj7b5',
    positions: [
        {
            frets: '3523',
            fingers: '2413'
        },
        {
            frets: '5668',
            fingers: '1234'
        },
        {
            frets: 'ba88',
            fingers: '4311',
            barres: 8,
            capo: true
        },
        {
            frets: 'aa89',
            fingers: '3412'
        }
    ]
};

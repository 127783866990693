export default {
    key: 'Ab',
    suffix: 'aug',
    positions: [
        {
            frets: '43211x',
            fingers: '432110',
            barres: 1,
            capo: true
        },
        {
            frets: '4x655x',
            fingers: '104230'
        },
        {
            frets: 'xx655x',
            fingers: '002110',
            barres: 5,
            capo: true
        },
        {
            frets: 'xba99x',
            fingers: '032110',
            barres: 9,
            capo: true
        }
    ]
};

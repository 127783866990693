export default {
    key: 'C#',
    suffix: '6',
    positions: [
        {
            frets: 'x4332x',
            fingers: '042310'
        },
        {
            frets: 'x46666',
            fingers: '013333',
            barres: 6
        },
        {
            frets: '988666',
            fingers: '423111',
            barres: 6,
            capo: true
        },
        {
            frets: '9xbab9',
            fingers: '103241',
            barres: 9,
            capo: true
        }
    ]
};

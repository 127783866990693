export default {
    key: 'G',
    suffix: 'sus4',
    positions: [
        {
            frets: '330013',
            fingers: '230014'
        },
        {
            frets: '355533',
            fingers: '123411',
            barres: 3,
            capo: true
        },
        {
            frets: 'x55788',
            fingers: '011344',
            barres: [5, 8],
            capo: true
        },
        {
            frets: 'aaccda',
            fingers: '112341',
            barres: 10,
            capo: true
        }
    ]
};

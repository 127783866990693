export default {
    key: 'C#',
    suffix: 'm7b5',
    positions: [
        {
            frets: 'x4545x',
            fingers: '013240'
        },
        {
            frets: 'xx5657',
            fingers: '001214',
            barres: 5,
            capo: true
        },
        {
            frets: '9x998x',
            fingers: '203410'
        },
        {
            frets: 'xxbccc',
            fingers: '001222',
            barres: 12
        }
    ]
};

export default {
    key: 'C#',
    suffix: '9b5',
    positions: [
        {
            frets: 'x43443',
            fingers: '021341',
            barres: 3,
            capo: true
        },
        {
            frets: '989889',
            fingers: '213114',
            barres: 8,
            capo: true
        },
        {
            frets: '9axa0b',
            fingers: '120304'
        }
    ]
};

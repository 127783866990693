export default {
    key: 'F',
    suffix: '7b5',
    positions: [
        {
            frets: '101201',
            fingers: '102403'
        },
        {
            frets: 'xx3445',
            fingers: '001234'
        },
        {
            frets: 'xx7867',
            fingers: '002413'
        },
        {
            frets: 'x898ax',
            fingers: '012130',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'C#',
    suffix: '9',
    positions: [
        {
            frets: '443444',
            fingers: '221334',
            barres: 4
        },
        {
            frets: '9898xx',
            fingers: '314200'
        },
        {
            frets: '9b9a9b',
            fingers: '131214',
            barres: 9,
            capo: true
        },
        {
            frets: 'xxbacb',
            fingers: '002143'
        }
    ]
};

export default {
    key: 'Gb',
    suffix: 'maj9',
    positions: [
        {
            frets: '1111',
            fingers: '1111',
            barres: 1,
            capo: true
        },
        {
            frets: '3544',
            fingers: '1322',
            barres: 4
        },
        {
            frets: '6868',
            fingers: '1324'
        },
        {
            frets: 'aa9b',
            fingers: '2213',
            barres: 10
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'b13#9',
    positions: [
        {
            frets: '5422',
            fingers: '4311',
            barres: 2,
            capo: true
        },
        {
            frets: '4423',
            fingers: '3412'
        },
        {
            frets: '5677',
            fingers: '1234'
        },
        {
            frets: '9b89',
            fingers: '2413'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '9b5',
    positions: [
        {
            frets: '0110',
            fingers: '0120'
        },
        {
            frets: '2534',
            fingers: '1423'
        },
        {
            frets: '6758',
            fingers: '2314'
        },
        {
            frets: 'a99a',
            fingers: '2113',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: 'minor',
    positions: [
        {
            frets: '133111',
            fingers: '134111',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3564',
            fingers: '001342'
        },
        {
            frets: '88aa98',
            fingers: '113421',
            barres: 8,
            capo: true
        },
        {
            frets: 'dbaaxx',
            fingers: '421100',
            barres: 10,
            capo: true
        }
    ]
};

export default {
    key: 'G',
    suffix: 'maj13',
    positions: [
        {
            frets: '322232',
            fingers: '311131',
            barres: [2, 3],
            capo: true
        },
        {
            frets: '334453',
            fingers: '112341',
            barres: 3,
            capo: true
        },
        {
            frets: 'xa9977',
            fingers: '042311',
            barres: 7
        },
        {
            frets: 'xaabcc',
            fingers: '011234',
            barres: 10,
            capo: true
        }
    ]
};

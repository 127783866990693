export default {
    key: 'C',
    suffix: '7#9',
    positions: [
        {
            frets: '0301',
            fingers: '0301'
        },
        {
            frets: '3436',
            fingers: '1214',
            barres: 3,
            capo: true
        },
        {
            frets: '8767',
            fingers: '4213'
        },
        {
            frets: '9766',
            fingers: '4211',
            barres: 6,
            capo: true
        }
    ]
};

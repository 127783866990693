export default {
    key: 'D',
    suffix: '/B',
    positions: [
        {
            frets: 'x20232',
            fingers: '010243'
        },
        {
            frets: 'x24232',
            fingers: '013121',
            barres: 2
        },
        {
            frets: 'x24235',
            fingers: '013124',
            barres: 2
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'add9',
    positions: [
        {
            frets: '0311',
            fingers: '0311',
            barres: 1
        },
        {
            frets: '3536',
            fingers: '1314',
            barres: 3,
            capo: true
        },
        {
            frets: '8768',
            fingers: '3214'
        },
        {
            frets: 'aaba',
            fingers: '1121',
            barres: 10,
            capo: true
        }
    ]
};

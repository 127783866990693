export default {
    key: 'Eb',
    suffix: 'maj7b5',
    positions: [
        {
            frets: 'xx1233',
            fingers: '001234'
        },
        {
            frets: 'x67786',
            fingers: '012240',
            barres: 6,
            capo: true
        },
        {
            frets: 'baccaa',
            fingers: '213411',
            barres: 10,
            capo: true
        },
        {
            frets: 'bcccxb',
            fingers: '122201',
            barres: [11, 12],
            capo: true
        }
    ]
};

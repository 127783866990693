export default {
    key: 'G',
    suffix: '9b5',
    positions: [
        {
            frets: '323223',
            fingers: '213114',
            barres: 2,
            capo: true
        },
        {
            frets: '343405',
            fingers: '121304',
            barres: 3
        },
        {
            frets: 'xa9aa9',
            fingers: '021341',
            barres: 9,
            capo: true
        },
        {
            frets: 'xcb00d',
            fingers: '021003'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '7sus4',
    positions: [
        {
            frets: '3344',
            fingers: '1122',
            barres: [3, 3],
            capo: true
        },
        {
            frets: '6866',
            fingers: '1311',
            barres: 6,
            capo: true
        },
        {
            frets: '8a9b',
            fingers: '1324'
        },
        {
            frets: 'ddbd',
            fingers: '2314'
        }
    ]
};

export default {
    key: 'F',
    suffix: '7b9',
    positions: [
        {
            frets: '131212',
            fingers: '141213',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3242',
            fingers: '002131',
            barres: 2,
            capo: true
        },
        {
            frets: 'x87878',
            fingers: '021314',
            barres: 7,
            capo: true
        },
        {
            frets: 'dcdbxx',
            fingers: '324100'
        }
    ]
};

export default {
    key: 'A',
    suffix: '9#11',
    positions: [
        {
            frets: '4334',
            fingers: '2113',
            barres: 3,
            capo: true
        },
        {
            frets: '6776',
            fingers: '1221',
            barres: [6, 7],
            capo: true
        },
        {
            frets: '8b9a',
            fingers: '1423'
        },
        {
            frets: 'cdbe',
            fingers: '2314'
        }
    ]
};

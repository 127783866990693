export default {
    key: 'C',
    suffix: 'maj13',
    positions: [
        {
            frets: 'x32201',
            fingers: '042301'
        },
        {
            frets: 'x33455',
            fingers: '011234',
            barres: 3
        },
        {
            frets: '877787',
            fingers: '211131',
            barres: 7,
            capo: true
        },
        {
            frets: '8899a8',
            fingers: '112341',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'maj13',
    positions: [
        {
            frets: '2233',
            fingers: '1122',
            barres: [2, 3],
            capo: true
        },
        {
            frets: '5755',
            fingers: '1311',
            barres: 5,
            capo: true
        },
        {
            frets: '798a',
            fingers: '1324'
        },
        {
            frets: 'ccac',
            fingers: '2314'
        }
    ]
};

export default {
    key: 'F',
    suffix: 'aug',
    positions: [
        {
            frets: 'xx3221',
            fingers: '004231'
        },
        {
            frets: 'x8766x',
            fingers: '032110',
            barres: 6,
            capo: true
        },
        {
            frets: 'x8xaa9',
            fingers: '010342'
        },
        {
            frets: 'dcbaax',
            fingers: '432110',
            barres: 10
        }
    ]
};

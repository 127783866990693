export default {
    key: 'G',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '300332',
            fingers: '200341'
        },
        {
            frets: '354335',
            fingers: '132114',
            barres: 3,
            capo: true
        },
        {
            frets: 'xx7076',
            fingers: '002031'
        },
        {
            frets: 'xa8bax',
            fingers: '021430'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'b13#9',
    positions: [
        {
            frets: '2335',
            fingers: '1234'
        },
        {
            frets: '8755',
            fingers: '4311',
            barres: 5,
            capo: true
        },
        {
            frets: '7756',
            fingers: '4312'
        },
        {
            frets: '89aa',
            fingers: '1234'
        }
    ]
};

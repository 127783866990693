export default {
    key: 'G',
    suffix: 'maj7',
    positions: [
        {
            frets: '0222',
            fingers: '0123'
        },
        {
            frets: '4635',
            fingers: '2413'
        },
        {
            frets: '7779',
            fingers: '1113',
            barres: 7,
            capo: true
        },
        {
            frets: 'cba9',
            fingers: '4321'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'sus4',
    positions: [
        {
            frets: '4422',
            fingers: '3411',
            barres: 2,
            capo: true
        },
        {
            frets: '4677',
            fingers: '1234'
        },
        {
            frets: '9677',
            fingers: '4122',
            barres: 7
        },
        {
            frets: '9679',
            fingers: '3124'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: '13b9',
    positions: [
        {
            frets: '0103',
            fingers: '0103'
        },
        {
            frets: '5434',
            fingers: '4213'
        },
        {
            frets: '6433',
            fingers: '4211',
            barres: 3,
            capo: true
        },
        {
            frets: '9c9a',
            fingers: '1412',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'D',
    suffix: 'maj7#5',
    positions: [
        {
            frets: 'xx0322',
            fingers: '000423'
        },
        {
            frets: '254322',
            fingers: '143211',
            barres: 2,
            capo: true
        },
        {
            frets: 'x50676',
            fingers: '010243'
        },
        {
            frets: 'xx0bb9',
            fingers: '000231'
        }
    ]
};

export default {
    key: 'E',
    suffix: '13',
    positions: [
        {
            frets: '020120',
            fingers: '020130'
        },
        {
            frets: '000122',
            fingers: '000123'
        },
        {
            frets: '056650',
            fingers: '013420'
        },
        {
            frets: '777799',
            fingers: '111134',
            barres: 7,
            capo: true
        }
    ]
};

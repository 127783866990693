export default {
    key: 'E',
    suffix: '/B',
    positions: [
        {
            frets: 'x22100',
            fingers: '023100'
        },
        {
            frets: 'x22454',
            fingers: '011343',
            barres: 2
        },
        {
            frets: '779997',
            fingers: '112341',
            barres: 7
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'dim7',
    positions: [
        {
            frets: '2323',
            fingers: '1324'
        },
        {
            frets: '5656',
            fingers: '1324'
        },
        {
            frets: '8989',
            fingers: '1324'
        },
        {
            frets: 'bcbc',
            fingers: '1324'
        }
    ]
};

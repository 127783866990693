export default {
    key: 'Ab',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '432010',
            fingers: '432010'
        },
        {
            frets: '435050',
            fingers: '213040'
        },
        {
            frets: '8ba988',
            fingers: '143211',
            barres: 8,
            capo: true
        },
        {
            frets: 'xbecdx',
            fingers: '014230'
        }
    ]
};

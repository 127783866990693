export default {
    key: 'Bb',
    suffix: 'sus4',
    positions: [
        {
            frets: 'x13341',
            fingers: '012341',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3346',
            fingers: '001124',
            barres: 3,
            capo: true
        },
        {
            frets: '688866',
            fingers: '133311',
            barres: 6,
            capo: true
        },
        {
            frets: 'x88abb',
            fingers: '011234',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'Ab',
    suffix: 'maj7b5',
    positions: [
        {
            frets: '435533',
            fingers: '213411',
            barres: 3,
            capo: true
        },
        {
            frets: '4555xx',
            fingers: '123400'
        },
        {
            frets: '006788',
            fingers: '001234',
            barres: 8,
            capo: true
        },
        {
            frets: 'xbccdx',
            fingers: '012240',
            barres: 12
        }
    ]
};

export default {
    key: 'E',
    suffix: 'sus4',
    positions: [
        {
            frets: '022200',
            fingers: '023400'
        },
        {
            frets: '022455',
            fingers: '011234',
            barres: 2
        },
        {
            frets: '7799a7',
            fingers: '112341',
            barres: 7,
            capo: true
        },
        {
            frets: 'xx99a0',
            fingers: '001120',
            barres: 9
        }
    ]
};

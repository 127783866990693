export default {
    key: 'A',
    suffix: 'm/C',
    positions: [
        {
            frets: 'x32210',
            fingers: '042310'
        },
        {
            frets: 'x32555',
            fingers: '021444',
            barres: 5
        },
        {
            frets: 'x32255',
            fingers: '021144',
            barres: 2
        }
    ]
};

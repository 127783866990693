export default {
    key: 'C#',
    suffix: 'mmaj9',
    positions: [
        {
            frets: 'x41110',
            fingers: '041110',
            barres: 1,
            capo: true
        },
        {
            frets: 'x42540',
            fingers: '021430'
        },
        {
            frets: '446540',
            fingers: '114320',
            barres: 4
        },
        {
            frets: '9ba99b',
            fingers: '132114',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: '9',
    positions: [
        {
            frets: 'x10111',
            fingers: '010234'
        },
        {
            frets: '65656x',
            fingers: '213140',
            barres: 5,
            capo: true
        },
        {
            frets: '686768',
            fingers: '131214',
            barres: 6,
            capo: true
        },
        {
            frets: 'xdcddd',
            fingers: '021333',
            barres: 13
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'minor',
    positions: [
        {
            frets: '3321',
            fingers: '3421'
        },
        {
            frets: '3666',
            fingers: '1333',
            barres: 6
        },
        {
            frets: '8666',
            fingers: '3111',
            barres: 6,
            capo: true
        },
        {
            frets: '8669',
            fingers: '3114',
            barres: 6,
            capo: true
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'minor',
    positions: [
        {
            frets: '3111',
            fingers: '3111',
            barres: 1,
            capo: true
        },
        {
            frets: '3114',
            fingers: '3114',
            barres: 1,
            capo: true
        },
        {
            frets: '3564',
            fingers: '1342'
        },
        {
            frets: '6564',
            fingers: '3241'
        }
    ]
};

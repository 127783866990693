export default {
    key: 'Ab',
    suffix: 'minor',
    positions: [
        {
            frets: '1342',
            fingers: '1342'
        },
        {
            frets: '4342',
            fingers: '3241'
        },
        {
            frets: '4346',
            fingers: '2134'
        },
        {
            frets: '8876',
            fingers: '3421'
        }
    ]
};

export default {
    key: 'C',
    suffix: 'm9',
    positions: [
        {
            frets: 'x31333',
            fingers: '021344',
            barres: 3
        },
        {
            frets: 'x30343',
            fingers: '010243'
        },
        {
            frets: 'x68788',
            fingers: '013244',
            barres: 8
        },
        {
            frets: '8a888a',
            fingers: '131114',
            barres: 8,
            capo: true
        }
    ]
};

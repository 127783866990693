import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Authenticated from '../auth/Authenticated';
import { Button, Modal, Typography, Input, Form, Row, Card, Col, Space, Divider } from 'antd';
import { RouteComponentProps, navigate } from '@reach/router';
import { useAuth } from '../../hooks/Auth';
import DeactivateAccountButton from '../auth/DeactivateAccountButton';
import UserAvatar from '../auth/UserAvatar';
import SignOutButton from '../auth/SignOutButton';
import EditableText from '../common/EditableText';


const AccountPage: React.FC<RouteComponentProps> = () => {
  const auth = useAuth();
  const [ startingDisplayName, setStartingDisplayName] = useState(auth.user?.displayName);
  const [ displayName, setDisplayName ] = useState(startingDisplayName);

  useEffect(() => {
    setStartingDisplayName(auth.user?.displayName);
    setDisplayName(startingDisplayName);
  }, [auth.user]);

  const updateProfile = useCallback(() => {
    async function internal() {

      await auth.user?.updateProfile({
        displayName
      });
      setStartingDisplayName(displayName);
    }
    internal();
  }, [displayName]);

  return (
    <Authenticated strategy='signInButton'>
      <Card
        title='Profile'
        actions={[
          <SignOutButton />,
          <DeactivateAccountButton />
        ]}>
        <Row>
          <Space>
            <Col>
              <UserAvatar user={auth.user} size={100} />
            </Col>
            <Col>
              <p>Display Name: {(
                <EditableText 
                  text={startingDisplayName} 
                  onTextChange={setDisplayName}
                  onPressEnter={updateProfile} />
              )}</p>
              <p>Email: {auth.user?.email}</p>
            </Col>
          </Space>
        </Row>
      </Card>
    </Authenticated>
  );
};

export default AccountPage;
export default {
    key: 'B',
    suffix: 'm6',
    positions: [
        {
            frets: '220102',
            fingers: '230104'
        },
        {
            frets: 'xx4434',
            fingers: '002314'
        },
        {
            frets: '7x677x',
            fingers: '201340'
        },
        {
            frets: '799797',
            fingers: '123141',
            barres: 7,
            capo: true
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'maj7#5',
    positions: [
        {
            frets: '4335',
            fingers: '2113',
            barres: 3,
            capo: true
        },
        {
            frets: '8775',
            fingers: '4231'
        },
        {
            frets: '7776',
            fingers: '2341'
        },
        {
            frets: '8baa',
            fingers: '1423'
        }
    ]
};

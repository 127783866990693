export default {
    key: 'Bb',
    suffix: 'sus4',
    positions: [
        {
            frets: '3311',
            fingers: '3411',
            barres: 1,
            capo: true
        },
        {
            frets: '3566',
            fingers: '1234'
        },
        {
            frets: '8566',
            fingers: '4122',
            barres: 6
        },
        {
            frets: '8568',
            fingers: '3124'
        }
    ]
};

export default {
    key: 'Ab',
    suffix: '7#9',
    positions: [
        {
            frets: '434444',
            fingers: '213334',
            barres: 4
        },
        {
            frets: '464577',
            fingers: '131244',
            barres: [4, 7],
            capo: true
        },
        {
            frets: 'xx6577',
            fingers: '003144',
            barres: 7
        },
        {
            frets: 'xbabcx',
            fingers: '021340'
        }
    ]
};

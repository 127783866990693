export default {
    key: 'Eb',
    suffix: 'mmaj9',
    positions: [
        {
            frets: 'x6476x',
            fingers: '021430'
        },
        {
            frets: '698766',
            fingers: '143211',
            barres: 6,
            capo: true
        },
        {
            frets: 'b9caxx',
            fingers: '314200'
        },
        {
            frets: 'bdcbbd',
            fingers: '132114',
            barres: 11,
            capo: true
        }
    ]
};

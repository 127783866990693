export default {
    key: 'G',
    suffix: 'dim',
    positions: [
        {
            frets: '0131',
            fingers: '0132'
        },
        {
            frets: '3131',
            fingers: '3141',
            barres: 1,
            capo: true
        },
        {
            frets: '6764',
            fingers: '2431'
        },
        {
            frets: 'ca9a',
            fingers: '4213'
        }
    ]
};

export default {
    key: 'Bb',
    suffix: 'm9',
    positions: [
        {
            frets: 'xx3524',
            fingers: '002413'
        },
        {
            frets: '64x564',
            fingers: '310241',
            barres: 4,
            capo: true
        },
        {
            frets: '686668',
            fingers: '131114',
            barres: 6,
            capo: true
        },
        {
            frets: 'xdbddx',
            fingers: '021340'
        }
    ]
};

import React from 'react'
import { UserType, User } from '../../hooks/Auth'
import { FingerprintUtil } from '../../util/FingerprintUtil';
import { Avatar } from 'antd';

interface AvatarProps {
  user?: User
  photoURL?: string
  size?: number | 'small' | 'large'
}

function placeholderURL(size: number, id: string) {
  return `https://api.adorable.io/avatars/${size}/${id}.png`;
}

const UserAvatar: React.FC<AvatarProps> = ({
  user,
  photoURL,
  size = 100
}) => {
  const fingerprint = FingerprintUtil.getFingerprint();
  let src = photoURL;
  let imgSize = size === 'large' ? 150 : typeof size === 'number' ? size : 100;

  if (user) {
    src = user.photoURL ? user.photoURL : undefined;
  }

  if (!src) {
    const id = Math.floor(Math.random() * 10000)
    src = placeholderURL(imgSize, id.toString());
  }

  return (
    <Avatar src={src} size={size} />
  );
};

export default UserAvatar;
export default {
    key: 'C',
    suffix: 'aug',
    positions: [
        {
            frets: '1003',
            fingers: '1004'
        },
        {
            frets: '1443',
            fingers: '1342'
        },
        {
            frets: '5443',
            fingers: '4231'
        },
        {
            frets: '5447',
            fingers: '2114',
            barres: 4,
            capo: true
        }
    ]
};

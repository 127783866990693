export default {
    key: 'C',
    suffix: 'add9',
    positions: [
        {
            frets: '0203',
            fingers: '0203'
        },
        {
            frets: '5435',
            fingers: '3214'
        },
        {
            frets: '7787',
            fingers: '1121',
            barres: 7,
            capo: true
        },
        {
            frets: '9caa',
            fingers: '1422',
            barres: 10
        }
    ]
};

export default {
    key: 'G',
    suffix: 'maj11',
    positions: [
        {
            frets: '2022',
            fingers: '1023'
        },
        {
            frets: '4653',
            fingers: '2431'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'aug9',
    positions: [
        {
            frets: 'x54556',
            fingers: '021334',
            barres: 5
        },
        {
            frets: 'x70576',
            fingers: '030142'
        },
        {
            frets: 'a9a9bx',
            fingers: '213140',
            barres: 9,
            capo: true
        },
        {
            frets: 'axabb0',
            fingers: '102340'
        }
    ]
};

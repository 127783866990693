export default {
    key: 'G',
    suffix: 'm11',
    positions: [
        {
            frets: '2011',
            fingers: '3011',
            barres: 1
        },
        {
            frets: '3010',
            fingers: '3010'
        },
        {
            frets: '3553',
            fingers: '1341',
            barres: 3,
            capo: true
        },
        {
            frets: '5560',
            fingers: '1230'
        }
    ]
};

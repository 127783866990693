export default {
    key: 'F',
    suffix: '69',
    positions: [
        {
            frets: '100011',
            fingers: '100023'
        },
        {
            frets: 'xx3233',
            fingers: '002134'
        },
        {
            frets: 'x55565',
            fingers: '011121',
            barres: 5,
            capo: true
        },
        {
            frets: 'x87788',
            fingers: '021134',
            barres: 7,
            capo: true
        }
    ]
};

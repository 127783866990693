export default {
    key: 'D',
    suffix: 'alt',
    positions: [
        {
            frets: 'xx0132',
            fingers: '000132'
        },
        {
            frets: 'x54x34',
            fingers: '042013'
        },
        {
            frets: 'x5677x',
            fingers: '012340'
        },
        {
            frets: 'xb0b9a',
            fingers: '030412'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'm9',
    positions: [
        {
            frets: '2413',
            fingers: '2413'
        },
        {
            frets: '5557',
            fingers: '1113',
            barres: 5,
            capo: true
        },
        {
            frets: 'a987',
            fingers: '4321'
        },
        {
            frets: '9988',
            fingers: '2211',
            barres: [8, 9],
            capo: true
        }
    ]
};

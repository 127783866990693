export default {
    key: 'C#',
    suffix: 'madd9',
    positions: [
        {
            frets: 'x4214x',
            fingers: '032140'
        },
        {
            frets: 'x46640',
            fingers: '013420'
        },
        {
            frets: '97x890',
            fingers: '310240'
        },
        {
            frets: 'xxb99b',
            fingers: '003114',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'B',
    suffix: 'maj7',
    positions: [
        {
            frets: '4321',
            fingers: '4321'
        },
        {
            frets: '3322',
            fingers: '2311',
            barres: 2,
            capo: true
        },
        {
            frets: '4666',
            fingers: '1234'
        },
        {
            frets: '8a79',
            fingers: '2413'
        }
    ]
};

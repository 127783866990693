export default {
    key: 'B',
    suffix: 'madd9',
    positions: [
        {
            frets: '2002',
            fingers: '2003'
        },
        {
            frets: '5452',
            fingers: '3241'
        },
        {
            frets: '4453',
            fingers: '2231',
            barres: 4
        }
    ]
};

export default {
    key: 'B',
    suffix: 'dim7',
    positions: [
        {
            frets: 'x23131',
            fingers: '023141',
            barres: 1,
            capo: true
        },
        {
            frets: 'xx3434',
            fingers: '001324'
        },
        {
            frets: '7x676x',
            fingers: '301420'
        },
        {
            frets: '789797',
            fingers: '123141',
            barres: 7,
            capo: true
        }
    ]
};

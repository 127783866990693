export default {
    key: 'C#',
    suffix: 'sus4',
    positions: [
        {
            frets: 'x4412x',
            fingers: '034120'
        },
        {
            frets: '446674',
            fingers: '112341',
            barres: 4,
            capo: true
        },
        {
            frets: '9x6679',
            fingers: '230014'
        },
        {
            frets: '9bbb99',
            fingers: '123411',
            barres: 9,
            capo: true
        }
    ]
};

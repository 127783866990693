export default {
    key: 'E',
    suffix: 'mmaj7b5',
    positions: [
        {
            frets: '0110x0',
            fingers: '012000'
        },
        {
            frets: 'xx2343',
            fingers: '001243'
        },
        {
            frets: '67888x',
            fingers: '123330',
            barres: 8
        },
        {
            frets: 'x7888x',
            fingers: '012340'
        }
    ]
};

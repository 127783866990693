export default {
    key: 'Bb',
    suffix: 'aug9',
    positions: [
        {
            frets: 'x10112',
            fingers: '010234'
        },
        {
            frets: '434334',
            fingers: '213114',
            barres: 3,
            capo: true
        },
        {
            frets: '65657x',
            fingers: '213140',
            barres: 5,
            capo: true
        },
        {
            frets: '6x6778',
            fingers: '101234',
            barres: 6,
            capo: true
        }
    ]
};

export default {
    key: 'E',
    suffix: 'm/C#',
    positions: [
        {
            frets: 'x42000',
            fingers: '031000'
        },
        {
            frets: 'x45457',
            fingers: '012134'
        },
        {
            frets: '9a9ccc',
            fingers: '121444',
            barres: 9
        }
    ]
};

export default {
    key: 'G',
    suffix: 'sus2',
    positions: [
        {
            frets: '0230',
            fingers: '0230'
        },
        {
            frets: '2235',
            fingers: '1124',
            barres: 2,
            capo: true
        },
        {
            frets: '7755',
            fingers: '3411',
            barres: 5,
            capo: true
        },
        {
            frets: '79aa',
            fingers: '1233',
            barres: 10
        }
    ]
};

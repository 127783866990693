export default {
    key: 'C#',
    suffix: 'sus2',
    positions: [
        {
            frets: '446644',
            fingers: '113411',
            barres: 4,
            capo: true
        },
        {
            frets: '96689x',
            fingers: '010023',
            barres: 6,
            capo: true
        },
        {
            frets: '9bbx9b',
            fingers: '123014',
            barres: 9,
            capo: true
        },
        {
            frets: 'bbbdeb',
            fingers: '111341',
            barres: 11,
            capo: true
        }
    ]
};

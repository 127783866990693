export default {
    key: 'Ab',
    suffix: '13',
    positions: [
        {
            frets: '413112',
            fingers: '413112',
            barres: 1,
            capo: true
        },
        {
            frets: '464564',
            fingers: '131241',
            barres: 4,
            capo: true
        },
        {
            frets: '444566',
            fingers: '111234',
            barres: 4,
            capo: true
        },
        {
            frets: 'xbabdd',
            fingers: '021344',
            barres: 13
        }
    ]
};

import React from 'react'
import { EdgeInsets } from './EdgeInsets';

interface PaddingProps {
  insets: EdgeInsets
}

const Padding: React.FC<PaddingProps> = (props) => {
  const { top, bottom, left, right } = props.insets; 
  return (
    <div style={{ 
      paddingTop: top,
      paddingBottom: bottom,
      paddingLeft: left,
      paddingRight: right,
    }}>
      {props.children}
    </div>
  );
};

export default Padding;
export default {
    key: 'Bb',
    suffix: '11',
    positions: [
        {
            frets: 'x11131',
            fingers: '011131',
            barres: 1,
            capo: true
        },
        {
            frets: '650544',
            fingers: '420311',
            barres: 4,
            capo: true
        },
        {
            frets: '88889a',
            fingers: '111123',
            barres: 8,
            capo: true
        },
        {
            frets: 'xdcdbb',
            fingers: '032411',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: 'major',
    positions: [
        {
            frets: '244322',
            fingers: '134211',
            barres: 2,
            capo: true
        },
        {
            frets: 'x44676',
            fingers: '011243',
            barres: 4,
            capo: true
        },
        {
            frets: '698676',
            fingers: '143121',
            barres: 6,
            capo: true
        },
        {
            frets: '99bbb9',
            fingers: '112341',
            barres: 9,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: '11',
    positions: [
        {
            frets: '4234',
            fingers: '3124'
        },
        {
            frets: '7774',
            fingers: '2341'
        },
        {
            frets: '6775',
            fingers: '2341'
        }
    ]
};

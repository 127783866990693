export default {
    key: 'Bb',
    suffix: 'maj9',
    positions: [
        {
            frets: '11021x',
            fingers: '110320',
            barres: 1
        },
        {
            frets: '633335',
            fingers: '411113',
            barres: 3,
            capo: true
        },
        {
            frets: '65756x',
            fingers: '214130',
            barres: 5,
            capo: true
        },
        {
            frets: '687768',
            fingers: '132214',
            barres: [6, 7],
            capo: true
        }
    ]
};

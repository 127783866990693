export default {
    key: 'Ab',
    suffix: 'major',
    positions: [
        {
            frets: '1343',
            fingers: '1243'
        },
        {
            frets: '5343',
            fingers: '3121',
            barres: 3,
            capo: true
        },
        {
            frets: '5346',
            fingers: '3124'
        },
        {
            frets: '888b',
            fingers: '1114',
            barres: 8,
            capo: true
        }
    ]
};

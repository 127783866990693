export default {
    key: 'Eb',
    suffix: 'alt',
    positions: [
        {
            frets: 'xx1243',
            fingers: '001243'
        },
        {
            frets: 'x65045',
            fingers: '042013'
        },
        {
            frets: 'x6708x',
            fingers: '012030'
        },
        {
            frets: 'baxcab',
            fingers: '210413',
            barres: 10,
            capo: true
        }
    ]
};

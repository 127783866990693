import React, { useState } from 'react'
import NamedChord from './NamedChord';
import Padding from '../common/Padding';
import { EdgeInsets } from '../common/EdgeInsets';

interface ChordRowProps {
  chords: string[]
}

const ChordRow: React.FC<ChordRowProps> = ({ chords }) => {
  const [size, setSize] = useState(1);
  
  if (chords.length === 0) return <div></div>;

  return (
    <div className='ChordRow scrollbox'
      style={{
        overflowX: 'scroll',
        overflowBlock: 'scroll',
        whiteSpace: 'nowrap',
        scrollbarWidth: 'thin',
      }}>
        <div>
          {chords.map(c => (
            <div style={{ display: 'inline-block' }}>
              <NamedChord name={c} />
            </div>
          ))}
        </div>
    </div>
  );
};

export default ChordRow;
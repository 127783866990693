export default {
    key: 'C#',
    suffix: 'aug',
    positions: [
        {
            frets: 'x44422',
            fingers: '023411',
            barres: 2,
            capo: true
        },
        {
            frets: '446474',
            fingers: '113141',
            barres: 4,
            capo: true
        },
        {
            frets: '98766x',
            fingers: '432110',
            barres: 6,
            capo: true
        },
        {
            frets: '9xbaa9',
            fingers: '104231',
            barres: 9,
            capo: true
        }
    ]
};

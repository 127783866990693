export default {
    key: 'C',
    suffix: 'madd9',
    positions: [
        {
            frets: '5335',
            fingers: '3114',
            barres: 3,
            capo: true
        },
        {
            frets: '8785',
            fingers: '3241'
        },
        {
            frets: '7786',
            fingers: '2231',
            barres: 7
        }
    ]
};

export default {
    key: 'F',
    suffix: '/E',
    positions: [
        {
            frets: '003211',
            fingers: '003211',
            barres: 1
        },
        {
            frets: 'xx2211',
            fingers: '002311',
            barres: 1
        },
        {
            frets: '033211',
            fingers: '034211',
            barres: 1
        }
    ]
};

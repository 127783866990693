export default {
    key: 'Eb',
    suffix: 'major',
    positions: [
        {
            frets: '0331',
            fingers: '0341'
        },
        {
            frets: '3331',
            fingers: '2341'
        },
        {
            frets: '3336',
            fingers: '1114',
            barres: 3,
            capo: true
        },
        {
            frets: '8766',
            fingers: '3211',
            barres: 6,
            capo: true
        }
    ]
};

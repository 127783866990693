export default {
    key: 'Eb',
    suffix: 'maj13',
    positions: [
        {
            frets: 'x3103x',
            fingers: '031040'
        },
        {
            frets: 'x65533',
            fingers: '042311',
            barres: 3
        },
        {
            frets: 'x66788',
            fingers: '011234',
            barres: 6,
            capo: true
        },
        {
            frets: 'baaaba',
            fingers: '211131',
            barres: 10,
            capo: true
        }
    ]
};

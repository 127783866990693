export default {
    key: 'F',
    suffix: 'add9',
    positions: [
        {
            frets: '0010',
            fingers: '0010'
        },
        {
            frets: '2533',
            fingers: '1422',
            barres: 3
        },
        {
            frets: '5758',
            fingers: '1314',
            barres: 5,
            capo: true
        },
        {
            frets: 'a98a',
            fingers: '3214'
        }
    ]
};

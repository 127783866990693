export default {
    key: 'C',
    suffix: '7',
    positions: [
        {
            frets: '0001',
            fingers: '0001'
        },
        {
            frets: '3433',
            fingers: '1211',
            barres: 3,
            capo: true
        },
        {
            frets: '5767',
            fingers: '1324'
        },
        {
            frets: '9a8a',
            fingers: '2314'
        }
    ]
};

export default {
    key: 'C',
    suffix: '9',
    positions: [
        {
            frets: '0201',
            fingers: '0201'
        },
        {
            frets: '3435',
            fingers: '1213',
            barres: 3,
            capo: true
        },
        {
            frets: '7767',
            fingers: '2314'
        },
        {
            frets: '9aaa',
            fingers: '1234'
        }
    ]
};

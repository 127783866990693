export default {
    key: 'Db',
    suffix: 'major',
    positions: [
        {
            frets: '1114',
            fingers: '1114',
            barres: 1,
            capo: true
        },
        {
            frets: '6544',
            fingers: '3211',
            barres: 4,
            capo: true
        },
        {
            frets: '6898',
            fingers: '1243'
        },
        {
            frets: 'a898',
            fingers: '3121',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'C',
    suffix: '7b5',
    positions: [
        {
            frets: '3423',
            fingers: '2413'
        },
        {
            frets: '5667',
            fingers: '1223',
            barres: 6
        },
        {
            frets: '9a89',
            fingers: '2413'
        },
        {
            frets: 'bccd',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'C',
    suffix: 'maj11',
    positions: [
        {
            frets: 'x32001',
            fingers: '032001'
        },
        {
            frets: 'x33000',
            fingers: '011000',
            barres: 3
        },
        {
            frets: '879060',
            fingers: '324010'
        },
        {
            frets: '889988',
            fingers: '112311',
            barres: 8,
            capo: true
        }
    ]
};

export default {
    key: 'F#',
    suffix: '9',
    positions: [
        {
            frets: '242324',
            fingers: '131214',
            barres: 2,
            capo: true
        },
        {
            frets: 'xx4354',
            fingers: '002143'
        },
        {
            frets: '998999',
            fingers: '221334',
            barres: 9
        },
        {
            frets: 'xbbbbc',
            fingers: '011112',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'A',
    suffix: '/G',
    positions: [
        {
            frets: '302220',
            fingers: '401230'
        },
        {
            frets: 'xx5655',
            fingers: '001211',
            barres: 5
        },
        {
            frets: '342255',
            fingers: '231144'
        }
    ]
};

export default {
    key: 'G',
    suffix: 'sus2',
    positions: [
        {
            frets: '300033',
            fingers: '100023'
        },
        {
            frets: '555785',
            fingers: '111341',
            barres: 5,
            capo: true
        },
        {
            frets: 'xa778a',
            fingers: '031124',
            barres: 7,
            capo: true
        },
        {
            frets: 'aaccaa',
            fingers: '113411',
            barres: 10,
            capo: true
        }
    ]
};

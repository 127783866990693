export default {
    key: 'Gb',
    suffix: 'sus2',
    positions: [
        {
            frets: '1124',
            fingers: '1124',
            barres: 1,
            capo: true
        },
        {
            frets: '6644',
            fingers: '3411',
            barres: 4,
            capo: true
        },
        {
            frets: '6899',
            fingers: '1233',
            barres: 9
        },
        {
            frets: 'bdeb',
            fingers: '1341',
            barres: 11,
            capo: true
        }
    ]
};

export default {
    key: 'F',
    suffix: '13b5b9',
    positions: [
        {
            frets: '4325',
            fingers: '3214'
        },
        {
            frets: '8675',
            fingers: '4231'
        },
        {
            frets: '7676',
            fingers: '3142'
        },
        {
            frets: '8ba9',
            fingers: '1432'
        }
    ]
};

export default {
    key: 'B',
    suffix: 'maj9',
    positions: [
        {
            frets: '22132x',
            fingers: '221430',
            barres: 2
        },
        {
            frets: 'xx4646',
            fingers: '001314',
            barres: 4,
            capo: true
        },
        {
            frets: '76867x',
            fingers: '214130',
            barres: 6
        },
        {
            frets: 'xx98b9',
            fingers: '002143'
        }
    ]
};

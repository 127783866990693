export default {
    key: 'Ab',
    suffix: 'aug7',
    positions: [
        {
            frets: '4x4550',
            fingers: '102340'
        },
        {
            frets: 'xx6978',
            fingers: '001423'
        },
        {
            frets: 'xbab90',
            fingers: '032410'
        },
        {
            frets: 'xbebdc',
            fingers: '014132',
            barres: 11,
            capo: true
        }
    ]
};

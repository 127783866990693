export default {
    key: 'Bb',
    suffix: 'm69',
    positions: [
        {
            frets: '0113',
            fingers: '0214'
        },
        {
            frets: '5534',
            fingers: '3412'
        },
        {
            frets: '6533',
            fingers: '4311',
            barres: 3,
            capo: true
        },
        {
            frets: '6788',
            fingers: '1234'
        }
    ]
};

export default {
    key: 'D',
    suffix: 'dim7',
    positions: [
        {
            frets: 'xx0101',
            fingers: '000203'
        },
        {
            frets: 'x56464',
            fingers: '023141',
            barres: 4,
            capo: true
        },
        {
            frets: 'x80a97',
            fingers: '020431'
        },
        {
            frets: 'abcaca',
            fingers: '123141',
            barres: 10,
            capo: true
        }
    ]
};

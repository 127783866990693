export default {
    key: 'A',
    suffix: 'maj9',
    positions: [
        {
            frets: '1102',
            fingers: '1203'
        },
        {
            frets: '4444',
            fingers: '1111',
            barres: 4,
            capo: true
        },
        {
            frets: '6877',
            fingers: '1322',
            barres: 7
        },
        {
            frets: '9b9b',
            fingers: '1324'
        }
    ]
};

export default {
    key: 'Eb',
    suffix: 'mmaj11',
    positions: [
        {
            frets: 'a8a9',
            fingers: '3142'
        },
        {
            frets: 'b8a8',
            fingers: '4131',
            barres: 8,
            capo: true
        },
        {
            frets: 'bedb',
            fingers: '1431',
            barres: 11,
            capo: true
        }
    ]
};

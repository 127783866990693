export default {
    key: 'Db',
    suffix: 'minor',
    positions: [
        {
            frets: '1444',
            fingers: '1234'
        },
        {
            frets: '6444',
            fingers: '2111',
            barres: 4,
            capo: true
        },
        {
            frets: '6447',
            fingers: '3114',
            barres: 4,
            capo: true
        },
        {
            frets: '6897',
            fingers: '1342'
        }
    ]
};

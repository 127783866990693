export default {
    key: 'Bb',
    suffix: 'mmaj9',
    positions: [
        {
            frets: '64x565',
            fingers: '310242',
            barres: 5
        },
        {
            frets: '6475xx',
            fingers: '314200'
        },
        {
            frets: '687668',
            fingers: '132114',
            barres: 6,
            capo: true
        },
        {
            frets: '888aa9',
            fingers: '111342',
            barres: 8,
            capo: true
        }
    ]
};
